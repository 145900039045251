import { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/assets/new-asset/components/FormField";
import CustomSelect from "components/CustomSelect";

/* const categoryOptions = [
  { value: "clothing", label: "Clothing" },
  { value: "electronics", label: "Electronics" },
  { value: "furniture", label: "Furniture" },
  { value: "others", label: "Others" },
  { value: "real estate", label: "Real Estate" },
]; */

function Basic({ formData }) {
  const [state, setState] = useState("...");
  const [posts, error, loading, axiosFetch] = useAxios();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const [facilityOptions, setFacilityOptions] = useState([]);
  const {
    assetName,
    description,
    //category,
    gas_volume,
    water_volume,
    electricity_power,
    power_energy_genarated,
    waste_recycled,
    waste_to_landfill,
    facility,
    id_facility,
    fuel_volume,
  } = formField;

  const {
    assetName: assetNameV,
    description: descriptionV,
    //category: categoryV,
    gas_volume: gas_volumeV,
    water_volume: water_volumeV,
    electricity_power: electricity_powerV,
    power_energy_genarated: power_energy_genaratedV,
    waste_recycled: waste_recycledV,
    waste_to_landfill: waste_to_landfillV,
    facility: facilityV,
    id_facility: id_facilityV,
    fuel_volume: fuel_volumeV,
  } = values;

  const handleFacilityResponse = (response) => {
    let facilityData = response?.data.map((responseData) => {
      return {
        label: responseData.facilityName,
        value: responseData.IDs,
      };
    });
    setFacilityOptions(facilityData);
  };

  const getFacility = () => {
    axiosFetch(
      {
        method: "get",
        url: "facility/list",
      },
      handleFacilityResponse
    );
  };
  useEffect(() => {
    getFacility();
  }, []);

  return (
    <SoftBox>
      <SoftTypography variant="h5">Asset Information</SoftTypography>
      <SoftBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid item mb={3}>
              <FormField
                type={assetName.type}
                label={assetName.label}
                name={assetName.name}
                placeholder={assetName.placeholder}
                error={errors.assetName && touched.assetName}
                success={values.assetName.length > 0 && !errors.assetName}
              />
            </Grid>
            {/*             <SoftBox mb={3}>
              <CustomSelect name={category.name} label={category.label} options={categoryOptions} />
            </SoftBox> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              placeholder={description.placeholder}
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={gas_volume.type}
                label={gas_volume.label}
                name={gas_volume.name}
                placeholder={gas_volume.placeholder}
                /*                 error={errors.gas_volume && touched.gas_volume}
                success={values.gas_volume.length > 0 && !errors.gas_volume} */
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={water_volume.type}
                label={water_volume.label}
                name={water_volume.name}
                placeholder={water_volume.placeholder}
                /*                 error={errors.water_volume && touched.water_volume}
                success={values.water_volume.length > 0 && !errors.water_volume} */
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={electricity_power.type}
                label={electricity_power.label}
                name={electricity_power.name}
                placeholder={electricity_power.placeholder}
                /*                error={errors.electricity_power && touched.electricity_power}
                success={values.electricity_power.length > 0 && !errors.electricity_power} */
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={power_energy_genarated.type}
                label={power_energy_genarated.label}
                name={power_energy_genarated.name}
                placeholder={power_energy_genarated.placeholder}
                /*                 error={errors.power_energy_genarated && touched.power_energy_genarated}
                success={values.power_energy_genarated.length > 0 && !errors.power_energy_genarated} */
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={waste_recycled.type}
                label={waste_recycled.label}
                name={waste_recycled.name}
                placeholder={waste_recycled.placeholder}
                /*                 error={errors.waste_recycled && touched.waste_recycled}
                success={values.waste_recycled.length > 0 && !errors.waste_recycled} */
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={waste_to_landfill.type}
                label={waste_to_landfill.label}
                name={waste_to_landfill.name}
                placeholder={waste_to_landfill.placeholder}
                /*                 error={errors.waste_to_landfill && touched.waste_to_landfill}
                success={values.waste_to_landfill.length > 0 && !errors.waste_to_landfill} */
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={1.5}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Facility
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  name={facility.name}
                  options={facilityOptions}
                  value={facilityOptions.find((option) => option.value == values.facility)}
                  onChange={(e) => {
                    setFieldValue("facility", e.value);
                    setFieldValue("id_facility", e.value);
                  }}
                  isSearchable
                />
              </SoftBox>
              {/* <FormField
                type={facility.type}
                label={facility.label}
                name={facility.name}
                placeholder={facility.placeholder}
                error={errors.facility && touched.facility}
                success={values.facility.length > 0 && !errors.facility}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={fuel_volume.type}
                label={fuel_volume.label}
                name={fuel_volume.name}
                placeholder={fuel_volume.placeholder}
                /*                 error={errors.fuel_volume && touched.fuel_volume}
                success={values.fuel_volume.length > 0 && !errors.fuel_volume} */
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
Basic.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Basic;
