import checkout from "layouts/login/sign-up/schemas/form";

const {
  formField: { name, email, password, position, company, phone, location },
} = checkout;

const initialValues = {
  [name.name]: "",
  [email.name]: "",
  [password.name]: "",
  [position.name]: "",
  [company.name]: "",
  [phone.name]: "",
  [location.name]: "",
};

export default initialValues;
