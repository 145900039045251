import React, { useState } from 'react';
import useAxios from "hooks/useAxios";
import { Card, CardContent, Grid } from '@mui/material';
import SoftButton from 'components/SoftButton';
import SoftSelect from 'components/SoftSelect';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { toast } from 'react-toastify';
import LoaderSpinner from "components/LoaderSpinner";

const GenerateReport = ({ productOptions, productsData }) => {
    const [selectedProduct, setSelectedProduct] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [posts, error, loading, axiosFetch] = useAxios();

    const handleProductChange = (event) => {
        setSelectedProduct(event);
    };

    const handleResponse = (response) => {
        if (response.status === 200 || response.status === 'success') {
            toast.success("Report generation in progress. You will recieve it by email!");
        } else {
            toast.error("Error generating PDF. Please try again later.");
        }
    };

    const downloadReport = async () => {
        if (!selectedProduct) {
            toast.warn('Please select a product first.');
            return;
        }
        setIsDownloading(true); // Set isDownloading to true when the download starts
        try {
            var option = productOptions.find((option) => option.value === selectedProduct.value);
            var proID = "-1";
            var resourceType = "Unknown";
            if (option) {
                proID = option.proID;
                resourceType = option.resource_type;
            }
            const filteredData =
                selectedProduct.value !== "All Products"
                    ? productsData.filter((item) => item.product_data.proID === selectedProduct.value)
                    : productsData || [];
            axiosFetch(
                {
                    method: "post",
                    url: "ai/generatelcareport",
                    requestConfig: {
                        productId: proID,
                        productTitle: selectedProduct.value,
                        resourceType: resourceType,
                        product: proID == "-1" ? null : filteredData,
                    },
                },
                handleResponse
            );

            /*             const currentDomain = window.location.hostname;
                        const currentProtocol = window.location.protocol;
                        const BASE_URL = currentProtocol + "//" + currentDomain + ":8080/api";
                        const url = `${BASE_URL}/getpdfreport`;
                        const response = await axios.post(
                            url,
                            {
                                productId: proID,
                                productTitle: selectedProduct.value,
                                resourceType: resourceType,
                                product: proID == "-1" ? null : productsData,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                },
                                //responseType: "arraybuffer", // This ensures the response is treated as binary data (for PDF)
                            }
                        ); */

            /*
            // Handle the PDF response here (e.g., save or display the PDF)
            // Save the PDF using FileSaver.js
            const blob = new Blob([response.data], { type: "application/pdf" });
            // Get the current date and time
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split("T")[0];
      
            // Add the date to the file name
            const fileName = `EMVIDE_${selectedProduct.value}_${formattedDate}.pdf`;
      
            saveAs(blob, fileName);
            */
        } catch (error) {
            console.error("Error generating PDF:", error);
            // Handle errors
        } finally {
            setIsDownloading(false); // Set isDownloading to true when the download starts
        }
    };



    return (
        <Card style={{ minHeight: '500px' }}>
            {loading && <LoaderSpinner />}
            <CardContent>
                <SoftBox mb={3} >

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Report Generation
                            </SoftTypography>
                            <SoftTypography fontSize="small">
                                Select a product and click Generate Report. The report will be generated and delivered to your email.
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={8}>
                            <SoftBox mb={2}>
                                <SoftSelect
                                    label="Select Product"
                                    options={productOptions.map(option => ({ label: option.label, value: option.value }))}
                                    onChange={handleProductChange}
                                    value={selectedProduct}
                                    fullWidth
                                />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={4}>
                            <SoftButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={downloadReport}
                                disabled={isDownloading}
                            >
                                {isDownloading ? 'Generating...' : 'Generate Report'}
                            </SoftButton>
                        </Grid>
                    </Grid>

                </SoftBox>
            </CardContent>
        </Card>
    );
};

export default GenerateReport;
