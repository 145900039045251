// helpContent.js
const helpContent = {
  "/home": `
  <p>Welcome to the <strong>Home</strong> page of our analytical dashboard, where insights into product emissions and resource usage come to life. Here's a breakdown of the features and functionalities you'll find on this page:</p>
  <ul>
    <li>
      <strong>Dashboard Overview:</strong>
      <ul>
        <li><strong>Filtering Options:</strong> Use the 'Filter by Product' dropdown to select a specific product and view related analytics.</li>
        <li><strong>View Canvas:</strong> Click the 'View Canvas' button to open a detailed canvas view for the selected product in a new tab.</li>
        <li><strong>Generate Report:</strong> The 'Generate Report' button allows you to download a comprehensive PDF report of the selected product's emission data.</li>
      </ul>
    </li>
    <li>
      <strong>Data Visualization:</strong>
      <ul>
        <li><strong>Products Graph:</strong> Offers a visual representation of the selected product's data.</li>
        <li><strong>Processes Graph:</strong> Displays the various processes and their associated emissions for the selected product.</li>
        <li><strong>Resources Graph:</strong> Showcases the resources utilized by the selected product and their emissions impact.</li>
      </ul>
    </li>
    <li>
      <strong>CO2 Emission Insights:</strong>
      <ul>
        <li><strong>Average CO2 Card:</strong> Summarizes the average CO2 emissions for the selected product.</li>
        <li><strong>Average Coproduct CO2 Card:</strong> Highlights the average CO2 emissions for coproducts of the selected product.</li>
        <li><strong>Average Waste CO2 Card:</strong> Indicates the average CO2 emissions for waste generated by the selected product.</li>
        <li><strong>CO2 by Facilities Pie Chart:</strong> Breaks down CO2 emissions by different facilities.</li>
        <li><strong>CO2 by Assets Pie Chart:</strong> Segregates CO2 emissions by various assets.</li>
      </ul>
    </li>


  </ul>
  <br>
  <p>By leveraging these features, you can gain valuable insights into the environmental impact of your products and make informed decisions to optimize resources and reduce emissions.</p>
`,

  "/products": `
  <p>The <strong>Products</strong> section serves as the central hub for managing your product catalog. Here's a brief rundown of its key features and how to use them:</p>
  <ul>
    <li>
      <strong>Product List:</strong> View a comprehensive list of all your products along with essential details such as product ID, type, CO2 emissions, and more.
    </li>
    <li>
      <strong>Filtering Options:</strong> Utilize the filtering buttons at the top to view products based on different criteria, such as 'All Products', 'My Organisation Products', 'My Products', and 'My Private Products'.
    </li>
    <li>
      <strong>Adding a New Product:</strong> Click the '+ new product' button to add a new product to your catalog.
    </li>
    <li>
      <strong>Editing and Deleting:</strong> Each product entry is equipped with action buttons, allowing you to edit product details or remove a product from the list.
    </li>
    <li>
      <strong>Search and Pagination:</strong> Use the search bar to quickly find products and the pagination controls to navigate through the list.
    </li>
  </ul>
  <p>Remember to keep your product information up to date to ensure accurate tracking and reporting.</p>
`,

  "/products/new": `
    <p>The <strong>New Product</strong> form is a multi-step process designed to guide you through the creation of a new product entry. Follow these steps to ensure a smooth experience:</p>
    <ol>
      <li>
        <strong>Basic Information:</strong> Fill in the initial details about the product such as name, type, and description.
      </li>
      <li>
        <strong>Visibility Settings:</strong> Choose who can see this product by setting its visibility to public or private, and selecting the appropriate organisation visibility level.
      </li>
      <li>
        <strong>Complete the Form:</strong> Navigate through the form steps, providing the necessary information for each aspect of the product.
      </li>
      <li>
        <strong>Review and Submit:</strong> Before submitting, review all the information you've entered. Once submitted, the product will be added to your catalog and you will be redirected to the product canvas.
      </li>
    </ol>
    
  `,
  "/products/edit": `
    <p>Editing a product is a streamlined process designed to update existing product details. Below is a step-by-step guide to help you navigate the Edit Product form:</p>
    <ul>
      <li>
        <strong>Fetching Product Data:</strong> When you navigate to the edit product page, the form automatically fetches the product details using the product ID from the URL.
      </li>
      <li>
        <strong>Visibility Control:</strong> Modify who can see this product by setting its visibility to public or private and selecting the appropriate organisation visibility level.
      </li>
      <li>
        <strong>Editing Information:</strong> Update any necessary fields in the Basic Information section, including name, type, description, transport method, and more.
      </li>
      <li>
        <strong>Form Validations:</strong> As you update information, form validations ensure that all data entered meets required standards for successful submission.
      </li>
      <li>
        <strong>Submitting Changes:</strong> Once you've made the desired changes, submit the form. If the submission is successful, you'll be redirected to the products page.
      </li>
    </ul>
    <p>Ensure you have the correct permissions to edit a product. Only the product owner or users with admin roles can make changes to product details.</p>
  `,
  "/products/canvas": `
      <p>The <strong>Product Canvas</strong> is a dynamic tool designed to facilitate a comprehensive visualization and management of your product's lifecycle. This guide will assist you in effectively navigating and utilizing the Product Canvas.</p>

      <h2>Interacting with Nodes & Keyboard Shortcuts</h2>
      <ul>
        <li><strong>Adding Nodes</strong>: Drag and drop nodes from the toolbox onto the canvas to represent resources or processes.</li>
        <li><strong>Selecting & Editing Nodes</strong>: Click on a node to select it. Use "CTRL + Click" on a node to open the editing interface and modify details.</li>
        <li><strong>Deleting Nodes or Links</strong>: Press the "Delete" key to remove selected nodes or links from the canvas.</li>
        <li><strong>Linking Nodes</strong>: To illustrate relationships or flows, hold "Shift" and click on one node and then click on another node to create a link.</li>
      </ul>

      <h2>Populating Resource Information</h2>
      <p>Choose from five data source options to populate resource information effectively:</p>
      <ol>
        <li><strong>GHG Library</strong>: Fetches existing carbon emission data. Specify the number of units.</li>
        <li><strong>Custom Resource</strong>: Utilizes resources previously created by the user.</li>
        <li><strong>Interim Product</strong>: Loads an entire product, facilitating linkage of upstream supply chains.</li>
        <li><strong>Finished Product</strong>: Represents the completed product.</li>
        <li><strong>Create Custom Resource</strong>: Create a new resource, manually input CO2 data, and specify units.</li>
      </ol>
      <p>Upon selection, the required fields will be presented accordingly.</p>

      <h2>Populating Process Information</h2>
      <p>Complete the process information in four clear steps:</p>
      <ol>
        <li><strong>Basic Information</strong>: Initiate the process creation with fundamental characteristics.</li>
        <li><strong>Asset Allocation</strong>: Link assets, assign their emission profiles, and allocate CO2 emissions.</li>
        <li><strong>Facility Allocation</strong>: Incorporate facilities, establish emission profiles, and allocate emissions.</li>
        <li><strong>Additional Emission Profiles</strong>: Add extra emission profiles and specify CO2 allocations.</li>
      </ol>
      <p>Each step contributes to building a comprehensive representation of the process.</p>

      <h2>Conclusion</h2>
      <p>The Product Canvas is an extensive tool that enables clear comprehension and management of your product's life cycle, resources, and processes. It empowers you to make informed decisions for optimization and sustainability, enhancing your product's overall ecological footprint.</p>

`,
  "/facilities": `
  <p>Welcome to the <strong>Facilities</strong> section of our dashboard. This area provides a comprehensive overview of all facilities associated with your products. Here's what you can do here:</p>
  <ul>
    <li>
      <strong>Facility List:</strong> View a list of all facilities, complete with details such as facility name, description, and categories. Descriptions are truncated for brevity, but you can view the full text by hovering over or interacting with the item.
    </li>
    <li>
      <strong>Add New Facility:</strong> Use the '+ new facility' button to register a new facility into the system. This option is available at the top of the facility list.
    </li>
    <li>
      <strong>Perform Actions:</strong> Each facility in the list is accompanied by action buttons, enabling you to modify facility details or remove a facility from your list.
    </li>
    <li>
      <strong>Search and Pagination:</strong> Utilize the search functionality to quickly locate facilities, and employ pagination controls to navigate through your list efficiently.
    </li>
    <li>
      <strong>Data Refresh:</strong> The list of facilities is dynamically updated, so you're always looking at the most current information.
    </li>
  </ul>
  <p>This section ensures that you have full control over your facilities, enabling efficient management and oversight of all locations associated with your products.</p>
`,

  "/facilities/new": `
  <p>Entering a new facility into the system is a straightforward process broken down into a few key steps. Here's what you need to know:</p>
  <ul>
    <li>
      <strong>Step 1 - Basic Information:</strong> Start by providing basic information about the facility, such as the facility's name, description, and category. These details are crucial for identifying and categorizing the facility within the system.
    </li>
    <li>
      <strong>Step 2 - Emission Factor:</strong> Input the emission factors associated with the facility. This is an essential step for those facilities where emission tracking and environmental impact are pertinent.
    </li>
    <li>
      <strong>Visibility Settings:</strong> Before proceeding, you'll be able to configure visibility settings, determining who can view or edit the facility's details within your organization.
    </li>
    <li>
      <strong>Progress and Navigation:</strong> As you complete each step, use the 'next' and 'back' buttons to navigate through the form. The process is indicated visually by a stepper at the top of the form.
    </li>
    <li>
      <strong>Submission:</strong> Once all required fields are filled in, you can submit the form. If successful, the facility will be added to the system, and you will be redirected back to the facilities list.
    </li>
    <li>
      <strong>Additional Profiles:</strong> If your facility requires emission profiles, a separate section will be displayed where you can configure these additional details.
    </li>
  </ul>
  <p>Please ensure that all information entered is accurate and up-to-date. This will ensure proper management and oversight of the facility within our system.</p>
`,

  "/facilities/edit": `
  <p>Editing an existing facility allows you to update its information to reflect any changes accurately. Here's a guide to help you navigate the Edit Facility form:</p>
  <ul>
    <li>
      <strong>Fetching Facility Data:</strong> When you navigate to the edit facility page, the form automatically retrieves the facility details using the facility ID from the URL.
    </li>
    <li>
      <strong>Modifying Information:</strong> Update any fields in the form, such as the facility name, description, category, and emission factors, to match the current status of the facility.
    </li>
    <li>
      <strong>Visibility Control:</strong> Adjust who can view or edit the facility by configuring the visibility settings, ensuring the right level of privacy and accessibility.
    </li>
    <li>
      <strong>Form Validations:</strong> As you make changes, form validations ensure that all data entered conforms to the required standards for successful submission.
    </li>
    <li>
      <strong>Submitting Changes:</strong> Once you've made the necessary changes, submit the form. If the submission is successful, you'll be redirected back to the facilities page.
    </li>
    <li>
      <strong>Adding Emission Profiles:</strong> If the facility has associated emission profiles, you'll have the option to add or modify them in a dedicated section of the form.
    </li>
  </ul>
  <p>Ensure you have the correct permissions to edit a facility. Typically, only the facility owner or users with administrative roles can make changes to facility details.</p>
`,

  "/assets": `
  <p>Welcome to the <strong>Assets</strong> section of our dashboard. This is where you can manage and overview all the assets at your disposal. Here's what you can accomplish in this section:</p>
  <ul>
    <li>
      <strong>Asset Inventory:</strong> Browse through a comprehensive list of all assets, each entry displaying the asset's name, a succinct description, and a set of actionable options.
    </li>
    <li>
      <strong>Register New Asset:</strong> Easily add a new asset to your inventory by clicking the '+ new assets' button located above the asset list.
    </li>
    <li>
      <strong>Interactive Actions:</strong> Each asset is paired with an 'action' button that allows you to perform various tasks such as editing asset details or removing the asset from your inventory.
    </li>
    <li>
      <strong>Search and Sort:</strong> Find assets quickly using the search functionality, and sort your asset list according to your preferences for an optimized viewing experience.
    </li>
  </ul>
  <p>This dedicated section for assets enables you to efficiently manage and keep track of all your assets in one centralized, accessible location.</p>
`,

  "/assets/new": `
  <p>Welcome to the <strong>New Asset</strong> creation page. This interactive module guides you through the process of adding a new asset to your inventory. Follow the steps below to enter the relevant information:</p>
  <ol>
    <li>
      <strong>Basic Information:</strong> Start by providing the basic details of your asset such as its name, description, and type. This foundational information is crucial for asset identification and categorization.
    </li>
    <li>
      <strong>Emission Factor:</strong> In this section, you can enter specific details related to the asset's emission factors. This is an important step for understanding the environmental impact of your assets.
    </li>
    <li>
      <strong>Visibility Settings:</strong> Before proceeding, set your asset's visibility preferences. You can decide whether the asset is publicly visible or restricted to certain organizational levels.
    </li>
    <li>
      <strong>Review and Save:</strong> Once you've filled out all the necessary fields, review your entries for accuracy. When ready, click 'Save' to add the new asset to your inventory.
    </li>
  </ol>
`,

  "/assets/edit": `
  <p>Welcome to the <strong>Edit Asset</strong> page. This interactive module guides you through the process of editing an existing asset. Follow the steps below to edit the relevant information:</p>
  <ol>
    <li>
      <strong>Basic Information:</strong> Start by providing the basic details of your asset such as its name, description, and type. This foundational information is crucial for asset identification and categorization.
    </li>
    <li>
      <strong>Emission Factor:</strong> In this section, you can enter specific details related to the asset's emission factors. This is an important step for understanding the environmental impact of your assets.
    </li>
    <li>
      <strong>Visibility Settings:</strong> Before proceeding, set your asset's visibility preferences. You can decide whether the asset is publicly visible or restricted to certain organizational levels.
    </li>
    <li>
      <strong>Review and Save:</strong> Once you've filled out all the necessary fields, review your entries for accuracy. When ready, click 'Save' to add the new asset to your inventory.
    </li>
  </ol>
`,
  "/emission-profiles": `
  <p>Welcome to the <strong>Emission Profiles</strong> section of our dashboard. This is where you can manage and keep track of all normalized emission profiles. Here's what you can do in this section:</p>
  <ul>
    <li>
      <strong>Emission Profile Inventory:</strong> View a detailed list of all emission profiles, each entry displaying the profile's name, method, category, GHG/EEIO factor, unit of measurement, and a set of actionable options.
    </li>
    <li>
      <strong>Register New Profile:</strong> Easily add a new emission profile to your list by clicking the '+ new profile' button located above the profile list.
    </li>
    <li>
      <strong>Interactive Actions:</strong> Each emission profile is paired with an 'action' button that allows you to perform various tasks such as editing profile details or removing the profile from your list.
    </li>
    <li>
      <strong>Search and Sort:</strong> Quickly find emission profiles using the search functionality, and sort your profile list according to your preferences for an optimized viewing experience.
    </li>
  </ul>
  <p>This dedicated section for emission profiles enables you to efficiently manage and monitor all your normalized emission data in one centralized, accessible location.</p>
`,

  "/emission-profiles/new": `

  <p>When creating a new normalized emission profile, users have three methods to choose from based on the data they have and the type of emission profile they wish to create:</p>
  <ol>
    <li>
      <strong>Direct (Custom):</strong> This method is for users who have specific CO2 data. Here, you simply provide a name for the profile, enter the known GHG factor, and specify the unit of measurement. It's recommended to use the notes field to detail how the GHG factor was determined and to include any relevant links or references. This method is straightforward and is ideal for those who have precise emission data.
    </li>
    <li>
      <strong>Activity (GHG):</strong> If you don't have specific CO2 data, you can use our database to find a GHG factor. You can search by category and name, select the appropriate GHG factor, and then specify the unit of measurement. This method is user-friendly and is perfect for those who wish to leverage existing data for ease and convenience.
    </li>
    <li>
      <strong>Financial:</strong> For users looking to create a profile based on financial data (least accurate), this method utilizes EEIO factors. Simply select the relevant EEIO factor based on known financial data. This approach is suitable for those who wish to correlate financial expenditure with environmental impact.
    </li>
  </ol>
  <p>Each of these methods caters to different needs and data availability, ensuring flexibility and ease of use when creating a new emission profile. Choose the one that best fits your data and objectives to create a comprehensive and accurate emission profile.</p>
`,
  "/emission-profiles/edit": `

  <p>When creating a new normalized emission profile, users have three methods to choose from based on the data they have and the type of emission profile they wish to create:</p>
  <ol>
    <li>
      <strong>Direct (Custom):</strong> This method is for users who have specific CO2 data. Here, you simply provide a name for the profile, enter the known GHG factor, and specify the unit of measurement. It's recommended to use the notes field to detail how the GHG factor was determined and to include any relevant links or references. This method is straightforward and is ideal for those who have precise emission data.
    </li>
    <li>
      <strong>Activity (GHG):</strong> If you don't have specific CO2 data, you can use our database to find a GHG factor. You can search by category and name, select the appropriate GHG factor, and then specify the unit of measurement. This method is user-friendly and is perfect for those who wish to leverage existing data for ease and convenience.
    </li>
    <li>
      <strong>Financial:</strong> For users looking to create a profile based on financial data (least accurate), this method utilizes EEIO factors. Simply select the relevant EEIO factor based on known financial data. This approach is suitable for those who wish to correlate financial expenditure with environmental impact.
    </li>
  </ol>
  <p>Each of these methods caters to different needs and data availability, ensuring flexibility and ease of use when creating a new emission profile. Choose the one that best fits your data and objectives to create a comprehensive and accurate emission profile.</p>
`,
  "/users": `

  <p>The <strong>"/users"</strong> endpoint provides a comprehensive list of all users registered in the system. This endpoint is particularly useful for administrators and those who manage user accounts and roles within the platform.</p>
  <h3>Features</h3>
  <ul>
    <li>
      <strong>Users List:</strong> Displays a table with columns for user names, emails, roles, and actions. This makes it easy to view all registered users at a glance.
    </li>
    <li>
      <strong>Actions:</strong> Each user row comes with an action column that allows administrators to perform operations such as editing user details or removing users from the system.
    </li>
    <li>
      <strong>Search Functionality:</strong> The users list can be searched to quickly find specific users, enhancing navigability and efficiency.
    </li>
    <li>
      <strong>Dynamic Pagination:</strong> Administrators can control the number of entries displayed per page, offering flexibility in how much information is on screen at any given time.
    </li>
  </ul>
  <h3>How to Use</h3>
  <ol>
    <li>
      Upon accessing the <strong>"/users"</strong> endpoint, you will be presented with a table listing all the users. Each row represents a single user with details such as name, email, and role.
    </li>
    <li>
      To edit or remove a user, locate the desired user and use the action buttons in the action column. Editing allows you to change the user's details, while removing will delete the user's account.
    </li>
    <li>
      Use the search bar to quickly locate specific users by name or email. This is particularly helpful when dealing with a large number of user accounts.
    </li>
    <li>
      Adjust the number of users displayed per page according to your preference using the pagination controls. This feature is useful for better managing the visibility of user data.
    </li>
  </ol>
  <p>Overall, the <strong>"/users"</strong> endpoint is a robust tool for managing users within the system, offering administrators the necessary features to maintain control over user accounts and roles effectively.</p>
`,
  "/users/edit": `

  <p>The <strong>"/users/edit"</strong> endpoint is dedicated to modifying the details of existing user accounts. This function is crucial for keeping user information up-to-date, managing user roles and permissions, and overseeing financial interactions such as wallet top-ups and credit history.</p>
  
  <h3>Sections Explained</h3>
  
  <h4>Basic Information</h4>
  <p>This section allows administrators to update foundational details about the user. It typically includes fields for the user's name, email address, and contact information. Ensuring that this information is accurate is essential for effective communication and user identification within the system.</p>
  
  <h4>Access Information</h4>
  <p>Access Information pertains to the user's roles and permissions. Here, administrators can assign or modify user roles, which dictate what parts of the system the user can access and what actions they can perform. This section is vital for maintaining the integrity and security of the system by ensuring that users have appropriate access rights.</p>
  
  <h4>Change Password</h4>
  <p>In the Change Password section, administrators have the capability to initiate a password reset. This function is crucial for user security, especially in instances where a user has forgotten their password or if there is a suspicion of account compromise. It’s an essential tool for maintaining stringent security protocols.</p>
  
  <h4>Wallet Top-Up</h4>
  <p>The Wallet Top-Up section is where administrators can manage a user's financial transactions within the system. If the platform includes a wallet or credit system for accessing services or products, administrators can use this section to add credits to a user's account manually. This is particularly useful for handling exceptions or rewards.</p>
  
  <h4>Credit History</h4>
  <p>Credit History provides a record of all financial transactions associated with the user’s account. This includes previous wallet top-ups, purchases, or any other credit-related activities. It's a crucial tool for administrators to track and audit financial interactions within the system, ensuring transparency and accountability.</p>
  
  <h3>How to Use</h3>
  <ol>
    <li>
      Navigate to the desired user account through the <strong>"/users"</strong> endpoint and click on the edit button in the action column.
    </li>
    <li>
      In the Basic Information section, update foundational user details. Verify the accuracy before proceeding.
    </li>
    <li>
      In the Access Information section, modify user roles and permissions as required to ensure appropriate system access.
    </li>
    <li>
      If necessary, use the Change Password function to initiate a password reset for the user.
    </li>
    <li>
      In the Wallet Top-Up section, manage any financial transactions or credits that need to be added to the user’s account.
    </li>
    <li>
      Review the Credit History to audit past transactions and ensure they align with the user’s activity and account status.
    </li>
    <li>
      Submit the changes. The user's account will be updated immediately with the new details.
    </li>
  </ol>
  
  <p>By offering a comprehensive suite of tools for updating user information, managing roles, handling security concerns, and overseeing financial transactions, the <strong>"/users/edit"</strong> endpoint is an indispensable feature for system administrators.</p>
`,

  "/account": `

  <p>The <strong>"/account"</strong> endpoint is dedicated to viewing and modifying the details of your account. This function is crucial for keeping user information up-to-date, managing user roles and permissions, and overseeing financial interactions such as wallet top-ups and credit history.</p>
  
  <h3>Sections Explained</h3>
  
  <h4>Basic Information</h4>
  <p>This section allows administrators to update foundational details about the user. It typically includes fields for the user's name, email address, and contact information. Ensuring that this information is accurate is essential for effective communication and user identification within the system.</p>
  
  <h4>Access Information</h4>
  <p>Access Information pertains to the user's roles and permissions. Here, administrators can assign or modify user roles, which dictate what parts of the system the user can access and what actions they can perform. This section is vital for maintaining the integrity and security of the system by ensuring that users have appropriate access rights.</p>
  
  <h4>Change Password</h4>
  <p>In the Change Password section, administrators have the capability to initiate a password reset. This function is crucial for user security, especially in instances where a user has forgotten their password or if there is a suspicion of account compromise. It’s an essential tool for maintaining stringent security protocols.</p>
  
  <h4>Wallet Top-Up</h4>
  <p>The Wallet Top-Up section is where administrators can manage a user's financial transactions within the system. If the platform includes a wallet or credit system for accessing services or products, administrators can use this section to add credits to a user's account manually. This is particularly useful for handling exceptions or rewards.</p>
  
  <h4>Credit History</h4>
  <p>Credit History provides a record of all financial transactions associated with the user’s account. This includes previous wallet top-ups, purchases, or any other credit-related activities. It's a crucial tool for administrators to track and audit financial interactions within the system, ensuring transparency and accountability.</p>
  
  <h3>How to Use</h3>
  <ol>
    <li>
      Navigate to the desired user account through the <strong>"/users"</strong> endpoint and click on the edit button in the action column. Or navigate to "/account" to view and/or modify your user account.
    </li>
    <li>
      In the Basic Information section, update foundational user details. Verify the accuracy before proceeding.
    </li>
    <li>
      In the Access Information section, modify user roles and permissions as required to ensure appropriate system access.
    </li>
    <li>
      If necessary, use the Change Password function to initiate a password reset for the user.
    </li>
    <li>
      In the Wallet Top-Up section, manage any financial transactions or credits that need to be added to the user’s account.
    </li>
    <li>
      Review the Credit History to audit past transactions and ensure they align with the user’s activity and account status.
    </li>
    <li>
      Submit the changes. The user's account will be updated immediately with the new details.
    </li>
  </ol>
  
  <p>By offering a comprehensive suite of tools for updating user information, managing roles, handling security concerns, and overseeing financial transactions, the <strong>"/users/edit"</strong> endpoint is an indispensable feature for system administrators.</p>
`,

  // Add more path-content mappings as needed
  "/default": "No specific help is available for this page.",
};

export default helpContent;
