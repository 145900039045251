import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
import BasicInfo from "layouts/facility/new-facility/components/Basic";

//Use same component for both Asst and Facility
import Emission from "layouts/assets/new-asset/components/Emission";

import validations from "layouts/facility/new-facility/schemas/validations";
import form from "layouts/facility/new-facility/schemas/form";
import initialValues from "layouts/facility/new-facility/schemas/initialValues";

//import EmissionComponent from "layouts/pages/product-emission/";
import EmissionComponent from "layouts/emission/new-emission";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

import VisibilityControl from "layouts/common/visibilityControl";

function getSteps() {
  return ["1. Basic", "2. Emission Factor"];
}

function getStepContent(stepIndex, formData, handleChildData, newProfile) {
  switch (stepIndex) {
    case 0:
      return <BasicInfo formData={formData} />;
    case 1:
      return <Emission formData={formData} onChildData={handleChildData} newProfile={newProfile} />;
    default:
      return null;
  }
}

function FacilityNew() {
  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();
  const handleBack = () => setActiveStep(activeStep - 1);
  const [showEmissionProfile, setShowEmissionProfile] = useState(false);
  const [showProcess, setShowProcess] = useState(true);
  const [newProfile, setNewProfile] = useState([]);

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        actions.setSubmitting(false);
        actions.resetForm();
        setActiveStep(0);
        navigate("/facilities");
      }
    };

    axiosFetch(
      {
        method: "post",
        url: "facility/add",
        requestConfig: {
          facilityName: values.facilityName,
          description: values.description,
          category: values.category.value,
          emissionFactor: values.emissionFactor,

          security_isPublic: visibilityOptions.security_isPublic,
          security_organisation_visibility: visibilityOptions.security_organisation_visibility,
        },
      },
      handleResponseCallback
    );
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleChildData = (data) => {
    // here data is a boolea value so uisng !data means opposite to data
    setShowProcess(!data);
    setShowEmissionProfile(data);
  };

  const hanldeFormData = (data) => {
    setNewProfile(data);
  };

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid mb={1} container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={3}>
                <VisibilityControl
                  onVisibilityChange={handleVisibilityChange}
                  initialVisibility={{
                    security_isPublic: visibilityOptions.security_isPublic,
                    security_organisation_visibility:
                      visibilityOptions.security_organisation_visibility,
                  }}
                  disabled={false}
                />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ display: !showEmissionProfile ? "" : "none" }}
          sx={{ height: "100%" }}
        >
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              validationSchema={currentValidation}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          handleChildData,
                          newProfile
                        )}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </SoftButton>
                          )}
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "send" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Grid style={{ display: showEmissionProfile ? "block" : "none" }}>
              <EmissionComponent
                isCalled="true"
                onChildData={handleChildData}
                onChildFormData={hanldeFormData}
              />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FacilityNew;
