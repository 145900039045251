import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import useAxios from "hooks/useAxios"; // Assuming this is your custom hook for axios
import Papa from "papaparse";

const EcoinventUsageReportDownloader = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [posts, error, loading, axiosFetch] = useAxios();

  const handleResponse = (response) => {
    try {
      // Convert JSON to CSV
      const csv = Papa.unparse(response.data);
      const blob = new Blob([csv], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `EcoinventUsageReport-${startDate}-${endDate}.csv`); // Set the file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up after download
    } catch (error) {
      console.error("Error fetching or converting the data", error);
      // Handle error scenario (e.g., showing an error message)
    }
  };
  const handleDownloadClick = async () => {
    axiosFetch(
      {
        method: "get",
        url: `/ecoinvent/usage/${startDate}/${endDate}`,
      },
      handleResponse
    );
  };

  return (
    <SoftBox my={3}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
          <SoftTypography variant="h5" fontWeight="medium">
            Ecoinvent Accounting
          </SoftTypography>
        </SoftBox>

        <SoftBox p={3} display="flex" justifyContent="space-between" alignItems="center">
          {/* Left-aligned content */}
          <SoftBox display="flex" gap="16px" alignItems="center">
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </SoftBox>

          {/* Right-aligned button */}
          <SoftButton variant="gradient" color="info" onClick={handleDownloadClick}>
            Download Usage Report
          </SoftButton>
        </SoftBox>
      </Card>
    </SoftBox>
  );
};

export default EcoinventUsageReportDownloader;
