import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
const VisibilityControl = ({ onVisibilityChange, initialVisibility, disabled }) => {
  const publicVisibilityOptions = [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
  ];

  const organisationVisibilityOptions = [
    { label: "None", value: "none" },
    { label: "Read Only", value: "readonly" },
    { label: "Read Write", value: "readwrite" },
  ];

  const [publicVisibility, setPublicVisibility] = useState(
    publicVisibilityOptions.find((opt) => opt.value === String(initialVisibility.security_isPublic))
  );

  const [organisationVisibility, setOrganisationVisibility] = useState(
    organisationVisibilityOptions.find(
      (opt) => opt.value === initialVisibility.security_organisation_visibility
    )
  );

  const handlePublicVisibilityChange = (option) => {
    setPublicVisibility(option);
    onVisibilityChange({
      security_isPublic: option.value === "true",
      security_organisation_visibility: organisationVisibility.value,
    });
  };

  const handleOrganisationVisibilityChange = (option) => {
    setOrganisationVisibility(option);
    onVisibilityChange({
      security_isPublic: publicVisibility.value === "true",
      security_organisation_visibility: option.value,
    });
  };

  useEffect(() => {
    setPublicVisibility(
      publicVisibilityOptions.find(
        (opt) => opt.value === String(initialVisibility.security_isPublic)
      )
    );
    setOrganisationVisibility(
      organisationVisibilityOptions.find(
        (opt) => opt.value === initialVisibility.security_organisation_visibility
      )
    );
  }, [initialVisibility]);

  return (
    <SoftBox mb={1.5} className="visibility-control">
      <SoftTypography variant="h5">Privacy & Visibility</SoftTypography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SoftBox mb={1.5}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Public Visibility
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              label="Public Visibility"
              options={[
                { label: "True", value: "true" },
                { label: "False", value: "false" },
              ]}
              value={publicVisibility}
              onChange={handlePublicVisibilityChange}
              disabled={disabled}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftBox mb={1.5}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Organisation Visibility
              </SoftTypography>
            </SoftBox>
            <SoftSelect
              label="Organisation Visibility"
              options={[
                { label: "None", value: "none" },
                { label: "Read Only", value: "readonly" },
                { label: "Read Write", value: "readwrite" },
              ]}
              value={organisationVisibility}
              onChange={handleOrganisationVisibilityChange}
              disabled={disabled}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default VisibilityControl;
