const form = {
  formId: "product-form",
  formField: {
    productUID: {
      name: "productUID",
      label: "product id",
      type: "text",
      placeholder: "Generated Product ID",
      errorMsg: "Product ID is required.",
    },
    productName: {
      name: "productName",
      label: "product name",
      type: "text",
      placeholder: "Enter Product Name",
      errorMsg: "Product name is required.",
    },
    description: {
      name: "description",
      label: "description",
      type: "text",
      placeholder: "Enter Description",
      errorMsg: "Description is required.",
    },
    transport: {
      name: "transport",
      label: "transport",
      type: "text",
      placeholder: "Transport",
      errorMsg: "Transport is required.",
    },
    transportMode: {
      name: "transportMode",
      label: "Transport Mode",
      type: "select",
      placeholder: "Enter Transport Mode",
      errorMsg: "Transport mode is required.",
    },
    purpose: {
      name: "purpose",
      label: "Business Goal of Analysis",
      type: "text",
      placeholder: "Enter Business Goal of Analysis",
      errorMsg: "Business goal is required.",
    },
    travelDistance: {
      name: "travelDistance",
      label: "Transport distance",
      type: "text",
      placeholder: "Enter Distance",
      errorMsg: "Transport distance is required.",
    },
    supplier: {
      name: "supplier",
      label: "Supplier",
      type: "select",
      placeholder: "supplier",
      errorMsg: "supplier is required.",
    },
    lifeTime: {
      name: "lifeTime",
      label: "Life Time",
      type: "text",
      placeholder: "Enter Life Time",
      errorMsg: "Life time is required.",
    },
    productType: {
      name: "productType",
      label: "Product Type",
      type: "select",
      placeholder: "Select Product Type",
      errorMsg: "product type is required.",
    },
    image_url: {
      name: "image_url",
      label: "Image URL",
      type: "text",
      placeholder: "Enter Image URL",
      errorMsg: "Image URL is required.",
    },
    customer_organisation: {
      name: "customer_organisation",
      label: "Customer Organisation",
      type: "text",
      placeholder: "Enter Customer Organisation",
      errorMsg: "Customer organisation is required.",
    },
    customer_contact: {
      name: "customer_contact",
      label: "Customer Contact",
      type: "text",
      placeholder: "Enter Customer Contact",
      errorMsg: "Customer contact is required.",
    },
    customer_email: {
      name: "customer_email",
      label: "Customer Email",
      type: "email",
      placeholder: "Enter Customer Email",
      errorMsg: "Customer email is required.",
    },
    reporting_period: {
      name: "reporting_period",
      label: "Reporting Period",
      type: "text",
      placeholder: "Enter Reporting Period",
      errorMsg: "Reporting period is required.",
    },
    revision: {
      name: "revision",
      label: "Revision",
      type: "text",
      placeholder: "Enter Revision",
      errorMsg: "Revision is required.",
    },
    disclosing_gases: {
      name: "disclosing_gases",
      label: "Disclosing Gases",
      type: "text",
      placeholder: "Enter Disclosing Gases",
      errorMsg: "Disclosing gases is required.",
    },
    lcia_model: {
      name: "lcia_model",
      label: "LCIA System Model",
      type: "select",
      placeholder: "Select LCIA System Model",
      errorMsg: "System model is required.",
    },
    lcia_method: {
      name: "lcia_method",
      label: "LCIA Method",
      type: "select",
      placeholder: "Select LCIA Method",
      errorMsg: "LCIA method is required.",
    },
    unit_of_analysis: {
      name: "unit_of_analysis",
      label: "Unit of Analysis",
      type: "text",
      placeholder: "Enter Unit of Analysis",
      errorMsg: "Unit of analysis is required.",
    },
  },
};

export default form;
