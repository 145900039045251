import { useEffect, useState } from "react";

import useAxios from "hooks/useAxios";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
// import FormField from 'components/FormField';
import FormField from "layouts/assets/new-asset/components/FormField";
import CustomSelect from "components/CustomSelect";
import SoftSelect from "components/SoftSelect";
//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

function BasicInfo({ formData }) {
  const [state, setState] = useState("...");
  const [posts, error, loading, axiosFetch] = useAxios();

  const { formField, values, errors, touched, setFieldValue } = formData;

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  //const [uomOptions, setuomOptions] = useState([]);
  const defaultOption = { value: "", label: "select option" };

  const [seletcedeeioFactor, setseletcedeeioFactor] = useState(values.eeioFactor);
  const [selectedGHG, setselectedGHG] = useState("");
  const [co2Value, setco2Value] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectICECategory, setSelectICECategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("");

  const [methodOfCalculationOptions, setmethodOfCalculationOptions] = useState([
    /*     { value: "ecoinvent", label: "Activity (Ecoinvent)" }, */
    { value: "ecoinvent", label: "Ecoinvent" },
    { value: "direct", label: "Direct (Custom)" },
    // { value: "activity", label: "Activity (GHG)" },
    { value: "financial", label: "Financial" },
  ]);

  const {
    profileName,
    methodOfCalculation,
    category,
    subCategory,
    material,
    materialInput,
    ghgfactor,
    eeioFactor,
    uom,
    scope1,
    scope2,
    scope3,
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    allocation_factor,
    gwp_multiplier,
    notes,
    activityUUIDs,
  } = formField;

  const {
    profileName: profileNameV,
    methodOfCalculation: methodOfCalculationV,
    category: categoryV,
    subCategory: subCategoryV,
    material: materialV,
    materialInput: materialInputV,
    uom: uomV,
    ghgfactor: ghgfactorV,
    eeioFactor: eeioFactorV,
    scope1: scope1V,
    scope2: scope2V,
    scope3: scope3V,
    stage1: stage1V,
    stage2: stage2V,
    stage3: stage3V,
    stage4: stage4V,
    stage5: stage5V,
    allocation_factor: allocation_factorV,
    gwp_multiplier: gwp_multiplierV,
    notes: notesV,
    activityUUIDs: activityUUIDsV,
  } = values;

  useEffect(() => {
    switch (selectedMethod) {
      case "activity":
        setFieldValue("eeioFactor", "");
        fetchICECategory();
        break;
      case "financial":
        setFieldValue("ghgfactor", "");
        fetchCategory();
        break;
      default:
        break;
    }
  }, [selectedMethod]);

  useEffect(() => {
    if (selectedMethod == "activity") {
      fetchSubCategory();
    }
    if (selectedMethod == "financial") {
      let data = categoryOptions.find((obj) => obj.label === selectedMaterial);
      setFieldValue("eeioFactor", data.factor);
      setseletcedeeioFactor(data.factor);
    }
  }, [selectICECategory, selectedMaterial]);

  useEffect(() => {
    if (selectedSubCategory != "") {
      if (selectedMethod == "activity") {
        fetchGHG();
        //fetchUOM();
      } else if (selectedMethod == "ecoinvent") {
        const selectedSubCategoryContent = subCategoryOptions.find(
          (option) => option.value === selectedSubCategory
        );
        setFieldValue("uom", selectedSubCategoryContent.uom);
        setselectedGHG(selectedSubCategoryContent.ghgFactor);
        setFieldValue("ghgfactor", selectedSubCategoryContent.ghgFactor);
        setFieldValue("notes", selectedSubCategoryContent.description);
        setFieldValue("activityUUIDs", selectedSubCategoryContent.activityUUIDs);
      }
    }
  }, [selectedSubCategory]);

  const handleResponseICECategory = (response) => {
    const options = Object.entries(response.data).map(([key, value]) => ({
      value: value,
      label: value,
    }));
    setCategoryOptions(options);
  };

  const handleSubCategoryResponse = (response) => {
    const options = Object.entries(response.data).map(([key, value]) => ({
      value: value,
      label: value,
    }));
    setSubCategoryOptions(options);
  };

  /*   const handleUOMResponse = (response) => {
    const options = Object.entries(response.data).map(([key, value]) => ({
      value: value,
      label: value,
    }));
    setuomOptions(options);
  }; */

  const handleGHGResponse = (response) => {
    setselectedGHG(response.data[0].kgCO2eperUOM);
    setFieldValue("ghgfactor", response.data[0].kgCO2eperUOM);
  };

  const fetchGHG = () => {
    axiosFetch(
      {
        method: "get",
        url: "getghg/" + selectICECategory + "/" + selectedSubCategory,
      },
      handleGHGResponse
    );
  };

  /*   const fetchUOM = () => {
    axiosFetch(
      {
        method: "get",
        url: "uom/" + selectICECategory + "/" + selectedSubCategory,
      },
      handleUOMResponse
    );
  }; */

  const fetchSubCategory = () => {
    axiosFetch(
      {
        method: "get",
        url: "ghgsubcategory/" + selectICECategory,
      },
      handleSubCategoryResponse
    );
  };

  const fetchICECategory = () => {
    axiosFetch(
      {
        method: "get",
        url: "ghgcategory",
      },
      handleResponseICECategory
    );
  };

  const fetchCategory = () => {
    axiosFetch(
      {
        method: "get",
        url: "eeiosector",
      },
      handleResponse
    );
  };

  const handleResponse = (response) => {
    const options = Object.entries(response.data).map(([key, value]) => ({
      value: key,
      label: key,
      factor: value,
    }));
    setCategoryOptions(options);
  };

  const fetchEcoActivityNames = (name) => {
    axiosFetch(
      {
        method: "post",
        url: "/ai/getactivitynames",
        requestConfig: {
          search_term: name,
        },
      },
      handleEcoActivityNamesResponse
    );
  };

  const handleEcoActivityNamesResponse = (response) => {
    console.log(response);
    const activityOptions = response.data.map((item) => ({
      value: item.activityName + " (" + item.geography + ")",
      label: item.activityName + " (" + item.geography + ")",
      //id: item._id,
      description: item.productInformation,
      uom: item.unit,
      ghgFactor: item.gwp ? item.gwp : 0,
      resource_datasource: "ecoinvent",
      activityUUIDs: [
        {
          datasource: "ecoinvent",
          activityName: item.activityName,
          activityUUID: item.activityUUID,
          activityUUID_APOS: item.activityUUID_APOS,
          activityUUID_consequential: item.activityUUID_consequential,
          activityUUID_en15804: item.activityUUID_en15804,
          unit_of_measurement: item.unit,
          search_term: profileNameV,
        },
      ],
      //resource_datasource: "custom",
    }));

    setSubCategoryOptions([...activityOptions]);
    //   const targetField = document.querySelector('[name="material"]');
    //    targetField.focus();
  };

  useEffect(() => {
    setFieldValue(methodOfCalculation.name, methodOfCalculationOptions[0].value);
    setSelectedMethod(methodOfCalculationOptions[0].value);
  }, []);

  return (
    <SoftBox minHeight="15.75rem">
      {loading && <LoaderSpinner />}
      <SoftTypography variant="h5">Emission Profile Information</SoftTypography>
      <SoftBox mt={1.625}>
        <SoftBox mt={3}>
          <SoftButton
            disabled={selectedMethod != "ecoinvent" || profileNameV == ""}
            variant="gradient"
            color="primary"
            onClick={() => fetchEcoActivityNames(profileNameV)}
          >
            Copilot Search
          </SoftButton>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField
                type={profileName.type}
                label={profileName.label}
                name={profileName.name}
                value={profileNameV}
                placeholder={profileName.placeholder}
                error={errors.profileName && touched.profileName}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SoftBox mb={1.5}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Data Source
                  </SoftTypography>
                </SoftBox>
                <SoftSelect
                  defaultValue={{
                    value: methodOfCalculationOptions[0].value,
                    label: methodOfCalculationOptions[0].label,
                  }}
                  name={methodOfCalculation.name}
                  options={methodOfCalculationOptions}
                  onChange={(e) => {
                    setFieldValue(methodOfCalculation.name, e.value);
                    setSelectedMethod(e.value);
                  }}
                  isSearchable
                />
              </SoftBox>
            </Grid>
            {selectedMethod == "activity" && (
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1.5}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Emission Profile Category
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={category.name}
                    options={categoryOptions}
                    onChange={(e) => {
                      setFieldValue(category.name, e.value);
                      setSelectICECategory(e.value);
                    }}
                    isSearchable
                  />
                </SoftBox>
              </Grid>
            )}
          </Grid>
        </SoftBox>

        {selectedMethod == "activity" ||
          (selectedMethod == "ecoinvent" && (
            <SoftBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SoftBox mb={1.5}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Emission Profile
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      name={subCategory.name}
                      options={subCategoryOptions}
                      onChange={(e) => {
                        setFieldValue(subCategory.name, e.value);
                        setSelectedSubCategory(e.value);
                      }}
                      isSearchable
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormField
                    type={uom.type}
                    label={uom.label}
                    name={uom.name}
                    value={uomV}
                    placeholder={uom.placeholder}
                    error={errors.uom && touched.uom}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={ghgfactor.type}
                    label={ghgfactor.label}
                    name={ghgfactor.name}
                    value={selectedGHG}
                    placeholder={ghgfactor.placeholder}
                    error={errors.ghgfactor && touched.ghgfactor}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={notes.type}
                    label={notes.label}
                    name={notes.name}
                    placeholder={notes.placeholder}
                    value={notesV}
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          ))}
        {selectedMethod == "direct" && (
          <SoftBox>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={4}>
                <SoftBox mb={3}>
                  <FormField
                    type={materialInput.type}
                    label={materialInput.label}
                    name={materialInput.name}
                    value={materialInputV}
                    placeholder={materialInput.placeholder}
                    error={errors.materialInput && touched.materialInput}
                  />
                </SoftBox>
              </Grid> */}

              <Grid item xs={12} sm={4}>
                <SoftBox mb={3}>
                  <FormField
                    type={ghgfactor.type}
                    label={ghgfactor.label}
                    name={ghgfactor.name}
                    value={ghgfactorV}
                    placeholder={ghgfactor.placeholder}
                    error={errors.ghgfactor && touched.ghgfactor}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type={uom.type}
                  label={uom.label}
                  name={uom.name}
                  value={uomV}
                  placeholder={uom.placeholder}
                  error={errors.uom && touched.uom}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={notes.type}
                  label={notes.label}
                  name={notes.name}
                  placeholder={notes.placeholder}
                  value={notesV}
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
          </SoftBox>
        )}
        {/* {selectedMethod == "activity" &&
                    <>
                        <SoftBox mt={2} bgColor="cornsilk" borderRadius="lg"
                            shadow="lg" opacity={2} p={2}>

                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <FormField
                                        type={scope1.type}
                                        label={scope1.label}
                                        name={scope1.name}
                                        value={scope1V}
                                        placeholder={scope1.placeholder}
                                        error={errors.scope1 && touched.scope1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField
                                        type={scope2.type}
                                        label={scope2.label}
                                        name={scope2.name}
                                        value={scope2V}
                                        placeholder={scope2.placeholder}
                                        error={errors.scope2 && touched.scope2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormField
                                        type={scope3.type}
                                        label={scope3.label}
                                        name={scope3.name}
                                        value={scope3V}
                                        placeholder={scope3.placeholder}
                                        error={errors.scope3 && touched.scope3}
                                    />
                                </Grid>

                            </Grid>
                        </SoftBox>
                        <SoftBox mt={2} bgColor="cornsilk" borderRadius="lg"
                            shadow="lg" opacity={2} p={2}>

                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={2.3}>
                                    <FormField
                                        type={stage1.type}
                                        label={stage1.label}
                                        name={stage1.name}
                                        value={stage1V}
                                        placeholder={stage1.placeholder}
                                        error={errors.stage1 && touched.stage1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2.3}>
                                    <FormField
                                        type={stage2.type}
                                        label={stage2.label}
                                        name={stage2.name}
                                        value={stage2V}
                                        placeholder={stage2.placeholder}
                                        error={errors.stage2 && touched.stage2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2.3}>
                                    <FormField
                                        type={stage3.type}
                                        label={stage3.label}
                                        name={stage3.name}
                                        value={stage3V}
                                        placeholder={stage3.placeholder}
                                        error={errors.stage3 && touched.stage3}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2.3}>
                                    <FormField
                                        type={stage4.type}
                                        label={stage4.label}
                                        name={stage4.name}
                                        value={stage4V}
                                        placeholder={stage4.placeholder}
                                        error={errors.stage4 && touched.stage4}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2.3}>
                                    <FormField
                                        type={stage5.type}
                                        label={stage5.label}
                                        name={stage5.name}
                                        value={stage5V}
                                        placeholder={stage5.placeholder}
                                        error={errors.stage5 && touched.stage5}
                                    />
                                </Grid>

                            </Grid>


                        </SoftBox>
                    </>
                } */}
        {selectedMethod == "financial" && (
          <>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={10}>
                  <SoftBox mb={1.5}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Emission Profile
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      name={material.name}
                      options={categoryOptions}
                      onChange={(e) => {
                        setFieldValue(category.name, e.value);
                        setSelectedMaterial(e.value);
                        setseletcedeeioFactor(e.factor);
                      }}
                      isSearchable
                    />
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={eeioFactor.type}
                    label={eeioFactor.label}
                    name={eeioFactor.name}
                    readOnly
                    value={seletcedeeioFactor}
                    placeholder={eeioFactor.placeholder}
                    error={errors.eeioFactor && touched.eeioFactor}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={notes.type}
                    label={notes.label}
                    name={notes.name}
                    placeholder={notes.placeholder}
                    value={notesV}
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </>
        )}
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
