const form = {
  formId: "user-form",
  formField: {
    id: {
      name: "id",
      label: "ID",
      type: "text",
      placeholder: "ID",
      errorMsg: "ID is required.",
    },
    userName: {
      name: "userName",
      label: "Name",
      type: "text",
      placeholder: "Name",
      errorMsg: "Name is required.",
    },
    email: {
      name: "email",
      label: "email  ",
      type: "email",
      placeholder: "Email",
      errorMsg: "Email is required.",
    },
    role: {
      name: "role",
      label: "role  ",
      type: "select",
      placeholder: "Role",
      errorMsg: "Role is required.",
    },
    credit_point: {
      name: "credit_point",
      label: "Remaining Credits  ",
      type: "text",
      placeholder: "Credit point",
      errorMsg: "Credit point is required.",
    },
    userStatus: {
      name: "userStatus",
      label: "status ",
      type: "select",
      placeholder: "Status",
      errorMsg: "Status is required.",
    },
    position: {
      name: "position",
      label: "position ",
      type: "text",
      placeholder: "Position",
      errorMsg: "Position is required.",
    },
    company: {
      name: "company",
      label: "company ",
      type: "text",
      placeholder: "Company",
      errorMsg: "company is required.",
    },
    phone: {
      name: "phone",
      label: "phone ",
      type: "text",
      placeholder: "Phone",
      errorMsg: "phone is required.",
    },
    location: {
      name: "location",
      label: "location ",
      type: "text",
      placeholder: "Location",
      errorMsg: "location is required.",
    },
    recurring_credits: {
      name: "recurring_credits",
      label: "Monthly Recurring Credits  ",
      type: "number",
      placeholder: "Monthly Recurring Credits",
      errorMsg: "Monthly Recurring Credits is required.",
    },
    recurring_credits_deadline: {
      name: "recurring_credits_deadline",
      label: "Recurring Credits Deadline (blank for infinite) ",
      type: "date",
      placeholder: "Recurring Credits Deadline",
      errorMsg: "Recurring Credits Deadline is required.",
    },
  },
};

export default form;
