import checkout from "layouts/assets/new-asset/schemas/form";

const {
  formField: {
    assetName,
    gas_volume,
    water_volume,
    electricity_power,
    power_energy_genarated,
    waste_recycled,
    waste_to_landfill,
    facility,
    id_facility,
    //category,
    description,
    fuel_volume,
    emissionFactor,
  },
} = checkout;

const initialValues = {
  [assetName.name]: "",
  [gas_volume.name]: "",
  [water_volume.name]: "",
  [electricity_power.name]: "",
  [power_energy_genarated.name]: "",
  [waste_recycled.name]: "",
  [waste_to_landfill.name]: "",
  [facility.name]: "",
  [id_facility.name]: "",
  //[category.name]: { label: "Clothing", value: "clothing" },
  [fuel_volume.name]: "",
  [description.name]: "",
  [emissionFactor.name]: [{ profile: "", calculation_method: "", ghg_factor: "" }],
};

export default initialValues;
