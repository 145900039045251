import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import useAxios from "hooks/useAxios";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "layouts/user/user-list/components/ActionCell";

import LoaderSpinner from "components/LoaderSpinner";

import EcoinventUsageReportDownloader from "layouts/admin/EcoinventUsageReportDownloader";

function UserList() {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [dataRow, setDataRow] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const deleteHandler = (userId) => {
    axiosFetch({
      method: "delete",
      url: "user/remove/" + userId,
    });
    setSelectedId(userId);
  };
  useEffect(() => {
    if (selectedId != null) {
      let filterdTableData = dataRow.filter((row) => row.id !== selectedId);
      setDataRow(filterdTableData);
    }
  }, [selectedId]);
  const handleResponse = (response) => {
    //refromatting data for  datatable column
    let trransferedData = response?.data.map((responseData) => {
      return {
        id: responseData._id,
        createdAt: responseData.createdAt
          ? new Date(responseData.createdAt).toLocaleDateString()
          : "",
        lastLoginAt: responseData.lastLoginAt
          ? new Date(responseData.lastLoginAt).toLocaleDateString()
          : "",
        name: responseData.name,
        email: responseData.email,
        role: responseData.role,
        isActive: responseData.isActive ? "Active" : "Inactive",
        credits: responseData.remainingCredits,
        action: (
          <ActionCell id={responseData._id} {...responseData} deleteHandler={deleteHandler} />
        ),
      };
    });
    setDataRow(trransferedData);
  };

  const fetchAsset = () => {
    axiosFetch(
      {
        method: "get",
        url: "user",
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchAsset();
  }, []);

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Users List
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <DataTable
            table={{
              columns: [
                { Header: "UID", accessor: "id" },
                { Header: "Registered", accessor: "createdAt" },
                { Header: "Last Seen", accessor: "lastLoginAt" },
                { Header: "name", accessor: "name" },
                { Header: "email", accessor: "email" },
                { Header: "role", accessor: "role" },
                { Header: "status", accessor: "isActive" },
                { Header: "balance", accessor: "credits" },
                { Header: "action", accessor: "action" },
              ],
              rows: dataRow,
            }}
            entriesPerPage={{
              defaultValue: 10,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            pagination={{
              variant: "gradient",
              color: "success",
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <EcoinventUsageReportDownloader />
      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
