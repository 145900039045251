import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/user/new-user/components/FormField";
import CustomSelect from "components/CustomSelect";
import SoftSelect from "components/SoftSelect";

function BasicInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    id,
    userName,
    email,
    position,
    company,
    phone,
    location,
    role,
    credit_point,
    userStatus,
    recurring_credits,
    recurring_credits_deadline,
  } = formField;
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [userOptions, setuseroptions] = useState([
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ]);

  const [userRole, setuserRole] = useState([
    { value: "viewer", label: "Viewer" },
    { value: "editor", label: "Editor" },
    { value: "organisationAdmin", label: "Organisation Admin" },
    { value: "superAdmin", label: "Super Admin" },
  ]);

  const onChangeStatus = (selectedOption) => {
    setFieldValue("userStatus", selectedOption.value);
  };

  const onChangeRole = (selectedOption) => {
    setFieldValue("role", selectedOption.value);
  };
  return (
    <SoftBox>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <SoftBox mt={1.625} p={3}>
          <SoftTypography variant="h5">Basic Information</SoftTypography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={id.type}
                label={id.label}
                name={id.name}
                placeholder={id.placeholder}
                error={errors.id && touched.id}
                success={values.id ? values.id.length > 0 && !errors.id : false}
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={userName.type}
                label={userName.label}
                name={userName.name}
                placeholder={userName.placeholder}
                error={errors.userName && touched.userName}
                success={values.userName ? values.userName.length > 0 && !errors.userName : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={values.email ? values.email.length > 0 && !errors.email : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={position.type}
                label={position.label}
                name={position.name}
                placeholder={position.placeholder}
                error={errors.position && touched.position}
                success={values.position ? values.position.length > 0 && !errors.position : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={company.type}
                label={company.label}
                name={company.name}
                placeholder={company.placeholder}
                error={errors.company && touched.company}
                success={values.company ? values.company.length > 0 && !errors.company : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={phone.type}
                label={phone.label}
                name={phone.name}
                placeholder={phone.placeholder}
                error={errors.phone && touched.phone}
                success={values.phone ? values.phone.length > 0 && !errors.phone : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={location.type}
                label={location.label}
                name={location.name}
                placeholder={location.placeholder}
                error={errors.location && touched.location}
                success={values.location ? values.location.length > 0 && !errors.location : false}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
      <Card id="access-info" sx={{ overflow: "visible", marginTop: 3 }}>
        <SoftBox mt={1.625} p={3}>
          <SoftTypography variant="h5">Access Information</SoftTypography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Role
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                label={role.label}
                name={role.name}
                value={userRole.find((option) => option.value === values.role)}
                options={userRole}
                onChange={onChangeRole}
                isSearchable
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={credit_point.type}
                label={credit_point.label}
                name={credit_point.name}
                placeholder={credit_point.placeholder}
                error={errors.credit_point && touched.credit_point}
                success={values.credit_point.length > 0 && !errors.credit_point}
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={recurring_credits.type}
                label={recurring_credits.label}
                name={recurring_credits.name}
                placeholder={recurring_credits.placeholder}
                // error={errors.recurring_credits && touched.recurring_credits}
                // success={values.recurring_credits.length > 0 && !errors.recurring_credits}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={recurring_credits_deadline.type}
                label={recurring_credits_deadline.label}
                name={recurring_credits_deadline.name}
                placeholder={recurring_credits_deadline.placeholder}
                // error={errors.recurring_credits_deadline && touched.recurring_credits_deadline}
                // success={
                //   values.recurring_credits_deadline.length > 0 && !errors.recurring_credits_deadline
                // }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Status
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                label={userStatus.label}
                name={userStatus.name}
                value={userOptions.find((option) => option.value === values.userStatus)}
                options={userOptions}
                onChange={onChangeStatus}
                isSearchable
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </SoftBox>
  );
}

// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
