// react-router-dom components
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "hooks/useAxios";
import LoaderSpinner from "components/LoaderSpinner";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "layouts/assets/assets-list/components/ActionCell";
import { useEffect, useState } from "react";

import { getAssetsList } from "reducers/assets-reducer";

function AssetsList() {
  const [posts, error, loading, axiosFetch] = useAxios();
  const dispatch = useDispatch();
  const { assetsList } = useSelector((state) => state.assetsReducer);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [dataRow, setDataRow] = useState([]);

  const deleteHandler = (assetId) => {
    axiosFetch({
      method: "post",
      url: "assets/remove",
      requestConfig: {
        ID: assetId,
      },
    });
    setSelectedAssetId(assetId);
  };

  useEffect(() => {
    dispatch(getAssetsList());
  }, []);

  useEffect(() => {
    if (assetsList?.status) {
      const finalTableData = assetsList?.data.map((asset) => {
        return {
          asset: asset.name,
          description: asset.describtion,
          //category: asset.category,
          //type: asset.type,
          id: asset.ID,
          action: <ActionCell id={asset.ID} {...asset} deleteHandler={deleteHandler} />,
        };
      });
      setDataRow(finalTableData);
    }
  }, [assetsList]);

  useEffect(() => {
    if (selectedAssetId != null) {
      let filterdTableData = dataRow.filter((row) => row.id !== selectedAssetId);
      setDataRow(filterdTableData);
    }
  }, [selectedAssetId]);

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Assets
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/assets/new">
                <SoftButton variant="gradient" color="success" size="small">
                  + new assets
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          <DataTable
            table={{
              columns: [
                { Header: "assets", accessor: "asset" },
                { Header: "description", accessor: "description" },
                //{ Header: "category", accessor: "category" },
                //{ Header: "type", accessor: "type" },
                { Header: "action", accessor: "action" },
              ],
              rows: dataRow,
            }}
            entriesPerPage={{
              defaultValue: 7,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            pagination={{
              variant: "gradient",
              color: "success",
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssetsList;
