import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

import FormField from "layouts/login/sign-in/FormField";

import { Formik, Form } from "formik";
import form from "layouts/login/sign-in/schemas/form";
import initialValues from "layouts/login/sign-in/schemas/initialValues";
import validations from "layouts/login/sign-in/schemas/validations";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { handleSignIn } from "reducers/login-reducer";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginValues } = useSelector((state) => state.loginReducer);
  const { formId, formField } = form;
  const { email, password } = formField;
  const [status, setStatus] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (loginValues.accessToken) {
      localStorage.setItem("token", loginValues.accessToken);
      localStorage.setItem("role", loginValues.data.role);
      localStorage.setItem("userId", loginValues.data._id);
      localStorage.setItem("userCompany", loginValues.data.company);
      localStorage.setItem("userEmail", loginValues.data.email);
      localStorage.setItem("userName", loginValues.data.name);
      localStorage.setItem("userOrganisationOwner", loginValues.data.security_organisation_owner);
      navigate("/home");
    }
  }, [loginValues]);

  const handleSubmit = (values) => {
    setStatus(""); // Clear the status when submitting the form
    setIsFailed(false);
    setIsInvalid(false);
    const payload = {
      email: values.email,
      password: values.password,
      role: "admin",
    };
    dispatch(handleSignIn(payload));
  };

  return (
    <Grid container spacing={1} justifyContent="center" marginTop="200px">
      <Grid item xs={12} sm={11} md={8} lg={6} xl={3}>
        <Card>
          <SoftBox p={3} mb={1} textAlign="center">
            <SoftTypography variant="h5" fontWeight="medium">
              User Login
            </SoftTypography>
          </SoftBox>
          {loginValues.status && (
            <SoftBox p={2} mb={1} textAlign="center" color="red">
              <SoftTypography variant="h5" color="red" fontWeight="medium">
                {loginValues.status}
              </SoftTypography>
            </SoftBox>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox pt={2} pb={3} px={3}>
                  <FormField
                    type={email.type}
                    label={email.label}
                    name={email.name}
                    placeholder={email.placeholder}
                    error={errors.email && touched.email}
                  />

                  <FormField
                    type={password.type}
                    label={password.label}
                    name={password.name}
                    placeholder={password.placeholder}
                    error={errors.password && touched.password}
                  />

                  <SoftBox mt={4} mb={1}>
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      sign in
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mt={3} textAlign="center">
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      Don&apos;t have an account?&nbsp;
                      <SoftTypography
                        component={Link}
                        to="/sign-up"
                        variant="button"
                        color="dark"
                        fontWeight="bold"
                        textGradient
                      >
                        Sign up
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignIn;
