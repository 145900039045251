// react components
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios"; // Add the import for axios
import useAxios from "hooks/useAxios";
import { saveAs } from "file-saver";
import React from 'react';

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import BubbleChart from "examples/Charts/BubbleChart/index.js";
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import PieChart from "examples/Charts/PieChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import Globe from "examples/Globe";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCardNoButton";

// Images
import ivancik from "assets/images/ivancik.jpg";
import kalVisualsSquare from "assets/images/kal-visuals-square.jpg";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftBadgeDot from "components/SoftBadgeDot";
import LoaderSpinner from "components/LoaderSpinner";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Basket from "examples/Icons/Basket";
import SpaceShip from "examples/Icons/SpaceShip";
import Settings from "examples/Icons/Settings";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Cube from "examples/Icons/Cube";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InsightsIcon from "@mui/icons-material/Insights";


// @mui material components
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";


import { Card as SUDCard, CardBody, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import QuickActions from "./components/quickActions.js";
import UsageHistory from "./components/usageHistoryTimeline.js";
import LearningAndResources from "./components/learningAndResources.js";
import ProductsTable from "./components/productsTable.js";
import ProductBubbleChart from "./components/productBubbleChart.js";



//data 
import bubbleChartData from "layouts/pages/charts/data/bubbleChartData";
import UsageHistoryTimeline from "./components/usageHistoryTimeline.js";
import { set } from "date-fns";



function Home() {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [productAnalyticsData, setProductAnalyticsData] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [sectorChartData, setSectorChartData] = useState([]);
  const [geographyChartData, setgeographyChartData] = useState([]);


  const [ecoinventAnalyticsData, setEcoinventAnalyticsData] = useState([]);
  const [creditUsageChartData, setCreditUsageChartData] = useState([]);
  const [creditsInfo, setCreditsInfo] = useState({
    currentBalance: 0,
    totalAllocated: 0,
  });
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  let { userId } = useParams();
  // If there is no userId from params, try getting it from localStorage
  if (!userId) {
    userId = localStorage.getItem("userId"); // replace 'userId' with the actual key you use to store the userId
  }
  const userName = localStorage.getItem("userName");

  useEffect(() => {
    setIsLoading(true); // Set isLoading to true when fetching data
    console.log("useEffect called");
    // load only the data for the user
    fetchAnalyticsData();
  }, []);

  const fetchAnalyticsData = (response) => {
    axiosFetch(
      {
        method: "get",
        url: "products/listAnalytics",
      },
      handleProductAnalyticsResponse
    );
    const startDate = new Date();
    const endDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 2);
    axiosFetch(
      {
        method: "get",
        url: `/ecoinvent/analytics/${startDate}/${endDate}`,
      },
      handleEcoinventAnalyticsResponse
    );
    axiosFetch(
      {
        method: "get",
        url: `/wallet/creditSummary/${userId}`,
      },
      handleCreditSummaryResponse
    );
    return axiosFetch(
      {
        method: "get",
        url: "/wallet/usageHistory/" + userId,
      },
      handleCreditHistoryResponse
    );

  };
  const handleCreditHistoryResponse = (response) => {
    const data = response.data;
    const now = new Date();
    const dateStart = new Date('2023-01-01');

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= dateStart && itemDate <= now;
    });

    // Sort the filtered data by createdAt dates
    filteredData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const creditsAddedByMonthYear = {};
    const creditUsedByMonthYear = {};

    filteredData.forEach((item) => {
      const date = new Date(item.createdAt);
      const monthYear = date.toLocaleString('default', { month: 'short' }) + '-' + date.getFullYear().toString().substr(-2);

      if (item.credits > 0) {
        creditsAddedByMonthYear[monthYear] = (creditsAddedByMonthYear[monthYear] || 0) + item.credits;
      } else if (item.credits < 0) {
        creditUsedByMonthYear[monthYear] = (creditUsedByMonthYear[monthYear] || 0) + item.credits;
      }
    });

    // Generate sorted labels based on date sorting
    const labels = Object.keys({ ...creditsAddedByMonthYear, ...creditUsedByMonthYear }).sort((a, b) => {
      const [aMonth, aYear] = a.split('-');
      const [bMonth, bYear] = b.split('-');
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return new Date(aYear, months.indexOf(aMonth)) - new Date(bYear, months.indexOf(bMonth));
    });

    const creditsAddedData = labels.map(monthYear => creditsAddedByMonthYear[monthYear] || 0);
    const creditUsedData = labels.map(monthYear => creditUsedByMonthYear[monthYear] || 0);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Credits Added",
          color: "primary", // For simplicity, directly assigning primary color
          data: creditsAddedData,
        },
        {
          label: "Credit Used",
          color: "secondary", // For simplicity, directly assigning secondary color
          data: creditUsedData,
        }
      ],
    };

    setCreditUsageChartData(chartData);
  };




  const handleCreditSummaryResponse = (response) => {
    // Assuming response.data contains the currentBalance and totalAllocated
    const { currentBalance, totalAllocated } = response.data;

    // Round the numbers
    const roundedCurrentBalance = Math.round(currentBalance);
    const roundedTotalAllocated = Math.round(totalAllocated);

    setCreditsInfo({
      currentBalance: roundedCurrentBalance,
      totalAllocated: roundedTotalAllocated,
    });
  };
  const handleEcoinventAnalyticsResponse = (response) => {
    console.log(response);
    const responseData = response.data;

    // get total number of unique sectors in sectorData
    const sectorData = responseData.sectorData;
    const uniqueSectors = new Set(sectorData.map(item => item.sector));
    const totalUniqueSectors = uniqueSectors.size;
    responseData.totalUniqueSectors = totalUniqueSectors;
    // get total number of unique sectors in geography
    const geographyData = responseData.geographyData;
    const uniqueGeographies = new Set(geographyData.map(item => item.geography));
    const totalUniqueGeographies = uniqueGeographies.size;
    responseData.totalUniqueGeographies = totalUniqueGeographies;
    setEcoinventAnalyticsData(responseData);


  }
  useEffect(() => {
    if (ecoinventAnalyticsData && ecoinventAnalyticsData.sectorData) {
      generateSectorChartData();
    }
  }, [ecoinventAnalyticsData.sectorData]);
  useEffect(() => {
    if (ecoinventAnalyticsData && ecoinventAnalyticsData.geographyData) {

      genereateGeographyChartData();
    }
  }, [ecoinventAnalyticsData.geographyData]);


  // Process and transform sectorData for the chart
  const generateSectorChartData = () => {

    if (ecoinventAnalyticsData && ecoinventAnalyticsData.sectorData && ecoinventAnalyticsData.sectorData.length > 0) {

      const total = ecoinventAnalyticsData.sectorData.reduce((acc, curr) => acc + curr.totalUniqueActivityUUIDs, 0);
      let sortedData = [...ecoinventAnalyticsData.sectorData].sort((a, b) => b.totalUniqueActivityUUIDs - a.totalUniqueActivityUUIDs);
      let othersCount = 0;
      let sectorChartLabels = [];
      let sectorChartData = [];

      sortedData.forEach((item, index) => {
        sectorChartLabels.push(item.sector);
        sectorChartData.push((item.totalUniqueActivityUUIDs / total * 100).toFixed(2)); // Convert to percentage

      });

      const sectorChart = {
        labels: sectorChartLabels,
        datasets: {
          label: "Sectors",
          backgroundColors: ["primary", "secondary", "info", "success", "warning", "danger", "dark"], // Extend the color list as needed
          data: sectorChartData,
        },
      };
      setSectorChartData(sectorChart);
    } else {
      setSectorChartData({ lables: [], datasets: {} });
    }

  }

  // Process and transform geographyData for the chart
  const genereateGeographyChartData = () => {
    if (ecoinventAnalyticsData && ecoinventAnalyticsData.geographyData && ecoinventAnalyticsData.geographyData.length > 0) {
      const total = ecoinventAnalyticsData.geographyData.reduce((acc, curr) => acc + curr.totalUniqueActivityUUIDs, 0);
      let sortedData = [...ecoinventAnalyticsData.geographyData].sort((a, b) => b.totalUniqueActivityUUIDs - a.totalUniqueActivityUUIDs);
      let othersCount = 0;
      let geographyChartLabels = [];
      let geographyChartData = [];


      sortedData.forEach((item, index) => {
        geographyChartLabels.push(item.geography);
        geographyChartData.push((item.totalUniqueActivityUUIDs / total * 100).toFixed(2)); // Convert to percentage
      });

      const geographyChart = {
        labels: geographyChartLabels,
        datasets: {
          label: "Regions",
          backgroundColors: ["primary", "secondary", "info", "success", "warning", "danger", "dark"], // Extend the color list as needed
          data: geographyChartData,
        },
      };


      setgeographyChartData(geographyChart);
    } else {
      setgeographyChartData({ lables: [], datasets: {} });
    }
  };

  const handleProductAnalyticsResponse = (response) => {
    console.log(response);
    const responseData = response.data;

    // Filter out data items without co2_summary
    const filteredData = responseData.filter(item => item.co2_summary);

    const options = response.data.map(item => ({
      label: item.product_data.name,
      value: item.product_data.proID,
      ...item.product_data, // Include the rest of the properties from the original item
    }));
    setProductOptions(options);
    setProductsData(response.data);

    // Calculate total and average CO2
    const totalCo2 = filteredData.reduce((sum, item) => sum + item.co2_summary.co2, 0);
    const averageCo2 = totalCo2 / filteredData.length;

    // Calculate total and average number of nodes
    const totalNumberOfNodes = filteredData.reduce((sum, item) => sum + (item.co2_summary.number_resources + item.co2_summary.number_processes), 0);
    const averageNumberOfNodes = totalNumberOfNodes / filteredData.length;

    // Preparing products count
    const preparingProductsCount = responseData.filter(item => item.product_data.copilot_status === "preparing").length;

    // Define comparison dates
    const currentDate = new Date();
    const lastMonthDate = new Date(new Date().setMonth(currentDate.getMonth() - 1));

    const lastMonthData = filteredData.filter(item => {
      const creationDate = new Date(item.product_data.createdAt);
      return creationDate >= lastMonthDate && creationDate <= currentDate;
    });

    const totalLastMonthCo2 = lastMonthData.reduce((sum, item) => sum + item.co2_summary.co2, 0);
    const totalCurrentCo2 = totalCo2; // Since totalCo2 is already calculated for the entire dataset up to the current date

    const co2ChangePercentage = ((totalCurrentCo2 - totalLastMonthCo2) / (totalLastMonthCo2 || 1)) * 100;

    const totalLastMonthNodes = lastMonthData.reduce((sum, item) => sum + (item.co2_summary.number_resources + item.co2_summary.number_processes), 0);
    const nodesChangePercentage = ((totalNumberOfNodes - totalLastMonthNodes) / (totalLastMonthNodes || 1)) * 100;

    const lastMonthProductCount = lastMonthData.length;
    const currentProductCount = filteredData.length;
    const productsChangePercentage = ((currentProductCount - lastMonthProductCount) / (lastMonthProductCount || 1)) * 100;

    // Prepare bubbleChartData
    const bubbleChartData = {
      labels: ["Resources CO2", "Processes CO2"],
      datasets: [{
        label: "Products",
        color: "info",
        data: filteredData.map(item => ({
          name: item.product_data.name,
          totalCo2: item.co2_summary.co2,
          x: item.co2_summary.resource_co2,
          y: item.co2_summary.process_co2,
          r: Math.sqrt(item.co2_summary.co2) / 10,
        })),
      }],
    };

    let analyticsData = {
      bubbleChartData: bubbleChartData,
      averageCo2: averageCo2 || 0,
      totalProducts: filteredData.length,
      totalNodes: totalNumberOfNodes || 0,
      copilotQueueCount: preparingProductsCount,
      co2ChangePercentage: (co2ChangePercentage >= 0 ? "+" : "-") + co2ChangePercentage.toFixed(0) + "%",
      nodesChangePercentage: (nodesChangePercentage >= 0 ? "+" : "-") + nodesChangePercentage.toFixed(0) + "%",
      productsChangePercentage: (productsChangePercentage >= 0 ? "+" : "-") + productsChangePercentage.toFixed(0) + "%",
    };

    setProductAnalyticsData(analyticsData);
    setIsLoading(false); // Set isLoading to false once data is available
  };



  const sustainabilityQuotes = [
    "We do not inherit the Earth from our ancestors; \nwe borrow it from our children."
    , "Sustainability: It's not just about saving the environment, \nit's about saving our future."
    , "The greatest threat to our planet is the belief \nthat someone else will save it."
    , "Sustainable living means understanding how our lifestyle choices \nimpact the world around us and finding ways for everyone to live better."
    , "There is no such thing as 'away.' When we throw anything away, \nit must go somewhere."
    , "The Earth provides enough to satisfy every man's needs, \nbut not every man's greed."
    , "Adopt the pace of nature: her secret is patience. \nSustainability takes time but yields lasting benefits."
    , "Sustainability is about ecology, economy, and equity. \nBalancing these three pillars is key."
    , "Think globally, act locally. \nEvery small action contributes to a bigger impact."
    , "The future will either be green or not at all. \nToday's actions determine tomorrow's world."
    , "Our planet's alarm is going off, and it is time to wake up \nand take action!"
    , "Be the change you wish to see in the world. \nSustainable living starts with us."
    , "What we do in the next 10 years will determine the future \nfor the next 10,000 years."
    , "Sustainability is no longer about doing less harm. It's about \ndoing more good."
    , "Conservation is a state of harmony between men and land. \nWorking with nature, not against it."
    , "The Earth does not belong to us: we belong to the Earth. \nOur actions reflect our respect."
    , "Sustainability is a necessity, not a luxury. \nIt's critical for our survival."
    , "Every drop in the ocean counts. \nYour sustainable actions make a difference."
    , "Live simply so others may simply live. \nEmbrace sustainability for a balanced life."
    , "It's only one straw, said 8 billion people. \nEvery choice towards sustainability counts."
    , "Green is not just a color; it's a way of life. \nSustainability should be our default."
    , "Nature does not hurry, yet everything is accomplished. \nPatience is key in sustainable practices."
    , "The greatest wealth is to live content with little. \nFind richness in sustainability."
    , "Preserve and cherish the pale blue dot, \nthe only home we've ever known."
    , "Change is never easy, but always possible. \nLet's work together for a sustainable future."
    , "A society grows great when old men plant trees \nwhose shade they know they shall never sit in."
    , "Reduce, reuse, recycle. \nThree simple rules for a sustainable lifestyle."
    , "Sustainability requires the courage to save \nwhat we love most: the planet."
    , "If you think you are too small to make a difference, \ntry sleeping with a mosquito."
    , "Sustainable development is the pathway to the future \nwe want for all."
  ];
  const getTodaysQuote = () => {
    const startOfYear = new Date(new Date().getFullYear(), 0, 0);
    const diff = new Date() - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    return sustainabilityQuotes[dayOfYear % sustainabilityQuotes.length];
  };


  return (
    <div id="home-container">
      {(loading || isLoading) && <LoaderSpinner />}
      <DashboardLayout>
        <DashboardNavbar id="navbar" />
        <Grid container spacing={3}>
          {/* Left Column (65%) */}
          <Grid item xs={12} md={8}>
            {/* Row 1: 6 Metrics Cards (3 on top of 3) */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} key={0}>
                <MiniStatisticsCard
                  title={{ text: "Products Created", fontWeight: "medium" }}
                  count={(productAnalyticsData.totalProducts ? productAnalyticsData.totalProducts : 0) + " products"}
                  percentage={{
                    color: productAnalyticsData.productsChangePercentage
                      ? productAnalyticsData.productsChangePercentage.startsWith("+")
                        ? "success"
                        : "error"
                      : "info",
                    text: productAnalyticsData.productsChangePercentage ? productAnalyticsData.productsChangePercentage : "0%"
                  }}
                  icon={{ color: "dark", component: "rocket" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} key={1}>
                <MiniStatisticsCard
                  title={{ text: "Nodes Created", fontWeight: "medium" }}
                  count={(productAnalyticsData.totalNodes ? productAnalyticsData.totalNodes : 0) + " nodes"}
                  percentage={{
                    color: productAnalyticsData.nodesChangePercentage
                      ? productAnalyticsData.nodesChangePercentage.startsWith("+")
                        ? "success"
                        : "error"
                      : "info",
                    text: productAnalyticsData.nodesChangePercentage ? productAnalyticsData.nodesChangePercentage : "0%"
                  }}
                  icon={{ color: "dark", component: "hub" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} key={2}>
                <MiniStatisticsCard
                  title={{ text: "Avg. Product Emissions", fontWeight: "medium" }}
                  count={(productAnalyticsData.averageCo2 ? productAnalyticsData.averageCo2.toFixed(0) : 0) + " kgCo2e"}
                  percentage={{
                    color: productAnalyticsData.co2ChangePercentage
                      ? productAnalyticsData.co2ChangePercentage.startsWith("+")
                        ? "success"
                        : "error"
                      : "info",
                    text: productAnalyticsData.co2ChangePercentage
                      ? productAnalyticsData.co2ChangePercentage
                      : "0%"
                  }}
                  icon={{ color: "dark", component: "co2" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} key={3}>
                <MiniStatisticsCard
                  title={{ text: "Credits Consumed", fontWeight: "medium" }}
                  count={(creditsInfo.currentBalance ? creditsInfo.totalAllocated - creditsInfo.currentBalance : 0) + " credits"}
                  icon={{ color: "dark", component: "wallet" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} key={4}>
                <MiniStatisticsCard
                  title={{ text: "Ecoinvent Datasets Used", fontWeight: "medium" }}
                  count={(ecoinventAnalyticsData.overallTotalUniqueActivityUUIDs ? ecoinventAnalyticsData.overallTotalUniqueActivityUUIDs : 0) + " datasets"}
                  icon={{ color: "dark", component: "dataset" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} key={5}>
                <MiniStatisticsCard
                  title={{ text: "Copilot Queue", fontWeight: "medium" }}
                  count={(productAnalyticsData.copilotQueueCount ? productAnalyticsData.copilotQueueCount : 0) + " jobs"}
                  icon={{ color: "dark", component: "insights" }}
                />
              </Grid>



              {/* Row 2: Quick Actions Card */}
              <Grid item xs={12}>
                <QuickActions
                  productOptions={productOptions}
                  productsData={productsData}
                />
              </Grid>


              {/* Row 3: D3.js Graph (Bubble Chart) */}

              <Grid item xs={12}>
                <ProductBubbleChart title="Products Bubble Chart" analyticsData={productAnalyticsData} />
              </Grid>




            </Grid>
          </Grid>
          {/* Right Column (35%) */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BackgroundBlogCard
                  image={ivancik} // Ensure you have defined or imported 'ivancik' somewhere in your code
                  title={`Hey ${userName}!`}
                  description={
                    <>
                      {getTodaysQuote().split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </>
                  }
                />

              </Grid>
              <Grid item xs={12}>
                <ProductsTable />
              </Grid>


            </Grid>
          </Grid>
        </Grid>
        {ecoinventAnalyticsData.totalUniqueSectors > 0 && (
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} md={6}>
              <ReportsDoughnutChart
                title="Ecoinvent Datasets Used by Sector"
                count={{ number: ecoinventAnalyticsData.totalUniqueSectors || 0, text: "Sectors" }}
                chart={sectorChartData}
                tooltip="Percentage of Ecoinvent Datasets by Sector"
                chartToLeft={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>

              <ReportsDoughnutChart
                title="Ecoinvent Datasets Used by Regions"
                count={{ number: ecoinventAnalyticsData.totalUniqueGeographies || 0, text: "Regions" }}
                chart={geographyChartData}
                tooltip="Percentage of Ecoinvent Datasets by Regions"
                chartToLeft={false}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3} mt={1}>


          <Grid item xs={12} md={12}>
            <Grid container spacing={3}>
              {/* Row 4: Line Graph for Credits Consumption Over Time */}
              <Grid item xs={12}>
                <GradientLineChart
                  title="Usage Trends"
                  chart={creditUsageChartData}
                />
              </Grid>


            </Grid>
          </Grid>

          {/*           <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <UsageHistoryTimeline />
              </Grid>
            </Grid>
          </Grid> */}

          {/* Row 5: Learning and Resources */}
          <Grid item xs={12}>
            <LearningAndResources />
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
export default Home;
