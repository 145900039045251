const form = {
  formId: "signin-form",
  formField: {
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Email",
      errorMsg: "Email is required.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
      errorMsg: "Password is required.",
    },
  },
};

export default form;
