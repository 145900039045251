import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import SoftButton from 'components/SoftButton';

const ConfirmDialog = (props) => {
  console.log(props);
  const handleConfirm = () => {
    props.onConfirm(); // lefting the state up
  }

  const handleClose = () => {
    props.onClose(); //lefting the state up
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{props.message}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <SoftButton color="success" onClick={handleConfirm}>{props.confirmLabel}</SoftButton>
        <SoftButton color="info" onClick={handleClose}>{props.cancelLabel}</SoftButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
