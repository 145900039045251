import { useState, useEffect } from "react";
import axiosInstance from "apis/apiV1";

const useAxios = () => {
    const [response, setResponse] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); //different!
    const [controller, setController] = useState();

    const axiosFetch = async (configObj, handleResponseCallback) => {
        const {            
            method,
            url,
            requestConfig = {}
        } = configObj;
        
        try {
            
            setLoading(true);
            const ctrl = new AbortController();
            setController(ctrl);
            const res = await axiosInstance[method.toLowerCase()](url, {
                ...requestConfig,
                signal: ctrl.signal
            });
            
            setResponse(res.data);
            handleResponseCallback(res.data)
        } catch (err) {
            
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {        

        // useEffect cleanup function
        return () => controller && controller.abort();

    }, [controller]);

    return [response, error, loading, axiosFetch];
}

export default useAxios