// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import CreditCard from "examples/Icons/CreditCard";

// Telkoa URL
import NewAsset from "layouts/assets/new-asset";

import AssetsList from "layouts/assets/assets-list";

import NewFacility from "layouts/facility/new-facility";
import FacilityList from "layouts/facility/facility-list";
import EmissionList from "layouts/emission/emission-list";

import NewOrganization from "layouts/organization/new-organization";
import OrganizatonList from "layouts/organization/organization-list";

import UserList from "layouts/user/user-list";
import EditUser from "layouts/user/edit-user";

import ProductList from "layouts/product/product-list";

import NewProduct from "layouts/product/new-product";

import HomeDashboard from "layouts/home";

import HelpPage from "layouts/help";
import CopilotPlayground from "layouts/copilot";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Basket from "examples/Icons/Basket";
import SpaceShip from "examples/Icons/SpaceShip";
import Settings from "examples/Icons/Settings";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Cube from "examples/Icons/Cube";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import InsightsIcon from "@mui/icons-material/Insights";

import D3 from "layouts/pages/D3/index";

import NewEmission from "layouts/emission/new-emission";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: "home",
    route: "/home",
    component: <HomeDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    icon: <SpaceShip size="16px" />,
    name: "Products",
    key: "products",
    route: "/products",
    noCollapse: true,
    component: <ProductList />,
  },
  {
    name: "New Product",
    key: "newProduct",
    route: "/products/new",
    noCollapse: true,
    component: <NewProduct />,
    readable: false,
  },
  {
    type: "collapse",
    // icon: <Cube size="16px" />,
    // icon: <AutoFixHighIcon size="16px" />,
    icon: <InsightsIcon size="16px" />,
    name: "Copilot Playground",
    key: "copilot-playground",
    route: "/copilot-playground",
    noCollapse: true,
    component: <CopilotPlayground />,
  },
  {
    type: "divider",
    key: "divider1",
  },
  {
    type: "title",
    title: "Definitions",
  },
  {
    type: "collapse",
    icon: "factory",
    name: "Facilities",
    key: "facilities",
    route: "/facilities",
    noCollapse: true,
    component: <FacilityList />,
  },
  {
    name: "New Facility",
    key: "newFacility",
    route: "/facilities/new",
    noCollapse: true,
    component: <NewFacility />,
    readable: false,
  },
  {
    type: "collapse",
    icon: <Settings size="16px" />,
    route: "/home",
    name: "Assets",
    key: "assets",
    route: "/assets",
    noCollapse: true,
    component: <AssetsList />,
  },
  {
    name: "New Asset",
    key: "newAsset",
    route: "/assets/new",
    noCollapse: true,
    component: <NewAsset />,
    readable: false,
  },
  /*   {
    type: "divider",
    key: "divider2",
  },
  {
    type: "title",
    title: "Advanced",
    key: "advancedTitle",
  }, */
  {
    type: "collapse",
    icon: "co2",
    name: "Emission Profiles",
    key: "emission-profiles",
    route: "/emission-profiles",
    noCollapse: true,
    component: <EmissionList />,
  },
  {
    name: "New Emission",
    key: "newEmission",
    route: "/emission-profiles/new",
    noCollapse: true,
    component: <NewEmission />,
    readable: false,
  },
  {
    type: "divider",
    key: "divider3",
  },
  {
    type: "title",
    title: "User Management",
    key: "userManagementTitle",
  },
  // {
  //   type: "collapse",
  //   icon: <Office size="16px" />,
  //   name: "Orgainsations",
  //   key: "organisations",
  //   route: "/organisations",
  //   noCollapse: true,
  //   component: <OrganizatonList />,
  // },
  {
    type: "collapse",
    icon: "group",
    name: "Users",
    key: "users",
    route: "/users",
    noCollapse: true,
    component: <UserList />,
  },
  {
    type: "collapse",
    icon: "person",
    name: "Account",
    key: "account",
    route: "/account",
    noCollapse: true,
    component: <EditUser />,
  },
  {
    name: "New Organization",
    key: "new-organisation",
    route: "organisations/new",
    noCollapse: true,
    component: <NewOrganization />,
    readable: false,
  },
  {
    type: "divider",
    key: "divider4",
  },
  {
    type: "collapse",
    icon: "help",
    name: "Help Center",
    key: "help",
    route: "/help",
    noCollapse: true,
    component: <HelpPage />,
  },
];

export default routes;
