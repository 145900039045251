import { React, useState, memo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Formik, Form, ErrorMessage } from "formik";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/user/new-user/components/FormField";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import LoaderSpinner from "components/LoaderSpinner";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Basic from "layouts/user/new-user/components/Basic";
import ConfirmDialog from "components/ConfirmDialog";
import form from "layouts/user/new-user/schemas/form";
import initialValues from "layouts/user/new-user/schemas/initialValues";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";

const EditUser = () => {
  const [posts, error, loading, axiosFetch] = useAxios();

  const [formValue, setFormValue] = useState(null);
  let { id } = useParams();
  // If there is no userId from params, try getting it from localStorage
  if (!id) {
    id = localStorage.getItem("userId"); // replace 'userId' with the actual key you use to store the userId
  }
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  const [dataRow, setDataRow] = useState([]);

  const creditTypeOptions = [
    { value: "", label: "Select..." },
    { value: "Purchased", label: "Purchased" },
    { value: "Earned", label: "Earned" },
    { value: "Used", label: "Used" },
    { value: "Promotional", label: "Promotional" },
  ];
  const [creditPointToAddType, setCreditPointToAddType] = useState("");
  const [creditPointToAdd, setCreditPointToAdd] = useState(0);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(null);
  const [passwordChangeError, setPasswordChangeError] = useState(null);

  const handleResponse = (response) => {
    let formattedResponseData = response?.data
      ? {
          id: id,
          userName: response.data.name || null,
          email: response.data?.email || null,
          role: response.data?.role || null,
          position: response.data?.position || null,
          company: response.data?.company || null,
          phone: response.data?.phone || null,
          location: response.data?.location || null,
          credit_point: response.data?.credit_point || 0,
          userStatus: response.data.isActive ? "Active" : "Inactive",

          recurring_credits: response.data?.recurring_credits || 0,
          recurring_credits_deadline: response.data?.recurring_credits_deadline
            ? new Date(response.data.recurring_credits_deadline).toISOString().slice(0, 10)
            : null || null,
        }
      : {};
    setFormValue(formattedResponseData);
  };

  const fetchAsset = () => {
    return axiosFetch(
      {
        method: "get",
        url: "user/" + id,
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchCredit();
  }, [dataRow]); // after getting credit history fetch the credits available

  useEffect(() => {
    fetchAsset().then(fetchCreditHitory());
  }, []);

  const { formId, formField } = form;

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  // const handleDeleteResponse = (response) => {
  //   if (response?.status === "success") {
  //     navigate("/users");
  //   }
  // };

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.message == "User has been updated") {
        actions.setSubmitting(false);
        actions.resetForm();
        toast.success("Account updated successfully!");
        fetchAsset();
      }
    };

    axiosFetch(
      {
        method: "put",
        url: "user/update/" + id,
        requestConfig: {
          name: values.userName,
          email: values.email,
          role: values.role,
          isActive: values.userStatus == "Active" ? true : false,
          position: values.position,
          company: values.company,
          phone: values.phone,
          location: values.location,

          recurring_credits: values.recurring_credits,
          recurring_credits_deadline: values.recurring_credits_deadline,
        },
      },
      handleResponseCallback
    );
  };
  const handleAddCreditsResponseCallback = (response) => {
    // handle the response from the API
    fetchCredit().then(fetchCreditHitory);

    setCreditPointToAddType("");
    setCreditPointToAdd(0);
    //setFieldValue("credit_point_to_add_type", "");
    //console.log("set field value");
  };
  const addCreditPoints = (type, points) => {
    axiosFetch(
      {
        method: "post",
        url: "/wallet/add",
        requestConfig: {
          userId: id,
          type,
          credits: points,
        },
      },
      handleAddCreditsResponseCallback
    );
  };

  const handlecreditHistoryResponse = (response) => {
    //refromatting data for  datatable column
    let data = response?.data.map((responseData) => {
      return {
        date: responseData.createdAt
          ? new Date(responseData.createdAt).toLocaleString() // or use toLocaleDateString() for just the date
          : "",
        type: responseData.type,
        credits: responseData.credits,
      };
    });
    if (data) setDataRow(data);
  };

  const fetchCreditHitory = () => {
    return axiosFetch(
      {
        method: "get",
        url: "/wallet/usageHistory/" + id,
      },
      handlecreditHistoryResponse
    );
  };

  const handlecreditResponse = (response) => {
    if (response && response.data) {
      setFormValue((prevFormValue) => {
        return {
          ...prevFormValue,
          credit_point: response.data.credits
            ? response.data.credits.toFixed(1)
            : response.data.credits,
        };
      });
    }
  };

  const fetchCredit = () => {
    return axiosFetch(
      {
        method: "get",
        url: "/wallet/" + id,
      },
      handlecreditResponse
    );
  };
  const handleChangePasswordResponse = (response) => {
    setPasswordChangeSuccess("Password updated successfully");
    //setPasswordChangeError("Failed to update password");
  };
  const handlePasswordUpdate = (currentPassword, newPassword, confirmNewPassword) => {
    // reset the success and error states
    setPasswordChangeSuccess(null);
    setPasswordChangeError(null);
    // Perform validation checks
    if (newPassword !== confirmNewPassword) {
      // Show error message that passwords do not match
      //toast.success("Password updated successfully!");
      setPasswordChangeError("Passwords do not match");
      return;
    }
    axiosFetch(
      {
        method: "post",
        url: "/user/resetpassword",
        requestConfig: {
          userId: id,
          currentPassword,
          password: newPassword,
          confirmNewPassword,
        },
      },
      handleChangePasswordResponse
    );
  };

  return (
    <>
      <DashboardLayout>
        {loading && <LoaderSpinner />}
        <ToastContainer />
        <DashboardNavbar />
        <SoftBox my={3}>
          <Formik
            initialValues={formValue || initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <Grid container spacing={3}>
                  {/* <SoftBox mb={3}> */}
                  {/* <Grid container spacing={3} alignItems="center"> */}
                  <Grid item xs={12} lg={9}>
                    <SoftTypography variant="h4" fontWeight="medium">
                      User Account
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} lg={3} display="flex" justifyContent="flex-end">
                    <SoftBox mx={2} mb={3}>
                      {userRole == "superAdmin" && (
                        <SoftButton variant="gradient" color="success" type="submit">
                          Update
                        </SoftButton>
                      )}
                    </SoftBox>
                    {/* </Grid> */}
                  </Grid>
                  {/* </SoftBox> */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                      <Sidenav />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <SoftBox>
                        <Basic
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          }}
                        />
                      </SoftBox>
                      <Card id="change-password" sx={{ overflow: "visible", marginTop: 3 }}>
                        <ChangePassword
                          handlePasswordUpdate={handlePasswordUpdate}
                          passwordChangeSuccess={passwordChangeSuccess}
                          passwordChangeError={passwordChangeError}
                        ></ChangePassword>
                      </Card>
                      <Card id="wallet-top-up" sx={{ overflow: "visible", marginTop: 3 }}>
                        <SoftBox p={3}>
                          <SoftTypography variant="h4" fontWeight="medium">
                            Wallet Top-up
                          </SoftTypography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Credit Type
                                </SoftTypography>
                              </SoftBox>
                              <SoftSelect
                                value={creditTypeOptions.find(
                                  (option) => option.value === creditPointToAddType
                                )}
                                label="Credit Type"
                                options={creditTypeOptions}
                                onChange={(option) => setCreditPointToAddType(option.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Credit Points
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput
                                type="number"
                                label="Credit Points to Add"
                                value={creditPointToAdd}
                                onChange={(e) => setCreditPointToAdd(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <SoftButton
                                variant="gradient"
                                color="success"
                                type="button"
                                disabled={
                                  !creditPointToAdd ||
                                  !creditPointToAddType ||
                                  creditPointToAdd === 0
                                }
                                onClick={() =>
                                  addCreditPoints(creditPointToAddType, creditPointToAdd)
                                }
                              >
                                {" "}
                                Add Credit Points
                              </SoftButton>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </Card>

                      <Card id="credit-history" sx={{ overflow: "visible", marginTop: 3 }}>
                        <SoftBox p={3}>
                          <SoftTypography variant="h4" fontWeight="medium">
                            Credit Usage History
                          </SoftTypography>
                          <DataTable
                            table={{
                              columns: [
                                { Header: "date", accessor: "date" },
                                { Header: "type", accessor: "type" },
                                { Header: "credits", accessor: "credits" },
                              ],
                              rows: dataRow,
                            }}
                            entriesPerPage={{
                              defaultValue: 5,
                              entries: [5, 7, 10, 15, 20, 25],
                            }}
                            pagination={{
                              variant: "gradient",
                              color: "success",
                            }}
                            canSearch
                            isSorted
                          />
                        </SoftBox>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default memo(EditUser);
