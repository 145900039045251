import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import useAxios from "hooks/useAxios";
import LoaderSpinner from "components/LoaderSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubmitTicketModal = ({ open, onClose }) => {
    const [posts, error, loading, axiosFetch] = useAxios();
    const theme = useTheme();
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 8,
        borderRadius: 2,
    };

    useEffect(() => {
        if (open) {
            setSubject('');
            setBody('');
        }
    }, [open]);

    const handleSubmitTicketResponse = (response) => {
        if (response.status === 200) {
            toast.success("Ticket submitted successfully");
            onClose();
        } else {
            toast.error("Error submitting ticket");
            onClose();
        }
    };
    const handleSubmitTicket = (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();
        axiosFetch(
            {
                method: "post",
                url: "submitTicket",
                requestConfig: {
                    subject: subject,
                    body: body,
                },
            },
            handleSubmitTicketResponse
        );
        // clear the form fields
        setSubject('');
        setBody('');
        onClose();
    };

    return (
        <>

            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        Submit a Ticket
                    </Typography>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmitTicket}>
                        <SoftInput
                            placeholder="Subject"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            sx={{ background: theme.palette.background.default, borderRadius: 1 }}
                        />
                        <SoftInput
                            placeholder="Message"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            sx={{ background: theme.palette.background.default, borderRadius: 1, mt: 2 }}
                        />
                        <SoftBox mt={2} display="flex" justifyContent="flex-end">
                            <SoftButton variant="gradient" color="secondary" type="submit">
                                Submit Ticket
                            </SoftButton>
                        </SoftBox>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default SubmitTicketModal;