// react components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadgeDot from "components/SoftBadgeDot";
import LoaderSpinner from "components/LoaderSpinner";

// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable";
import ActionCell from "layouts/product/product-list/components/ActionCell";
import { toast } from "react-toastify";
function ProductsTable() {
    const [posts, error, loading, axiosFetch] = useAxios();
    const [selectedId, setSelectedId] = useState(null);
    const [dataRow, setDataRow] = useState([]);

    const [filterOption, setFilterOption] = useState("My Products");

    const handleResponse = (response) => {
        try {
            //refromatting data for  datatable column
            response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            let transferredData = response?.data.map((responseData) => {
                responseData.id = responseData.proID;
                let copilotStatusDotColor = "info";
                if (responseData.copilot_status) {
                    copilotStatusDotColor = responseData.copilot_status.toLowerCase() == "preparing" ? "warning"
                        : responseData.copilot_status.toLowerCase() == "failed" ? "error" : "success";
                }
                return {
                    productName: responseData.name.length > 30 ? responseData.name.substring(0, 30) + "..." : responseData.name || "",
                    description:
                        responseData.description && responseData.description.trim() !== ""
                            ? responseData.description.length <= 30
                                ? responseData.description
                                : responseData.description.substring(0, 30) + "..."
                            : "",
                    purpose:
                        responseData.purpose && responseData.purpose.trim() !== ""
                            ? responseData.purpose.length <= 30
                                ? responseData.purpose
                                : responseData.purpose.substring(0, 30) + "..."
                            : "",
                    service_life_time: responseData.service_life_time || "",
                    emission: responseData.emission ? responseData.emission.toFixed(3) : "",
                    coproduct_co2: responseData.coproduct_co2 ? responseData.coproduct_co2.toFixed(3) : "",
                    waste_co2: responseData.waste_co2 ? responseData.waste_co2.toFixed(3) : "",
                    id: responseData.proID,
                    productType: responseData.resource_type,
                    number_resources: responseData.number_resources,
                    number_processes: responseData.number_processes,
                    number_resources_processes:
                        (responseData.number_resources ? responseData.number_resources : 0) +
                        "|" +
                        (responseData.number_processes ? responseData.number_processes : 0),
                    createdAt: responseData.createdAt
                        ? new Date(responseData.createdAt).toLocaleString() // or use toLocaleDateString() for just the date
                        : "",
                    updatedAt: responseData.updatedAt
                        ? new Date(responseData.updatedAt).toLocaleString() // or use toLocaleDateString() for just the date
                        : "",
                    organisation: responseData.security_organisation_owner,
                    user: responseData.security_user_owner_email,
                    customer: responseData.customer_organisation,
                    copilot_status: (
                        <SoftBox ml={-1.325}>
                            <SoftBadgeDot size="small"
                                badgeContent={responseData.copilot_status} color={copilotStatusDotColor} />
                        </SoftBox>
                    ),
                    copilot_completion_time: responseData.copilot_completion_time,
                    accuracy: responseData.accuracy,
                    action: (
                        <ActionCell
                            id={responseData.proID}
                            {...responseData}
                            deleteHandler={deleteHandler}
                            jsonExportHandler={jsonExportHandler}
                            csvExportHandler={csvExportHandler}
                        />
                    ),
                };
            });
            setDataRow(transferredData);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchProduct = () => {
        let url = "products/list";
        if (filterOption === "My Organisation Products") {
            const organisation = localStorage.getItem("userOrganisationOwner");
            url += `?organisation=${organisation}`;
        } else if (filterOption === "My Products") {
            const userEmail = localStorage.getItem("userEmail");
            url += `?userEmail=${userEmail}`;
        } else if (filterOption === "My Private Products") {
            const userEmail = localStorage.getItem("userEmail");
            url += `?userEmail=${userEmail}&private=true`;
        }
        axiosFetch(
            {
                method: "get",
                url: url,
            },
            handleResponse
        );
    };

    // Update the fetchProduct effect to run whenever the filter option changes
    useEffect(() => {
        fetchProduct();
    }, [filterOption]);

    const deleteHandler = (prodId) => {
        axiosFetch({
            method: "post",
            url: "products/remove",
            requestConfig: {
                proID: prodId,
            },
        });
        setSelectedId(prodId);
    };

    const jsonExportHandler = async (id) => {
        axiosFetch(
            {
                method: "get",
                url: "products/export/" + id,
            },
            handleJsonExportResponse
        );
    };
    const handleJsonExportResponse = (response) => {
        try {
            const productName = response.data.product.name;
            const productID = response.data.product.proID;
            // Pretty print JSON with 2-space indentation
            const prettyJson = JSON.stringify(response.data, null, 2);
            // Create a blob with the pretty printed JSON
            const blob = new Blob([prettyJson], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", productID + "_" + productName + ".json");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            toast.error("Error exporting product");
            console.error("Error exporting product:", error);
        }
    };


    const csvExportHandler = async (id) => {
        axiosFetch(
            {
                method: "get",
                url: "products/export-flat-csv/" + id,
            },
            handleCSVExportResponse
        );
    };


    const handleCSVExportResponse = (response) => {
        try {
            const csvContent = response.data.nodeDataCSV;
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0];
            saveAs(blob, `${response.data.product.name}_${formattedDate}_Export_Emvide.csv`);
        } catch (error) {
            toast.error("Error exporting product");
            console.error("Error exporting product:", error);
        }
    };

    useEffect(() => {
        if (selectedId != null) {
            let filterdTableData = dataRow.filter((row) => row.id !== selectedId);
            setDataRow(filterdTableData);
        }
    }, [selectedId]);

    useEffect(() => {
        fetchProduct();
    }, []);

    return (
        <SoftBox>
            {loading && <LoaderSpinner />}
            <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                    <SoftBox lineHeight={1}>
                        <SoftTypography variant="h5" fontWeight="medium">
                            Recent Products
                        </SoftTypography>

                    </SoftBox>
                </SoftBox>
                <DataTable
                    table={{
                        columns: [
                            /*                             { Header: "Date Updated", accessor: "updatedAt" }, */
                            { Header: "Product", accessor: "productName" },
                            { Header: "Copilot Status", accessor: "copilot_status" },
                            { Header: "action", accessor: "action" },
                        ],
                        rows: dataRow,
                    }}
                    entriesPerPage={{
                        defaultValue: 5,
                        entries: [5],
                    }}
                    pagination={{
                        variant: "gradient",
                        color: "success",
                    }}
                    canSearch
                    isSorted
                />
            </Card>
        </SoftBox>
    );
}

export default ProductsTable;
