import checkout from "layouts/user/new-user/schemas/form";

const {
  formField: {
    id,
    userName,
    email,
    password,
    role,
    credit_point,
    position,
    company,
    phone,
    location,
    recurring_credits,
    recurring_credits_deadline,
  },
} = checkout;

const initialValues = {
  [id.name]: "",
  [userName.name]: "",
  [email.name]: "",
  [position.name]: "",
  [company.name]: "",
  [phone.name]: "",
  [location.name]: "",
  [role.name]: "",
  [credit_point.name]: "",
  [recurring_credits.name]: "",
  [recurring_credits_deadline.name]: "",
};

export default initialValues;
