import { React, useState, memo, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Formik, Form, ErrorMessage } from "formik";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import LoaderSpinner from "components/LoaderSpinner";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Basic from "layouts/facility/new-facility/components/Basic";
//import Emission from "layouts/assets/new-asset/components/Emission";
import Emission from "layouts/emission/select-emissions";
//import EmissionComponent from "layouts/pages/product-emission/";
import EmissionComponent from "layouts/emission/new-emission";

import ConfirmDialog from "components/ConfirmDialog";
import validations from "layouts/facility/new-facility/schemas/validations";
import form from "layouts/facility/new-facility/schemas/form";
import initialValues from "layouts/facility/new-facility/schemas/initialValues";

import VisibilityControl from "layouts/common/visibilityControl";

const EditFacility = () => {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [showConfirm, setShowConfirm] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [newProfile, setNewProfile] = useState([]);
  const [showEmissionProfile, setShowEmissionProfile] = useState(false);
  const [showProcess, setShowProcess] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };
  const canChangeVisibility = useMemo(() => {
    if (formValue && formValue.security_user_owner_email) {
      const currentUserRole = localStorage.getItem("role");
      const currentUserEmail = localStorage.getItem("userEmail");
      if (
        currentUserRole === "superAdmin" ||
        currentUserRole === "organisationAdmin" ||
        formValue.security_user_owner_email === currentUserEmail
      ) {
        return true;
      }
    }
    return false;
  }, [formValue]);

  const handleResponse = (response) => {
    let formattedResponseData = response?.data.map((responseData) => {
      return {
        facilityName: responseData.facilityName,
        category: { label: responseData.category, value: responseData.category },
        description: responseData.description,
        emissionFactor: responseData.emissionFactor,

        security_user_owner_id: responseData.security_user_owner_id,
        security_user_owner_email: responseData.security_user_owner_email,
        security_isPublic: responseData.security_isPublic,
        security_organisation_visibility: responseData.security_organisation_visibility,
      };
    });

    const responseObject = formattedResponseData.reduce((obj, item) => {
      return item;
    }, {});
    setFormValue(responseObject);
    setVisibilityOptions({
      security_isPublic: responseObject.security_isPublic,
      security_organisation_visibility: responseObject.security_organisation_visibility,
    });
  };
  const fetchAsset = () => {
    axiosFetch(
      {
        method: "get",
        url: "facilitylist/" + id,
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchAsset();
  }, []);

  const { formId, formField } = form;

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        actions.setSubmitting(false);
        actions.resetForm();
        navigate("/facilities");
      }
    };

    axiosFetch(
      {
        method: "post",
        url: "facility/update",
        requestConfig: {
          facilityName: values.facilityName,
          describtion: values.description,
          category: values.category.value,
          emissionFactor: values.emissionFactor,
          ID: id,

          security_isPublic: visibilityOptions.security_isPublic,
          security_organisation_visibility: visibilityOptions.security_organisation_visibility,
        },
      },
      handleResponseCallback
    );
  };

  const handleChildData = (data) => {
    // here data is a boolea value so uisng !data means opposite to data
    setShowProcess(!data);
    setShowEmissionProfile(data);
  };

  const hanldeFormData = (data) => {
    setNewProfile(data);
  };

  return (
    <>
      {loading && <LoaderSpinner />}

      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={3} style={{ display: !showEmissionProfile ? "block" : "none" }}>
          <Formik
            initialValues={formValue || initialValues}
            // validationSchema={validations}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox mb={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <SoftTypography variant="h4" fontWeight="medium">
                        Edit Facility
                      </SoftTypography>
                      <SoftBox mt={1} mb={2}></SoftBox>
                    </Grid>
                    <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end">
                      <SoftBox>
                        <SoftButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="success"
                        >
                          Update Facility
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={9}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <VisibilityControl
                          onVisibilityChange={handleVisibilityChange}
                          initialVisibility={{
                            security_isPublic: visibilityOptions.security_isPublic,
                            security_organisation_visibility:
                              visibilityOptions.security_organisation_visibility,
                          }}
                          disabled={!canChangeVisibility}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <Basic
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <Emission
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          }}
                          onChildData={handleChildData}
                          newProfile={newProfile}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <Grid style={{ display: showEmissionProfile ? "block" : "none" }}>
                      <EmissionComponent
                        isCalled="true"
                        onChildData={handleChildData}
                        onChildFormData={hanldeFormData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SoftBox>

        <Footer />
      </DashboardLayout>
    </>
  );
};

export default EditFacility;
