import * as d3 from "d3";

export const functionVoid = (...rest) => { }
// This function handles the 'mousedown' event on a circle element in a D3 graph, with specific actions when the shift key is held down.
export const shiftCircleMouseDownAction = (graph, d3node, d) => {
    if (graph) {
        var state = graph.state;
        d3.event.stopPropagation();
        state.mouseDownNode = d;
        if (d3.event.shiftKey) {
            state.shiftNodeDrag = d3.event.shiftKey;
            // reposition dragged directed edge
            graph.dragLine
                .classed("hidden", false)
                .attr("d", "M" + d.x + "," + d.y + "L" + d.x + "," + d.y);
            return;
        }
    }
}

export const deleteNodeAction = (graph, state, node = null, edge = null, callback = functionVoid) => {
    d3.event.preventDefault();
    if (node && !graph.paneOpen) {
        graph.nodes.splice(graph.nodes.indexOf(node), 1);
        graph.spliceLinksForNode(node);
        state.selectedNode = null;
        graph.updateGraph();
    } else if (edge && !graph.paneOpen) {
        graph.edges.splice(graph.edges.indexOf(edge), 1);
        state.selectedEdge = null;
        graph.updateGraph();
    }
}

export const connectNodeAction = (graph, state, node, edge, callback = functionVoid) => {
    graph.state.connectMode = true;
    graph.state.mouseDownNode = node;
    graph.state.tempMouseDownNode = node;
    graph.state.shiftNodeDrag = true;
}

export const editNodeAction = (graph, state, node, edge, callback = functionVoid) => {
    return graph.state.SVG_REF.dispatchEvent(callback(node))
}

export const deleteNodeLinkAction = (graph, state, node, edge, callback = functionVoid) => {
    return graph.removeSelectFromEdge()
}

export const removeAllChildrenInD3 = (canvas) => {
    canvas.selectAll("*").remove();
}
export const removeElements = (el) => {
    if (el) {
        const childrens = el.children ?? []
        while (childrens.length > 0) {
            childrens[0].remove();
        }
    }

}

export const removeAttributes = (el, attributes = []) => {
    for (const attribute of attributes) {
        el.removeAttribute(attribute)
    }
}