const form = {
  formId: "asset-form",
  formField: {
    assetName: {
      name: "assetName",
      label: "Name",
      type: "text",
      placeholder: "Name",
      errorMsg: "Name is required.",
    },
    /*     category: {
      name: "category",
      label: "Category",
      type: "select",
      placeholder: "Category",
      errorMsg: "Category is required.",
    }, */
    description: {
      name: "description",
      label: "Description  ",
      type: "textarea",
      placeholder: "Description",
      errorMsg: "Description  is required.",
    },
    gas_volume: {
      name: "gas_volume",
      label: "Gas Volume",
      type: "text",
      placeholder: "Gas Volume",
      errorMsg: "Gas volume is required.",
    },
    water_volume: {
      name: "water_volume",
      label: "Water Volume",
      type: "text",
      placeholder: "Water Volume",
      errorMsg: "Water Volume is required.",
    },
    electricity_power: {
      name: "electricity_power",
      label: "Electricity Power",
      type: "text",
      placeholder: "Electricity Power",
      errorMsg: "Electricity Power is required.",
    },
    power_energy_genarated: {
      name: "power_energy_genarated",
      label: "Power Energy Genarated",
      type: "text",
      placeholder: "Power Energy Genarated",
      errorMsg: "Power Energy Genarated is required.",
    },
    waste_recycled: {
      name: "waste_recycled",
      label: "Waste Recycled",
      type: "text",
      placeholder: "Waste Recycled",
      errorMsg: "Waste Recycled is required.",
    },
    waste_to_landfill: {
      name: "waste_to_landfill",
      label: "Waste To Landfill",
      type: "text",
      placeholder: "Waste To Landfill",
      errorMsg: "Waste To Landfill is required.",
    },
    facility: {
      name: "facility",
      label: "Facility",
      type: "text",
      placeholder: "Facility",
      errorMsg: "Facility is required.",
    },
    id_facility: {
      name: "id_facility",
      label: "Facility ID",
      type: "text",
      placeholder: "Facility ID",
      errorMsg: "Facility ID is required.",
    },
    fuel_volume: {
      name: "fuel_volume",
      label: "Fuel Volume",
      type: "text",
      placeholder: "Fuel Volume",
      errorMsg: "Fuel Volume is required.",
    },
    emissionFactor: {
      name: "emissionFactor",
    },
  },
};

export default form;
