import * as Yup from "yup";
import checkout from "layouts/assets/new-asset/schemas/form";

const {
  formField: {
    assetName,
    gas_volume,
    emissionFactor,
    water_volume,
    electricity_power,
    power_energy_genarated,
    waste_recycled,
    waste_to_landfill,
    facility,
    id_facility,
    fuel_volume,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [assetName.name]: Yup.string().required(assetName.errorMsg),
    //     [gas_volume.name]:Yup.string().required(gas_volume.errorMsg),
    //     [water_volume.name]:Yup.string().required(water_volume.errorMsg),
    //     [electricity_power.name]:Yup.string().required(electricity_power.errorMsg),
    //     [power_energy_genarated.name]:Yup.string().required(power_energy_genarated.errorMsg),
    //     [waste_recycled.name]:Yup.string().required(waste_recycled.errorMsg),
    //     [waste_to_landfill.name]:Yup.string().required(waste_to_landfill.errorMsg),
    //     [facility.name]:Yup.string().required(facility.errorMsg),
    //     [fuel_volume.name]:Yup.string().required(fuel_volume.errorMsg),
  }),
  // Yup.object().shape({
  //     [emissionFactor.name]: Yup.array().of(
  //         Yup.object().shape({
  //             name: Yup.string().required("Name required"),
  //             gh_factor: Yup.string()
  //             .required("Gh factor required"),
  //             co2: Yup.string()
  //             .required("Co2 factor required")
  //         })
  //       )
  // }),
];

export default validations;
