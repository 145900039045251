const form = {
  formId: "emission-form",
  formField: {
    profileName: {
      name: "profileName",
      label: "Profile name",
      type: "text",
      placeholder: "Profile Name",
      errorMsg: "Profile name is required.",
    },
    methodOfCalculation: {
      name: "methodOfCalculation",
      label: "Data Source",
      type: "select",
      placeholder: "Data Source",
      errorMsg: "Data Source is required.",
    },
    category: {
      name: "category",
      label: "category",
      type: "select",
      placeholder: "category",
      errorMsg: "Category is required.",
    },
    subCategory: {
      name: "subCategory",
      label: "Sub category",
      type: "select",
      placeholder: "Sub category",
      errorMsg: "Sub category is required.",
    },
    material: {
      name: "material",
      label: "material",
      type: "select",
      placeholder: "Material",
      errorMsg: "Material is required.",
    },
    uom: {
      name: "uom",
      label: "Unit of Measurement",
      type: "text",
      placeholder: "Unit Of Measurement",
      errorMsg: "Unit Of Measurement is required.",
    },
    ghgfactor: {
      name: "ghgfactor",
      label: "GHG Factor",
      type: "text",
      placeholder: "GHG Factor",
      errorMsg: "GHG Factor is required.",
    },
    eeioFactor: {
      name: "eeioFactor",
      label: "EEIO Factor",
      type: "text",
      placeholder: "EEIO Factor",
      errorMsg: "EEIO Factor is required.",
    },
    units: {
      name: "units",
      label: "Units",
      type: "text",
      placeholder: "Units",
      errorMsg: "Unit is required.",
    },
    materialInput: {
      name: "material",
      label: "material",
      type: "text",
      placeholder: "material",
      errorMsg: "material is required.",
    },
    co2: {
      name: "co2",
      label: "CO2",
      type: "text",
      placeholder: "CO2",
    },
    scope1: {
      name: "scope1",
      label: "Scope 1 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Scope 1 % is required.",
    },
    scope2: {
      name: "scope2",
      label: "Scope 2 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Scope 2 % is required.",
    },
    scope3: {
      name: "scope3",
      label: "Scope 3 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Scope 3 % is required.",
    },
    stage1: {
      name: "stage1",
      label: "Stage 1 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Stage 1 % is required.",
    },
    stage2: {
      name: "stage2",
      label: "Stage 2 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Stage 2 % is required.",
    },
    stage3: {
      name: "stage3",
      label: "Stage 3 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Stage 3 % is required.",
    },
    stage4: {
      name: "stage4",
      label: "Stage 4 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Stage 4 % is required.",
    },
    stage5: {
      name: "stage5",
      label: "Stage 5 %",
      type: "text",
      placeholder: "In percentage",
      errorMsg: "Stage 5 % is required.",
    },

    allocation_factor: {
      name: "allocation_factor",
      label: "Allocation Factor",
      type: "number",
      placeholder: "Allocation Factor",
      errorMsg: "Allocation Factor is required.",
    },
    gwp_multiplier: {
      name: "gwp_multiplier",
      label: "GWP Multiplier",
      type: "number",
      placeholder: "GWP Multiplier",
      errorMsg: "GWP Multiplier is required.",
    },
    notes: {
      name: "notes",
      label: "Notes",
      type: "text",
      placeholder: "Notes",
      errorMsg: "Notes is required.",
    },
  },
};

export default form;
