import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/organization/new-organization/components/FormField";

function BasicInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { organizationName, comments } = formField;

  return (
    <SoftBox>
      <SoftTypography variant="h5">Organization Information</SoftTypography>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid item mb={3}>
              <FormField
                type={organizationName.type}
                label={organizationName.label}
                name={organizationName.name}
                placeholder={organizationName.placeholder}
                error={errors.organizationName && touched.organizationName}
                success={values.organizationName.length > 0 && !errors.organizationName}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={comments.type}
              label={comments.label}
              name={comments.name}
              placeholder={comments.placeholder}
              error={errors.comments && touched.comments}
              success={values.comments.length > 0 && !errors.comments}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
