const form = {
  formId: "emission-form",
  formField: {
    name: {
      name: "name",
      label: "name",
      type: "text",
      placeholder: "Name",
      errorMsg: "Name is required.",
    },
    material: {
      name: "material",
      label: "material",
      type: "text",
      placeholder: "Material",
      errorMsg: "Material is required.",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
      errorMsg: "Description is required.",
    },
    uom: {
      name: "uom",
      label: "Unit of Measurement",
      type: "select",
      placeholder: "Unit of Measurement",
      errorMsg: "Unit of Measurement is required.",
    },
    ghgFactor: {
      name: "ghgFactor",
      label: "GHG Factor",
      type: "text",
      placeholder: "GHG Factor",
      errorMsg: "GHG Factor is required.",
    },
    eeioFactor: {
      name: "eeioFactor",
      label: "EEIO Factor",
      type: "text",
      placeholder: "EEIO Factor",
      errorMsg: "EEIO Factor is required.",
    },
    units: {
      name: "units",
      label: "Units",
      type: "text",
      placeholder: "Units",
      errorMsg: "Unit is required.",
    },
    materialInput: {
      name: "material",
      label: "material",
      type: "text",
      placeholder: "material",
      errorMsg: "material is required.",
    },
    co2: {
      name: "co2",
      label: "CO2",
      type: "text",
      placeholder: "CO2",
    },
    scope1: {
      name: "scope1",
      label: "Scope 1 %",
      type: "text",
      placeholder: "In %",
      errorMsg: "Scope 1 % is required.",
    },
    scope2: {
      name: "scope2",
      label: "Scope 2 %",
      type: "text",
      placeholder: "In %",
      errorMsg: "Scope 2 % is required.",
    },
    scope3: {
      name: "scope3",
      label: "Scope 3 %",
      type: "text",
      placeholder: "In %",
      errorMsg: "Scope 3 % is required.",
    },
    stage1: {
      name: "stage1",
      label: "Stage 1",
      type: "text",
      placeholder: "%",
      errorMsg: "Stage 1 % is required.",
    },
    stage2: {
      name: "stage2",
      label: "Stage 2",
      type: "text",
      placeholder: "%",
      errorMsg: "Stage 2 % is required.",
    },
    stage3: {
      name: "stage3",
      label: "Stage 3",
      type: "text",
      placeholder: "%",
      errorMsg: "Stage 3 % is required.",
    },
    stage4: {
      name: "stage4",
      label: "Stage 4",
      type: "text",
      placeholder: "%",
      errorMsg: "Stage 4 % is required.",
    },
    stage5: {
      name: "stage5",
      label: "Stage 5",
      type: "text",
      placeholder: "%",
      errorMsg: "Stage 5 % is required.",
    },

    material_id: {
      name: "material_id",
      label: "material_id",
      type: "text",
    },
    notes: {
      name: "notes",
      label: "notes",
      type: "text",
    },
    accuracy: {
      name: "accuracy",
      label: "Accuracy",
      type: "number",
      placeholder: "accuracy",
    },
    geography: {
      name: "geography",
      label: "Country",
      type: "text",
      placeholder: "country",
    },
    decomposition_assumptions: {
      name: "decomposition_assumptions",
      label: "Decomposition Assumptions",
      type: "text",
      placeholder: "decomposition_assumptions",
    },
    quantification_assumptions: {
      name: "quantification_assumptions",
      label: "Quantification Assumptions",
      type: "text",
      placeholder
        : "quantification_assumptions",
    },
    verification_results: {
      name: "verification_results",
      label: "Verification Results",
      type: "text",
      placeholder: "verification_results",
    },
    activity_selection_reasoning: {
      name: "activity_selection_reasoning",
      label: "Activity Selection Reasoning",
      type: "text",
      placeholder: "activity_selection_reasoning",
    },
  },
};

export default form;
