import { Link } from "react-router-dom";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { React, useState, memo, useEffect } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ConfirmDialog from "components/ConfirmDialog";

function ActionCell(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [userId, setUserId] = useState("");

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleConfirm = () => {
    props.deleteHandler(userId);
    setShowConfirm(false);
  };

  const deleteUser = (id) => {
    setUserId(id);
    setShowConfirm(true);
  };
  return (
    <>
      {showConfirm && (
        <ConfirmDialog
          message="Are you sure you want to delete this record?"
          confirmLabel="Delete"
          cancelLabel="Cancel"
          open={showConfirm}
          onClose={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
      <SoftBox display="flex" alignItems="center">
        <Link to={`/users/edit/${props.id}`}>
          <SoftTypography
            variant="body1"
            color="secondary"
            sx={{ cursor: "pointer", lineHeight: 0 }}
          >
            <Tooltip title="Edit User" placement="top">
              <Icon>edit</Icon>
            </Tooltip>
          </SoftTypography>
        </Link>
        &nbsp;&nbsp;&nbsp;
        <SoftTypography
          variant="body1"
          color="secondary"
          onClick={() => {
            deleteUser(props.id);
          }}
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Delete user" placement="top">
            <Icon>delete</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    </>
  );
}

export default ActionCell;
