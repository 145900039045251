// react-router-dom components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import LoaderSpinner from "components/LoaderSpinner";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "layouts/emission/emission-list/components/ActionCell";

function AssetsList() {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [dataRow, setDataRow] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState("");

  const deleteHandler = (profileId) => {
    axiosFetch({
      method: "post",
      url: "emissionprofile/delete",
      requestConfig: {
        ID: profileId,
      },
    });
    setSelectedProfileId(profileId);
  };

  const handleResponse = (response) => {
    //refromatting data for  datatable column
    let trransferedData = response?.data.map((responseData) => {
      return {
        id: responseData.profileID,
        profileName: responseData.name,
        method: responseData.calculation_method,
        category: responseData.category,
        sub_category: responseData.sub_category,
        ghgfactor:
          responseData.ghgfactor != null ? responseData.ghgfactor : responseData.eeiofactor,
        uom: responseData.uom,
        co2: responseData.co2,
        action: (
          <ActionCell id={responseData.profileID} {...responseData} deleteHandler={deleteHandler} />
        ),
      };
    });
    setDataRow(trransferedData);
  };

  const fetchAsset = () => {
    axiosFetch(
      {
        method: "get",
        url: "emissionprofilelist",
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchAsset();
  }, []);

  useEffect(() => {
    if (setSelectedProfileId != null) {
      let filterdTableData = dataRow.filter((row) => row.id !== selectedProfileId);
      setDataRow(filterdTableData);
    }
  }, [selectedProfileId]);

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Normalised Emission
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/emission-profiles/new">
                <SoftButton variant="gradient" color="success" size="small">
                  + new profile
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          <DataTable
            table={{
              columns: [
                { Header: "Profile", accessor: "profileName" },
                { Header: "Data Source", accessor: "method" },
                { Header: "Category", accessor: "category" },
                //{ Header: "Sub Category", accessor: "sub_category" },
                { Header: "GHG/EEIO Factor", accessor: "ghgfactor" },
                { Header: "Unit of Measurement", accessor: "uom" },
                //{ Header: "CO2", accessor: "co2" },
                { Header: "action", accessor: "action" },
              ],
              rows: dataRow,
            }}
            entriesPerPage={{
              defaultValue: 7,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            pagination={{
              variant: "gradient",
              color: "success",
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssetsList;
