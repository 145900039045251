import checkout from "layouts/login/sign-in/schemas/form";

const {
  formField: { email, password },
} = checkout;

const initialValues = {
  [email.name]: "",
  [password.name]: "",
};

export default initialValues;
