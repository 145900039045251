import * as Yup from "yup";
import checkout from 'layouts/facility/new-facility/schemas/form';

const { formField: {
    facilityName
}, } = checkout;

const validations = [
    Yup.object().shape({
        [facilityName.name]: Yup.string().required(facilityName.errorMsg)
    }),

];

export default validations;