import { useEffect, useState, useRef } from "react";

import useAxios from "hooks/useAxios";

import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
// import FormField from 'components/FormField';
import FormField from "layouts/assets/new-asset/components/FormField";
import CustomSelect from "components/CustomSelect";

import placeholderImage from "media/placeholder-image.jpg";

function BasicInfo({ formData }) {
  const [state, setState] = useState("...");
  const [posts, error, loading, axiosFetch] = useAxios();

  const [transportModeOptions, settransportModeOptions] = useState([
    { value: "train", label: "Train" },
    { value: "ship", label: "Ship" },
    { value: "aircraft", label: "Aircraft" },
    { value: "other", label: "Other" },
  ]);

  const [supplierOptions, setsupplierOptions] = useState([
    { value: "Test supplier", label: "Test Supplier" },
    { value: "supplier", label: "Supplier 1" },
    { value: "supplier2", label: "Supplier 2" },
    { value: "supplier3", label: "Supplier 3" },
  ]);

  const [productTypeOptions, setproductTypeOptions] = useState([
    { value: "interim", label: "Interim Product" },
    { value: "finished", label: "Finished Product" },
  ]);

  const [lciaModelOptions, setLciaModelOptions] = useState([
    { value: "cutoff", label: "cutoff" },
    { value: "consequential", label: "consequential" },
    { value: "apos", label: "apos" },
  ]);
  const [lciaMethodOptions, setLciaModelOption] = useState([
    { value: "IPCC 2021", label: "IPCC 2021" },
    { value: "Ecological Footprint", label: "Ecological Footprint" },
    { value: "EF v3.0 no LT", label: "EF v3.0 no LT" },
    { value: "Ecological Scarcity 2021", label: "Ecological Scarcity 2021" },
    { value: "EPS 2020d no LT", label: "EPS 2020d no LT" },
    { value: "Ecosystem Damage Potential", label: "Ecosystem Damage Potential" },
    {
      value: "ReCiPe 2016 v1.03, midpoint (I) no LT",
      label: "ReCiPe 2016 v1.03, midpoint (I) no LT",
    },
    { value: "ReCiPe 2016 v1.03, endpoint (H)", label: "ReCiPe 2016 v1.03, endpoint (H)" },
    { value: "EF v3.1 no LT", label: "EF v3.1 no LT" },
    { value: "CML v4.8 2016 no LT", label: "CML v4.8 2016 no LT" },
    { value: "ReCiPe 2016 v1.03, midpoint (E)", label: "ReCiPe 2016 v1.03, midpoint (E)" },
    { value: "IPCC 2013", label: "IPCC 2013" },
    { value: "ReCiPe 2016 v1.03, midpoint (H)", label: "ReCiPe 2016 v1.03, midpoint (H)" },
    { value: "EPS 2020d", label: "EPS 2020d" },
    {
      value: "ReCiPe 2016 v1.03, endpoint (H) no LT",
      label: "ReCiPe 2016 v1.03, endpoint (H) no LT",
    },
    { value: "ReCiPe 2016 v1.03, endpoint (E)", label: "ReCiPe 2016 v1.03, endpoint (E)" },
    { value: "IPCC 2013 no LT", label: "IPCC 2013 no LT" },
    { value: "ReCiPe 2016 v1.03, midpoint (I)", label: "ReCiPe 2016 v1.03, midpoint (I)" },
    { value: "TRACI v2.1", label: "TRACI v2.1" },
    { value: "CML v4.8 2016", label: "CML v4.8 2016" },
    { value: "Ecological Scarcity 2021 no LT", label: "Ecological Scarcity 2021 no LT" },
    { value: "Crustal Scarcity Indicator 2020", label: "Crustal Scarcity Indicator 2020" },
    {
      value: "ReCiPe 2016 v1.03, endpoint (I) no LT",
      label: "ReCiPe 2016 v1.03, endpoint (I) no LT",
    },
    {
      value: "ReCiPe 2016 v1.03, endpoint (E) no LT",
      label: "ReCiPe 2016 v1.03, endpoint (E) no LT",
    },
    {
      value: "ReCiPe 2016 v1.03, midpoint (H) no LT",
      label: "ReCiPe 2016 v1.03, midpoint (H) no LT",
    },
    { value: "EF v3.1", label: "EF v3.1" },
    { value: "USEtox v2.13, endpoint no LT", label: "USEtox v2.13, endpoint no LT" },
    { value: "Cumulative Energy Demand (CED)", label: "Cumulative Energy Demand (CED)" },
    { value: "Inventory results and indicators", label: "Inventory results and indicators" },
    { value: "IPCC 2021 no LT", label: "IPCC 2021 no LT" },
    {
      value: "IMPACT World+ v2.0.1, footprint version",
      label: "IMPACT World+ v2.0.1, footprint version",
    },
    { value: "USEtox v2.13, endpoint", label: "USEtox v2.13, endpoint" },
    { value: "USEtox v2.13, midpoint no LT", label: "USEtox v2.13, midpoint no LT" },
    {
      value: "ReCiPe 2016 v1.03, midpoint (E) no LT",
      label: "ReCiPe 2016 v1.03, midpoint (E) no LT",
    },
    { value: "USEtox v2.13, midpoint", label: "USEtox v2.13, midpoint" },
    { value: "ReCiPe 2016 v1.03, endpoint (I)", label: "ReCiPe 2016 v1.03, endpoint (I)" },
    { value: "Cumulative Exergy Demand (CExD)", label: "Cumulative Exergy Demand (CExD)" },
    { value: "TRACI v2.1 no LT", label: "TRACI v2.1 no LT" },
    { value: "EF v3.0", label: "EF v3.0" },
  ]);
  const [selectedLciaModel, setSelectedLciaModel] = useState();
  const [selectedLciaMethod, setSelectedLciaMethod] = useState();

  const [selectedProductType, setSelectedProductType] = useState();
  if (formData.values === null) {
    formData.values = {};
  }

  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    productUID,
    productName,
    description,
    transport,
    transportMode,
    purpose,
    travelDistance,
    supplier,
    lifeTime,
    productType,

    image_url,
    customer_organisation,
    customer_contact,
    customer_email,
    reporting_period,
    revision,
    disclosing_gases,
    unit_of_analysis,
    lcia_model,
    lcia_method,
  } = formField;

  const {
    productUID: productUIDV,
    productName: productNameV,
    description: descriptionV,
    transport: transportV,
    transportMode: transportModeV,
    purpose: purposeV,
    travelDistance: travelDistanceV,
    supplier: supplierV,
    lifeTime: lifeTimeV,
    productType: productTypeV,

    image_url: image_urlV,
    customer_organisation: customer_organisationV,
    customer_contact: customer_contactV,
    customer_email: customer_emailV,
    reporting_period: reporting_periodV,
    revision: revisionV,
    disclosing_gases: disclosing_gasesV,
    unit_of_analysis: unit_of_analysisV,
    lcia_model: lcia_modelV,
    lcia_method: lcia_methodV,
  } = values;

  const [imageUrl, setImageUrl] = useState("");

  const handleProductTypeChange = (option) => {
    setSelectedProductType(option);
    setFieldValue("productType", option.value);
  };
  useEffect(() => {
    if (productTypeOptions && productTypeOptions.length > 0) {
      setSelectedProductType(productTypeOptions[0]);
    }
    if (lciaModelOptions && lciaModelOptions.length > 0) {
      setSelectedLciaModel(lciaModelOptions[0]);
    }
    if (lciaMethodOptions && lciaMethodOptions.length > 0) {
      //select the EF v3.1 lcia method
      var index = lciaMethodOptions
        .map(function (e) {
          return e.value;
        })
        .indexOf("EF v3.1");
      if (index > 0) setSelectedLciaMethod(lciaMethodOptions[index]);
    }
  }, []);
  useEffect(() => {
    if (values) {
      console.log(values);
      if (productTypeOptions && productTypeOptions.length > 0) {
        setSelectedProductType(productTypeOptions[0]);
        var index = productTypeOptions
          .map(function (e) {
            return e.value;
          })
          .indexOf(values.productType);
        if (index > 0) setSelectedProductType(productTypeOptions[index]);
      }

      if (lciaModelOptions && lciaModelOptions.length > 0) {
        setSelectedLciaModel(lciaModelOptions[0]);
        var index = lciaModelOptions
          .map(function (e) {
            return e.value;
          })
          .indexOf(values.lcia_model);
        if (index > 0) setSelectedLciaModel(lciaModelOptions[index]);
      }
      if (lciaMethodOptions && lciaMethodOptions.length > 0) {
        setSelectedLciaMethod(lciaMethodOptions[0]);
        var index = lciaMethodOptions
          .map(function (e) {
            return e.value;
          })
          .indexOf(values.lcia_method);
        if (index > 0) setSelectedLciaMethod(lciaMethodOptions[index]);
      }

      if (values.image_url) {
        setImageUrl(values.image_url);
      }
    }
  }, [values]);

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "/copilot_template.csv";
    link.setAttribute("download", "copilot_template.csv");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const csvFileInputRef = useRef(null);

  const handleCsvButtonClick = () => {
    // Clear the current file input value
    if (csvFileInputRef.current) {
      csvFileInputRef.current.value = "";
    }
    setFieldValue("csvFile", null);
    csvFileInputRef.current.click();
  };

  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue("csvFile", file);
      toast.success("CSV file uploaded successfully!");
    }
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">Product Information</SoftTypography>

      <SoftBox mt={1}>
        <Grid container spacing={2}>
          {/* Product Name and Image */}
          <Grid item xs={12} sm={6}>
            <FormField
              type={productName.type}
              label={productName.label}
              name={productName.name}
              placeholder={productName.placeholder}
              error={errors.productName && touched.productName}
              success={values.productName.length > 0 && !errors.productName}
              required
            />

            {/* Image display */}
            <img
              src={imageUrl ? `${imageUrl}?${Date.now()}` : placeholderImage}
              alt="Product"
              style={{ width: "100%", marginTop: "24px" }}
            />
          </Grid>
          {/* Description and Purpose */}
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              placeholder={description.placeholder}
              multiline
              rows={6}
            />

            <FormField
              type={purpose.type}
              label={purpose.label}
              name={purpose.name}
              placeholder={purpose.placeholder}
              multiline
              rows={6}
            />
          </Grid>
          {/* Image URL */}
          <Grid item xs={12} sm={6}>
            <FormField
              type={image_url.type}
              label={image_url.label}
              name={image_url.name}
              placeholder={image_url.placeholder}
              value={imageUrl}
              onChange={(e) => {
                const newImageUrl = e.target.value;
                setImageUrl(newImageUrl);
                setFieldValue("image_url", newImageUrl);
              }}
              error={errors.image_url && touched.image_url}
            />
          </Grid>

          {/* Lifetime */}
          <Grid item xs={12} sm={6}>
            <FormField
              type={lifeTime.type}
              label={lifeTime.label}
              name={lifeTime.name}
              placeholder={lifeTime.placeholder}
              error={errors.lifeTime && touched.lifeTime}
            />
          </Grid>

          {/* Product Type */}
          <Grid item xs={12} sm={6}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {productType.label}
            </SoftTypography>
            <SoftSelect
              name={productType.name}
              label={productType.label}
              options={productTypeOptions}
              value={selectedProductType}
              onChange={handleProductTypeChange}
            />
          </Grid>

          {/* New Fields - Add more Grid items for each new field as needed */}
          <Grid item xs={12} sm={6}>
            <FormField
              type={customer_organisation.type}
              label={customer_organisation.label}
              name={customer_organisation.name}
              placeholder={customer_organisation.placeholder}
              error={errors.customer_organisation && touched.customer_organisation}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={customer_contact.type}
              label={customer_contact.label}
              name={customer_contact.name}
              placeholder={customer_contact.placeholder}
              error={errors.customer_contact && touched.customer_contact}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={customer_email.type}
              label={customer_email.label}
              name={customer_email.name}
              placeholder={customer_email.placeholder}
              error={errors.customer_email && touched.customer_email}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={reporting_period.type}
              label={reporting_period.label}
              name={reporting_period.name}
              placeholder={reporting_period.placeholder}
              error={errors.reporting_period && touched.reporting_period}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={disclosing_gases.type}
              label={disclosing_gases.label}
              name={disclosing_gases.name}
              placeholder={disclosing_gases.placeholder}
              error={errors.disclosing_gases && touched.disclosing_gases}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={unit_of_analysis.type}
              label={unit_of_analysis.label}
              name={unit_of_analysis.name}
              placeholder={unit_of_analysis.placeholder}
              error={errors.unit_of_analysis && touched.unit_of_analysis}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={revision.type}
              label={revision.label}
              name={revision.name}
              placeholder={revision.placeholder}
              error={errors.revision && touched.revision}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {lcia_model.label}
            </SoftTypography>
            <SoftSelect
              name={lcia_model.name}
              label={lcia_model.label}
              options={lciaModelOptions}
              value={selectedLciaModel}
              onChange={(option) => {
                setSelectedLciaModel(option);
                setFieldValue("lcia_model", option.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {lcia_method.label}
            </SoftTypography>
            <SoftSelect
              name={lcia_method.name}
              label={lcia_method.label}
              options={lciaMethodOptions}
              value={selectedLciaMethod}
              onChange={(option) => {
                setSelectedLciaMethod(option);
                setFieldValue("lcia_method", option.value);
              }}
            />
          </Grid>

          {/* _id Field */}
          <Grid item xs={12} sm={6}>
            <FormField
              type={productUID.type}
              label={productUID.label}
              name={productUID.name}
              placeholder={productUID.placeholder}
              error={errors.productUID && touched.productUID}
              disabled
            />
          </Grid>
        </Grid>

        <SoftTypography mt={1} variant="h5">
          Copilot
        </SoftTypography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <SoftButton fullWidth onClick={downloadTemplate}>
                Download CSV Template
              </SoftButton>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <input
                type="file"
                accept=".csv"
                ref={csvFileInputRef}
                onChange={handleCsvFileChange}
                style={{ display: "none" }}
              />
              <SoftButton
                color="primary"
                variant="gradient"
                fullWidth
                onClick={handleCsvButtonClick}
              >
                Upload CSV
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}
// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
