import React, { useState, useRef } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import FormField from "layouts/assets/new-asset/components/FormField";
import useAxios from "hooks/useAxios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import { Formik, Form } from "formik";
import ReactJson from "react-json-view";
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";

import CopilotSearchCompose from "./components/searchAndCompose";
import CsvGenerator from "./components/csvGenerator";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

function CopilotPlayground() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CopilotSearchCompose />
      <CsvGenerator />

      <Footer />
    </DashboardLayout>
  );
}
export default CopilotPlayground;