import checkout from "layouts/product/new-product/schemas/form";

const {
  formField: {
    productUID,
    productName,
    description,
    transport,
    transportMode,
    purpose,
    travelDistance,
    supplier,
    lifeTime,

    image_url,
    customer_organisation,
    customer_contact,
    customer_email,
    reporting_period,
    revision,
    disclosing_gases,
    unit_of_analysis,
    lcia_model,
    lcia_method,
  },
} = checkout;

// Function to get the current reporting period
const getCurrentReportingPeriod = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth(); // month is zero-indexed

  if (month < 3) {
    return `Q1 ${year}`;
  } else if (month < 6) {
    return `Q2 ${year}`;
  } else if (month < 9) {
    return `Q3 ${year}`;
  } else {
    return `Q4 ${year}`;
  }
};

const initialValues = {
  [productUID.name]: "",
  [productName.name]: "",
  [description.name]: "",
  [transport.name]: "",
  [transportMode.name]: "",
  [purpose.name]: "",
  [travelDistance.name]: "",
  [supplier.name]: "",
  [lifeTime.name]: "",

  [image_url.name]: "",
  [customer_organisation.name]: localStorage.getItem("userCompany"),
  [customer_contact.name]: localStorage.getItem("userName"),
  [customer_email.name]: localStorage.getItem("userEmail"),
  [reporting_period.name]: getCurrentReportingPeriod(),
  [revision.name]: 1,
  [disclosing_gases.name]: "CO2",
  [unit_of_analysis.name]: "kgCO2e",
  [lcia_model.name]: "cutoff",
  [lcia_method.name]: "EF v3.1",
};

export default initialValues;
