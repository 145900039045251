import { Navigate } from "react-router-dom";
import JWT from "expo-jwt";

//const key = process.env.REACT_APP_JWT_SECRET;
const key = "kCHwJ5aM9NWBVqXfae2Aqywx";
const AuthRoute = ({ children }) => {
  const queryParams = new URLSearchParams(location.search);
  var token = queryParams.get("token");
  if (token == null) {
    token = localStorage.getItem("token");
  }
  if (token) {
    try {
      const decodedToken = JWT.decode(token, key);
      if (decodedToken) {
        if (decodedToken.exp > Math.floor(Date.now() / 1000)) {
          localStorage.setItem("token", token);
        } else {
          localStorage.removeItem("token");
        }
      }
    } catch (error) {
      console.log(error);
    }

    /*
    const { decodedToken, isExpired } = useJwt(token);
    if (decodedToken && !isExpired) {
      localStorage.setItem("token", token);
    }
    //else {
    //  localStorage.removeItem("token");
    //}
    */
  }

  const userSignedIn = localStorage.getItem("token") ? true : false;

  if (!userSignedIn) {
    return <Navigate replace to="sign-in" />;
  }
  return children;
};

export default AuthRoute;
