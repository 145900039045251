import * as Yup from "yup";
import checkout from 'layouts/pages/product-entity/schemas/form';

const { formField: {
    processName,
}, } = checkout;


const validations = [
    Yup.object().shape({
        [processName.name]: Yup.string().required(processName.errorMsg),
    }),

];

export default validations;