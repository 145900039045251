import checkout from "layouts/organization/new-organization/schemas/form";

const {
  formField: { organizationName, comments },
} = checkout;

const initialValues = {
  [organizationName.name]: "",
  [comments.name]: "",
};

export default initialValues;
