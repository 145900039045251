// HelpFloatingButton.js
import React, { useState, useEffect, useRef } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import helpContent from "./helpContent"; // Import the content object

const HelpFloatingButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const buttonRef = useRef(null); // Add a reference to the button
  const [content, setContent] = useState("");

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      setAnchorEl(null);
      setIsHovered(false); // Reset hover state on close
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "help-popover" : undefined;

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/").filter(Boolean); // Splits the path and removes any empty strings
    const basePath =
      pathSegments.length > 1 ? `/${pathSegments[0]}/${pathSegments[1]}` : `/${pathSegments[0]}`;

    const currentContent = helpContent[basePath] || helpContent["/default"];

    setContent(currentContent);
  }, []); // The empty dependency array ensures this runs once on mount

  return (
    <div
      id="floating_help_button"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "50%",
        right: 0, // Sticks to the outmost right
        transform: "translateY(-50%) rotate(-90deg)",
        transformOrigin: "bottom right",
        zIndex: 1500,
        opacity: isHovered || open ? 1 : 0.5, // Control opacity based on hover or open state
        transition: "opacity 0.5s, background-color 0.6s",
        borderRadius: 5,
        padding: "0px 24px",
        backgroundColor: "white", // White fill
        color: "#4caf50", // Text and icon in green
        cursor: "pointer", // Change cursor to pointer
        fontSize: "1rem",
        fontWeight: "bold",
        fontFamily: "Arial, sans-serif",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        border: "1px solid #4caf50", // Green border
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <HelpOutlineIcon style={{ marginRight: 1, fontSize: "1.0rem" }} />
      Help
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        disableRestoreFocus
        style={{
          borderRadius: "10px", // Rounded corners for the popover
          marginTop: "10px", // Space between the button and popover
        }}
      >
        <Typography
          style={{
            padding: "20px",
            minWidth: "300px",
            maxWidth: "500px",
            backgroundColor: "white",
          }}
        >
          <CloseIcon
            style={{ cursor: "pointer", float: "right" }} // Make the icon clickable and float to the right
            onClick={handleClose}
          />
          <Typography
            style={{ padding: "20px", minWidth: "300px", maxWidth: "500px" }}
            dangerouslySetInnerHTML={{ __html: content }} // Set HTML content
          >
            {/* Content is rendered via dangerouslySetInnerHTML */}
          </Typography>
        </Typography>
      </Popover>
    </div>
  );
};

export default HelpFloatingButton;
