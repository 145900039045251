const form = {
  formId: "organization-form",
  formField: {
    organizationName: {
      name: "organizationName",
      label: "Name",
      type: "text",
      placeholder: "Name",
      errorMsg: "Name is required.",
    },
    comments: {
      name: "comments",
      label: "Description",
      type: "textarea",
      placeholder: "Comments",
      errorMsg: "Comments is required.",
    },
  },
};

export default form;
