import React from 'react';
import ReactDOM from 'react-dom';
import './Loader.css';

const Loader = () => {
    return (
        <div className="backdrop">
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        </div>
    );
};

const LoaderSpinner = () => {
    return (
        <>
        { ReactDOM.createPortal(<Loader />, document.getElementById('loader-spinner-root'))}
        </>
    );

}
export default LoaderSpinner;
