import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//let BASE_URL = process.env.REACT_APP_BASE_URL;
const currentDomain = window.location.hostname;
const currentProtocol = window.location.protocol;

let BASE_URL = currentProtocol + "//" + currentDomain + ":8080/api";
if (currentDomain === "localhost") {
  BASE_URL = "http://localhost:8080/api";
} else {
  BASE_URL = currentProtocol + "//" + currentDomain + "/api";
}

// create a new axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
  },
});

// add the JWT token to the headers of every request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// handle response errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ERR_CANCELED") {
      // do nothing and return
      return Promise.reject(error);
    }
    if (error.response && (error.response.status === 401 || error.response.status === 401)) {
      // clear user token and redirect to login
      localStorage.removeItem("token");
      window.location = "/sign-in"; // adjust this as per your login route
    }
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    } else if (error.response && error.response.data && error.response.data.data) {
      toast.error(error.response.data.data);
    } else if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.message);
    }
    // check if it's a 401 (Unauthorized) or 403 (Forbidden)

    return Promise.reject(error);
  }
);

export default axiosInstance;
