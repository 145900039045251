import checkout from "layouts/emission/new-emission/schemas/form";

const {
  formField: {
    profileName,
    methodOfCalculation,
    category,
    subCategory,
    material,
    materialInput,
    ghgfactor,
    eeioFactor,
    uom,
    units,
    co2,
    scope1,
    scope2,
    scope3,
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    allocation_factor,
    gwp_multiplier,
    notes,
  },
} = checkout;

const initialValues = {
  [profileName.name]: "",
  [units.name]: "",
  [ghgfactor.name]: "",
  [uom.name]: "",
  [co2.name]: "",
  [scope1.name]: "",
  [scope2.name]: "",
  [scope3.name]: "",
  [stage1.name]: "",
  [stage2.name]: "",
  [stage3.name]: "",
  [stage4.name]: "",
  [stage5.name]: "",
  [subCategory.name]: "",
  [category.name]: "",
  [material.name]: "",
  [materialInput.name]: "",
  [eeioFactor.name]: "",
  [methodOfCalculation.name]: "",
  [allocation_factor.name]: 1,
  [gwp_multiplier.name]: 1,
  [notes.name]: "",
};

export default initialValues;
