import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

import { useSelector, useDispatch } from "react-redux";

import { FieldArray } from "formik";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import FormField from "layouts/assets/new-asset/components/FormField";

import { getEmissionProfileList } from "reducers/assets-reducer";

import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

function Emission({ formData, onChildData, newProfile }) {
  const dispatch = useDispatch();
  const { emissionProfileList } = useSelector((state) => state.assetsReducer);
  const [state, setState] = useState("...");
  const [posts, error, loading, axiosFetch] = useAxios();
  const { formField, values, errors, touched, setFieldValue } = formData;

  const [profileOptions, setProfileOptions] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const handleClick = () => {
    onChildData(true);
  };

  const onChangeProfiles = (selectedOption) => {
    setSelectedProfiles(selectedOption);

    //make emissionfactor into empty
    values.emissionFactor = "";
    //it's a multi select, selectedOption provides all the selected option
    selectedOption.forEach((item) => {
      const newObject = {
        profile: item.value,
        calculation_method: item.method,
        ghg_factor: item.ghgfactor !== "" ? item.ghgfactor : item.eeioFactor,
        uom: item.uom,
      };
      values.emissionFactor = [...values.emissionFactor, newObject];
    });
  };

  useEffect(() => {
    dispatch(getEmissionProfileList());
  }, []);

  useEffect(() => {
    if (emissionProfileList?.status) {
      const emissionData = emissionProfileList?.data?.map((emission) => ({
        value: emission.name,
        label: emission.name,
        method: emission.calculation_method,
        ghgfactor: emission.ghgfactor,
        uom: emission.uom,
      }));
      setProfileOptions(emissionData);
    }
  }, [emissionProfileList]);


  useEffect(() => {
    if (values.emissionFactor && profileOptions.length > 0) {
      const selectedProfileValues = values.emissionFactor?.map((emission) => emission.profile);
      const selectedValues = profileOptions.filter((option) =>
        selectedProfileValues?.includes(option.value)
      );
      setSelectedProfiles(selectedValues);
    }
  }, [profileOptions, values.emissionFactor]);

  useEffect(() => {
    if (Object.keys(newProfile).length > 0) {
      const newOption = {
        value: newProfile.profileName,
        label: newProfile.profileName,
        method: newProfile.methodOfCalculation,
        ghgfactor: newProfile.ghgfactor !== "" ? newProfile.ghgFactor : newProfile.eeioFactor,
        material: newProfile.category,
        profile_id: newProfile.profileID,
      };

      values.emissionFactor = [
        ...values.emissionFactor,
        {
          profile: newProfile.profileName,
          calculation_method: newProfile.methodOfCalculation,
          ghg_factor: newProfile.ghgfactor !== "" ? newProfile.ghgFactor : newProfile.eeioFactor,
        },
      ];
      setProfileOptions((prevOptions) => [...prevOptions, newOption]);
    }
  }, [newProfile]);

  return (
    <SoftBox>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={8}>
          <SoftTypography variant="h5">Emission Profile Information</SoftTypography>
        </Grid>
        <Grid item xs={12} sm={4} container justifyContent="flex-end" alignItems="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px", // This will provide the spacing between buttons
            }}
          >
            <SoftButton
              variant="outlined"
              color="secondary"
              size="medium"
              style={{ width: "200px" }}
              onClick={() => dispatch(getEmissionProfileList())}
            >
              Refresh
            </SoftButton>
            <Link to="/emission-profiles/new" target="_blank">
              <SoftButton variant="outlined" color="info" size="medium" style={{ width: "200px" }}>
                new profile 🡥
              </SoftButton>
            </Link>
          </div>
        </Grid>
      </Grid>
      <SoftBox mt={1.625}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={8}>
            <SoftBox mb={1.5}>
              <SoftSelect
                name={`emissionFactor`}
                options={profileOptions}
                onChange={onChangeProfiles}
                value={selectedProfiles}
                isSearchable
                isMulti
                size="large"
              />
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item xs={12} sm={12}>
            {selectedProfiles.length > 0 && values.emissionFactor && (
              <TableContainer>
                <MuiTable>
                  <SoftBox component="thead">
                    <TableRow>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Profile
                      </SoftBox>
                      {/*                       <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Material
                      </SoftBox> */}
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Data Source
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        GHG/EEIO Factor
                      </SoftBox>
                    </TableRow>
                  </SoftBox>
                  <TableBody>
                    {selectedProfiles.length > 0 &&
                      selectedProfiles.map((item, key) => (
                        <TableRow key={key}>
                          <SoftBox
                            component="th"
                            width="auto"
                            pt={1.5}
                            pb={1.25}
                            textAlign="center"
                            color="secondary"
                            opacity={0.7}
                            fontWeight="regular"
                            fontSize="default"
                            style={{
                              maxWidth: "100px",
                              wordWrap: "break-word",
                              border: "1px solid moccasin",
                            }}
                          >
                            {item.value}
                          </SoftBox>
                          {/*                           <SoftBox
                            component="td"
                            width="auto"
                            pt={1.5}
                            pb={1.25}
                            textAlign="center"
                            color="secondary"
                            opacity={0.7}
                            fontWeight="regular"
                            fontSize="default"
                            style={{
                              maxWidth: "100px",
                              wordWrap: "break-word",
                              border: "1px solid moccasin",
                            }}
                          >
                            {item.material}
                          </SoftBox> */}
                          <SoftBox
                            component="td"
                            width="auto"
                            pt={1.5}
                            pb={1.25}
                            textAlign="center"
                            color="secondary"
                            opacity={0.7}
                            fontWeight="regular"
                            fontSize="default"
                            style={{
                              maxWidth: "100px",
                              wordWrap: "break-word",
                              border: "1px solid moccasin",
                            }}
                          >
                            {item.method &&
                              item.method.trim() !== "" &&
                              item.method.charAt(0).toUpperCase() + item.method.slice(1)}
                          </SoftBox>
                          <SoftBox
                            component="td"
                            width="auto"
                            pt={1.5}
                            pb={1.25}
                            textAlign="center"
                            color="secondary"
                            opacity={0.7}
                            fontWeight="regular"
                            fontSize="default"
                            style={{
                              maxWidth: "100px",
                              wordWrap: "break-word",
                              border: "1px solid moccasin",
                            }}
                          >
                            {item.ghgfactor}
                          </SoftBox>
                        </TableRow>
                      ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <Grid container mt={5}></Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Emission
Emission.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Emission;
