const form = {
    formId: 'facility-form',
    formField: {
        facilityName: {
            name: "facilityName",
            label: "Name",
            type: "text",
            placeholder: "Name",
            errorMsg: "Name is required.",
        },
        category: {
            name: "category",
            label: "Category",
            type: "select",
            placeholder: "Category",
            errorMsg: "Category is required.",
        },
        description: {
            name: "description",
            label: "Description  ",  
            type: "textarea",
            placeholder: "Description",
            errorMsg: "Description  is required.",
        },        
        emissionFactor
            : {
            name: "emissionFactor",                        
        },
        profiles:{
            name: "profiles",          
        }
    }
}

export default form;