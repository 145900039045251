import React, { useState } from "react";
import Chart from "react-apexcharts";

const RESOURCES_PER_PAGE = 4; // Display 4 resources per page

const ResourcesGraph = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const processData = data.flatMap((item) => {
    if (item.canvas_nodes && Array.isArray(item.canvas_nodes)) {
      const resourceNodes = item.canvas_nodes.filter((node) => node.type !== "react");
      return resourceNodes.map((node) => {
        const resourceName = node.title;
        const co2 = node.co2 || 0;
        const coproductCo2 = node.coproduct_co2 || 0;
        const wasteCo2 = node.waste_co2 || 0;

        return {
          resourceName,
          co2,
          coproductCo2,
          wasteCo2,
        };
      });
    }
    return [];
  });

  const filteredData = processData.filter((item) => item !== undefined && item !== null);
  const sortedData = filteredData.sort((a, b) => b.co2 - a.co2); // Sort by CO2 in descending order

  const resourceNames = sortedData.map((item) => item.resourceName);
  const co2Data = sortedData.map((item) => item.co2);
  const coproductCo2Data = sortedData.map((item) => item.coproductCo2);
  const wasteCo2Data = sortedData.map((item) => item.wasteCo2);

  const currentResourceNames = resourceNames.slice(
    currentPage * RESOURCES_PER_PAGE,
    (currentPage + 1) * RESOURCES_PER_PAGE
  );
  const currentCo2Data = co2Data.slice(
    currentPage * RESOURCES_PER_PAGE,
    (currentPage + 1) * RESOURCES_PER_PAGE
  );

  const chartOptions = {
    noData: {
      text: "NO DATA",
      align: "center",
      verticalAlign: "middle",
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: currentResourceNames,
    },
    yaxis: {
      title: {
        text: "CO2 (kgCo2e)",
        offsetX: -65,
      },
      labels: {
        formatter: (value) => value.toFixed(0),
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toFixed(3),
      },
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    series: [
      {
        name: "CO2",
        data: currentCo2Data,
      },
    ],
  };

  const totalPages = Math.ceil(resourceNames.length / RESOURCES_PER_PAGE);

  return (
    <div>
      <Chart options={chartOptions} series={chartOptions.series} type="bar" height={350} />

      <div
        className="graph-nav-button"
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {currentPage > 0 && (
          <button
            style={{
              margin: "0 10px",
              padding: "5px 15px",
              cursor: currentPage === 0 ? "not-allowed" : "pointer",
              backgroundColor: currentPage === 0 ? "#ccc" : "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            {"<<"}
          </button>
        )}
        <span style={{ margin: "0 10px", alignItems: "center", display: "flex" }}>
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          style={{
            margin: "0 10px",
            padding: "5px 15px",
            cursor:
              (currentPage + 1) * RESOURCES_PER_PAGE >= resourceNames.length
                ? "not-allowed"
                : "pointer",
            backgroundColor:
              (currentPage + 1) * RESOURCES_PER_PAGE >= resourceNames.length ? "#ccc" : "#007BFF",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
          disabled={(currentPage + 1) * RESOURCES_PER_PAGE >= resourceNames.length}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          {">>"}
        </button>
      </div>
    </div>
  );
};

export default ResourcesGraph;
