import React from "react";
import Chart from "react-apexcharts";
import Card from "@mui/material/Card"; // Import MUI Card components
import CardContent from "@mui/material/CardContent"; // Import MUI CardContent components
import Typography from "@mui/material/Typography"; // Import MUI Typography components
import SoftBox from "components/SoftBox"; // Import SoftBox component


const ProductBubbleChart = ({ analyticsData }) => {
    // Define a default structure for analyticsData in case it's not provided or incomplete
    const safeAnalyticsData = analyticsData && analyticsData.bubbleChartData ? analyticsData : {
        bubbleChartData: {
            datasets: [{ data: [] }] // Ensures datasets are set to trigger noData config
        }
    };

    // Extract data from the safeAnalyticsData
    const { bubbleChartData } = safeAnalyticsData;


    let minX = Infinity, maxX = 0, minY = Infinity, maxY = 0;
    bubbleChartData.datasets[0].data.forEach((item) => {
        if (item.x < minX) minX = item.x;
        if (item.x > maxX) maxX = item.x;
        if (item.y < minY) minY = item.y;
        if (item.y > maxY) maxY = item.y;
    });

    // Apply padding but ensure no negative values
    const padding = 10;
    minX = Math.max(0, minX - padding);
    minY = Math.max(0, minY - padding);

    const series = [{
        name: 'Products',
        data: bubbleChartData.datasets[0].data.map(item => ({
            x: item.x,
            y: item.y,
            z: Math.max(Math.sqrt(item.r) * 10, 10), // Ensure minimum bubble size
            name: item.name, // Product name for the tooltip
            totalCo2: item.r // Total CO2 for the tooltip
        }))
    }];

    const options = {
        noData: {
            text: "NO DATA",
            align: "center",
            verticalAlign: "middle",
        },
        chart: {
            type: 'bubble',
            height: 350,
            toolbar: { show: true },
            animations: {
                enabled: true,
            },
        },
        dataLabels: { enabled: false },
        fill: { opacity: 0.8 },
        xaxis: {
            // Reduced tick amount for simplicity
            tickAmount: 4,
            type: 'numeric',
            title: { text: 'Resources kgCO2e' },
            min: minX,
            max: Math.ceil(maxX / 100) * 100, // Round maxX to nearest 100 for simplicity
            labels: {
                formatter: function (val) {
                    return `${val.toFixed(0)}`; // Remove decimals
                }
            },
        },
        yaxis: {
            // Reduced tick amount for simplicity
            tickAmount: 4,
            type: 'numeric',
            title: { text: 'Processes kgCO2e', offsetX: -65 },
            min: minY,
            max: Math.ceil(maxY / 100) * 100, // Round maxY to nearest 100 for simplicity
            labels: {
                formatter: function (val) {
                    return `${val.toFixed(0)}`; // Remove decimals
                }
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
        },
        tooltip: {
            followCursor: true,
            shared: false,
            intersect: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // Custom tooltip content
                const data = w.config.series[seriesIndex].data[dataPointIndex];
                const tooltipContent = `
        <div style="padding: 5px;">
          <strong>Product:</strong> ${data.name}<br>
          <strong>Total CO2:</strong> ${Math.round(data.x + data.y)}<br>
          <strong>Resources CO2:</strong> ${Math.round(data.x)}<br>
          <strong>Processes CO2:</strong> ${Math.round(data.y)}
        </div>
      `;
                return tooltipContent;
            },
        },
        grid: {
            show: false,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            row: {
                colors: '#90A4AE',
                opacity: 0.2
            },
            padding: {
                top: 20, // Increase top padding to give more space for tooltips
            },
        },
    };


    return (

        <SoftBox >
            <Card>
                <CardContent style={{ pageBreakInside: "avoid" }}>
                    <Typography variant="h6">Product Emissions</Typography>
                    <div style={{ overflowX: "auto", overflowY: "hidden", width: "100%" }}>
                        <div style={{ width: "100%" }}>
                            <Chart options={options} series={series} type="bubble" height={400} />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </SoftBox>


    );
};

export default ProductBubbleChart;
