import * as Yup from "yup";
import checkout from "layouts/login/sign-in/schemas/form";

const {
  formField: { email, password },
} = checkout;

const validations = Yup.object().shape({
  [email.name]: Yup.string()
    .required(email.errorMsg)
    .matches(/^[\w.-]+@[\w.-]+\.\w+$/, "Invalid Email Address"),
  [password.name]: Yup.string()
    .required(password.errorMsg)
    .min(8, "Password is too short - should be 8 chars minimum"),
});

export default validations;
