import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "hooks/useAxios";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// import Table from "examples/Tables/Table";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftTypography from "components/SoftTypography";
import FormField from "components/FormField";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
// import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

function Emission({ formData, onChildData, newProfile }) {
  const [state, setState] = useState("...");
  const [posts, error, loading, axiosFetch] = useAxios();
  const { formField, values, errors, touched, setFieldValue } = formData;

  const [profileOptions, setProfileOptions] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const handleClick = () => {
    onChildData(true);
  };

  const onChangeProfiles = (selectedOption) => {
    setSelectedProfiles(selectedOption);
    setFieldValue("profiles", selectedOption);
  };

  const handleResponse = (response) => {
    let profiles = response?.data.map((responseData) => ({
      value: responseData.name,
      label: responseData.name,
      method: responseData.calculation_method,
      ghgfactor: responseData.ghgfactor,
      material: responseData.category,
      uom: responseData.uom,
      units: 0,
      co2: 0,
      profile_id: responseData.profileID,
    }));
    //setProfileOptions(profiles);
    setProfileOptions((profileOptions) => [...profileOptions, ...profiles]);

  };

  const fetchProfiles = () => {
    axiosFetch(
      {
        method: "get",
        url: "emissionprofilelist",
      },
      handleResponse
    );
  };
  useEffect(() => {
    if (values.profiles.length > 0 && profileOptions.length > 0) {
      const selectedProfileValues = values.profiles.map((profile) => profile.value);
      const selectedValues = profileOptions.filter((option) =>
        selectedProfileValues.includes(option.value)
      );
      setSelectedProfiles(selectedValues);
    }
  }, [profileOptions]);

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (Object.keys(newProfile).length > 0) {
      const newOption = {
        value: newProfile.profileName,
        label: newProfile.profileName,
        method: newProfile.methodOfCalculation,
        ghgfactor: newProfile.ghgfactor !== "" ? newProfile.ghgfactor : newProfile.eeioFactor,
        material: newProfile.category,
        uom: newProfile.uom,
        units: 0,
        co2: 0,
        profile_id: newProfile.profileID,
      };
      values.profiles = [...values.profiles, newOption];
      setProfileOptions((prevOptions) => [...prevOptions, newOption]);
    }
  }, [newProfile]);


  return (
    <SoftBox>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} sm={8}>
          <SoftTypography variant="h5">Emission Profile information</SoftTypography>
        </Grid>

        <Grid item xs={12} sm={4} container justifyContent="flex-end" alignItems="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px", // This will provide the spacing between buttons
            }}
          >
            <SoftButton
              variant="outlined"
              color="secondary"
              size="medium"
              style={{ width: "150px" }}
              onClick={fetchProfiles}
            >
              Refresh
            </SoftButton>
            <Link to="/emission-profiles/new" target="_blank">
              <SoftButton variant="outlined" color="info" size="medium" style={{ width: "150px" }}>
                + new profile 🡥
              </SoftButton>
            </Link>
          </div>
        </Grid>
      </Grid>
      <SoftBox mt={1.625}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={8}>
            <SoftBox mb={1.5}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Emission Profiles
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                name={`profiles`}
                options={profileOptions}
                onChange={onChangeProfiles}
                value={selectedProfiles}
                isSearchable
                isMulti
                size="large"
              />
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container mt={2} mb={28}>
          <Grid item xs={12} sm={12}>
            {values.profiles.length > 0 && (
              <TableContainer>
                <MuiTable>
                  <SoftBox component="thead">
                    <TableRow>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Profile
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Material
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Data Source
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        GHG/EEIO Factor
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Unit of Measurement
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        Units
                      </SoftBox>
                      <SoftBox
                        component="th"
                        width="auto"
                        pt={1.5}
                        pb={1.25}
                        textAlign="center"
                        color="secondary"
                        opacity={0.7}
                        fontWeight="bold"
                        fontSize="default"
                        bgColor="moccasin"
                        style={{
                          maxWidth: "100px",
                          wordWrap: "break-word",
                          border: "1px solid moccasin",
                        }}
                      >
                        CO2
                      </SoftBox>
                    </TableRow>
                  </SoftBox>
                  <TableBody>
                    {values.profiles.map((item, key) => (
                      <TableRow key={key}>
                        <SoftBox
                          component="th"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          {item.value}
                        </SoftBox>
                        <SoftBox
                          component="td"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          {item.material}
                        </SoftBox>
                        <SoftBox
                          component="td"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          {item.method &&
                            item.method.trim() !== "" &&
                            item.method.charAt(0).toUpperCase() + item.method.slice(1)}
                        </SoftBox>
                        <SoftBox
                          component="td"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          {item.ghgfactor}
                        </SoftBox>
                        <SoftBox
                          component="td"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          {item.uom}
                        </SoftBox>
                        <SoftBox
                          component="td"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          <Grid container style={{ paddingLeft: "20%" }}>
                            <Grid item xs={12} sm={8}>
                              <FormField
                                type="text"
                                placeholder="Units"
                                label=""
                                name={`profiles[${key}].units`}
                                value={values.profiles[key].units}
                                onChange={(e) => {
                                  const newUnit = e.target.value;
                                  const ghg_eeio_factor =
                                    item.ghgfactor != "" ? item.ghgfactor : item.eeioFactor;
                                  setFieldValue(`profiles[${key}].co2`, newUnit * ghg_eeio_factor);
                                  setFieldValue(`profiles[${key}].units`, newUnit);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          width="auto"
                          pt={1.5}
                          pb={1.25}
                          textAlign="center"
                          color="secondary"
                          opacity={0.7}
                          fontWeight="regular"
                          fontSize="default"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-word",
                            border: "1px solid moccasin",
                          }}
                        >
                          <Grid container style={{ paddingLeft: "20%" }}>
                            <Grid item xs={12} sm={8}>
                              <FormField
                                type="text"
                                placeholder="CO2"
                                readOnly
                                label=""
                                value={values.profiles[key].co2}
                                name={`profilesCO2[${key}].co2`}
                              />
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <Grid container mt={5}></Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Emission
Emission.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Emission;
