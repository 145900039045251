import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import helpContent from "layouts/common/helpContent"; // Import the help content
import { makeStyles } from "@mui/styles"; // Import makeStyles

const useStyles = makeStyles((theme) => ({
  helpContent: {
    "& ul": {
      paddingLeft: theme.spacing(3),
    },
    "& p, & ul, & li": {
      fontSize: "1rem",
    },
    "& strong": {
      fontWeight: "bold",
    },
    "& li": {
      marginBottom: theme.spacing(1),
    },
  },
}));

function HelpPage() {
  const [activeAccordion, setActiveAccordion] = useState(false);
  const classes = useStyles(); // Use the styles
  const filteredHelpContent = Object.entries(helpContent).filter(([key]) => key !== "/default");
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Help Center
              </SoftTypography>
              <SoftTypography component="p" variant="button" color="text">
                Platform Page Guide
              </SoftTypography>
            </SoftBox>
            <Link to="/">
              <SoftButton variant="gradient" color="info" size="small">
                Go back to dashboard
              </SoftButton>
            </Link>
          </SoftBox>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {filteredHelpContent.map(([path, content], index) => (
                  <MuiAccordion
                    key={path}
                    expanded={activeAccordion === index}
                    onChange={() => setActiveAccordion(activeAccordion === index ? false : index)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <SoftTypography>{path}</SoftTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className={classes.helpContent}
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </AccordionDetails>
                  </MuiAccordion>
                ))}
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default HelpPage;
