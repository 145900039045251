import { React, useState, memo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Formik, Form, ErrorMessage } from "formik";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import LoaderSpinner from "components/LoaderSpinner";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Basic from "layouts/organization/new-organization/components/Basic";
import form from "layouts/organization/new-organization/schemas/form";
import initialValues from "layouts/organization/new-organization/schemas/initialValues";

const EditOrganization = () => {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [showConfirm, setShowConfirm] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleResponse = (response) => {
    let formattedResponseData = response?.data
      ? {
          organizationName: response.data.name,
          comments: response.data.description,
        }
      : {};
    const responseObject = Object.keys(formattedResponseData).reduce((obj, key) => {
      return {
        ...obj,
        [key]: formattedResponseData[key],
      };
    }, {});

    setFormValue(formattedResponseData);
  };
  const fetchAsset = () => {
    axiosFetch(
      {
        method: "get",
        url: "organization/" + id,
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchAsset();
  }, []);

  const { formId, formField } = form;

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        actions.setSubmitting(false);
        actions.resetForm();
        navigate("/organisations");
      }
    };

    axiosFetch(
      {
        method: "put",
        url: "organization/update/" + id,
        requestConfig: {
          name: values.organizationName,
          description: values.comments,
        },
      },
      handleResponseCallback
    );
  };

  return (
    <>
      {loading && <LoaderSpinner />}

      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={3}>
          <Formik
            initialValues={formValue || initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox mb={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <SoftTypography variant="h4" fontWeight="medium">
                        Edit Organization
                      </SoftTypography>
                      <SoftBox mt={1} mb={2}></SoftBox>
                    </Grid>
                    <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end">
                      <SoftBox>
                        <SoftButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="success"
                        >
                          Update
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={2}></Grid>
                  <Grid item xs={12} lg={8}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <Basic
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default EditOrganization;
