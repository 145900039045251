import { useState, useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//toaster
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

// New asset page components
import Basic from "layouts/assets/new-asset/components/Basic";
import Emission from "layouts/assets/new-asset/components/Emission";

import EmissionComponent from "layouts/emission/new-emission";
//"layouts/pages/product-emission/";

import validations from "layouts/assets/new-asset/schemas/validations";
import form from "layouts/assets/new-asset/schemas/form";
import initialValues from "layouts/assets/new-asset/schemas/initialValues";

import { handleNewAssetInformation, resetNewAssetInformation } from "reducers/assets-reducer";
import VisibilityControl from "layouts/common/visibilityControl";
function getSteps() {
  return ["1. Basic", "2. Emission Factor"];
}

function getStepContent(stepIndex, formData, handleChildData, newProfile) {
  switch (stepIndex) {
    case 0:
      return <Basic formData={formData} />;
    case 1:
      return <Emission formData={formData} onChildData={handleChildData} newProfile={newProfile} />;
    default:
      return null;
  }
}

function AssetNew() {
  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newAssetInformation } = useSelector((state) => state.assetsReducer);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const [showEmissionProfile, setShowEmissionProfile] = useState(false);
  const [showProcess, setShowProcess] = useState(true);
  const [newProfile, setNewProfile] = useState([]);
  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    if (newAssetInformation.status) {
      navigate("/assets");
    }

    return () => {
      dispatch(resetNewAssetInformation());
    };
  }, [newAssetInformation]);

  const submitForm = (values) => {
    const payload = {
      name: values.assetName,
      describtion: values.description,
      //category: values.category.value,
      //type: values.category.value,
      electricity_power: values.electricity_power,
      water_volume: values.water_volume,
      gas_volume: values.gas_volume,
      renewal_energy_generated: values.power_energy_genarated,
      waste_recycled: values.waste_recycled,
      waste_to_landfill: values.waste_to_landfill,
      emissionFactor: values.emissionFactor,
      fuel_volume: values.fuel_volume,
      id_facility: values.id_facility,

      security_isPublic: visibilityOptions.security_isPublic,
      security_organisation_visibility: visibilityOptions.security_organisation_visibility,
    };

    dispatch(handleNewAssetInformation(payload));
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleChildData = (data) => {
    // here data is a boolea value so uisng !data means opposite to data
    setShowProcess(!data);
    setShowEmissionProfile(data);
  };

  const hanldeFormData = (data) => {
    setNewProfile(data);
  };
  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid mb={1} container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={3}>
                <VisibilityControl
                  onVisibilityChange={handleVisibilityChange}
                  initialVisibility={{
                    security_isPublic: visibilityOptions.security_isPublic,
                    security_organisation_visibility:
                      visibilityOptions.security_organisation_visibility,
                  }}
                  disabled={false}
                />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ display: !showEmissionProfile ? "" : "none" }}
          sx={{ height: "100%" }}
        >
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          handleChildData,
                          newProfile
                        )}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </SoftButton>
                          )}
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color={isLastStep ? "success" : "dark"}
                          >
                            {isLastStep ? "Save" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Grid style={{ display: showEmissionProfile ? "block" : "none" }}>
              <EmissionComponent
                isCalled="true"
                onChildData={handleChildData}
                onChildFormData={hanldeFormData}
              />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssetNew;
