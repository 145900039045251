import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "apis/apiV1";

const initialState = {
  isLoading: false,
  newAssetInformation: {},
  loginValues: {},
  assetsList: {},
  assetsListWithId: {},
  emissionProfileList: {},
  removedAssetStatus: {},
};

export const handleNewAssetInformation = createAsyncThunk("post/assets/add", async (payload) => {
  const res = await axiosInstance.post("assets/add", payload).then((response) => response.data);

  return res;
});

export const getAssetsList = createAsyncThunk("get/assets/list", async () => {
  const res = await axiosInstance.get("assets/list").then((response) => response.data);

  return res;
});

export const getEmissionProfileList = createAsyncThunk("get/emissionprofilelist", async () => {
  const res = await axiosInstance.get("emissionprofilelist").then((response) => response.data);

  return res;
});

export const updateAssetInformation = createAsyncThunk("post/assets/update", async (payload) => {
  const res = await axiosInstance.post("assets/update", payload).then((response) => response.data);

  return res;
});

export const getAssetsListWithId = createAsyncThunk("get/assetslist", async (id) => {
  const res = await axiosInstance.get(`assetslist/${id}`).then((response) => response.data);

  return res;
});

export const removeAsset = createAsyncThunk("post/assets/remove", async (payload) => {
  const res = await axiosInstance.post("assets/remove", payload).then((response) => response.data);

  return res;
});

const assetsReducer = createSlice({
  name: "assetsReducer",
  initialState: initialState,
  reducers: {
    resetNewAssetInformation(state) {
      state.newAssetInformation = {};
    },
    resetRemovedAssetStatus(state) {
      state.removedAssetStatus = {};
    },
  },
  extraReducers: {
    [handleNewAssetInformation.fulfilled]: (state, action) => {
      state.newAssetInformation = action.payload;
    },
    [getAssetsList.fulfilled]: (state, action) => {
      state.assetsList = action.payload;
    },
    [getEmissionProfileList.fulfilled]: (state, action) => {
      state.emissionProfileList = action.payload;
    },
    [getAssetsListWithId.fulfilled]: (state, action) => {
      state.assetsListWithId = action.payload;
    },
    [updateAssetInformation.fulfilled]: (state, action) => {
      state.newAssetInformation = action.payload;
    },
    [removeAsset.fulfilled]: (state, action) => {
      state.removedAssetStatus = action.payload;
    },
  },
});

export const { resetNewAssetInformation, resetRemovedAssetStatus } = assetsReducer.actions;
export default assetsReducer.reducer;
