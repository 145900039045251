const form = {
  formId: "asset-form",
  formField: {
    processName: {
      name: "processName",
      label: "Name",
      type: "text",
      placeholder: "Name",
      errorMsg: "Name is required.",
    },
    methodOfCalculation: {
      name: "methodOfCalculation",
      label: "methodOfCalculation",
      type: "select",
      placeholder: "methodOfCalculation",
      errorMsg: "Calculation method is required.",
    },
    description: {
      name: "description",
      label: "Description  ",
      type: "textarea",
      placeholder: "Description",
      errorMsg: "Description  is required.",
    },
    emissionProfiles: {
      name: "emissionProfiles",
    },
    emissionProfilesFacility: {
      name: "emissionProfilesFacility",
    },
    profiles: {
      name: "profiles",
    },
    category: {
      name: "category",
      label: "category",
      type: "select",
      placeholder: "category",
      errorMsg: "Category is required.",
    },
    subcategory: {
      name: "subcategory",
      label: "sub category",
      type: "select",
      placeholder: "sub category",
      errorMsg: "Sub category is required.",
    },
    material: {
      name: "material",
      label: "material",
      type: "select",
      placeholder: "material",
      errorMsg: "Material is required.",
    },
    uom: {
      name: "uom",
      label: "Unit of Measurement",
      type: "select",
      placeholder: "Unit of Measurement",
      errorMsg: "Unit of Measurement is required.",
    },
    scope: {
      name: "scope",
      label: "scope",
      type: "select",
      placeholder: "scope",
      errorMsg: "Scope is required.",
    },
    ghgfactor: {
      name: "ghgfactor",
      label: "GHG factor",
      type: "text",
      placeholder: "GHG factor",
      errorMsg: "GHG factor is required.",
    },
    geography:{
      name: "geography",
      label: "Geography",
      type: "text",
      placeholder: "Geography",    
    },
    units: {
      name: "units",
    },
    co2: {
      name: "co2",
    },
  },
};

export default form;
