import checkout from 'layouts/facility/new-facility/schemas/form';

const {formField:{
    facilityName,   
    category,
    description,   
    emissionFactor,
    profiles,
},
} = checkout;

const initialValues = {
    [facilityName.name] : '',    
    [category.name] : { label: 'Clothing', value: 'clothing' },  
    [description.name] : '',
    [emissionFactor.name]: [{ profile: '', calculation_method: '', ghg_factor: '' }],
    profiles : []
}

export default initialValues;