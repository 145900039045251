import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";
import useAxios from "hooks/useAxios";
import { useParams } from "react-router-dom";

const UserCreditsGauge = () => {
  const [creditsInfo, setCreditsInfo] = useState({
    currentBalance: 0,
    totalAllocated: 0,
  });
  const [progressValue, setProgressValue] = useState(0); // State to hold the progress value

  const [posts, error, loading, axiosFetch] = useAxios();

  const userId = localStorage.getItem("userId"); // Directly use id or fallback to localStorage

  const fetchCreditSummary = () => {
    axiosFetch(
      {
        method: "get",
        url: `/wallet/creditSummary/${userId}`,
      },
      handleResponse
    );
  };

  const handleResponse = (response) => {
    // Assuming response.data contains the currentBalance and totalAllocated
    const { currentBalance, totalAllocated } = response.data;

    // Round the numbers
    const roundedCurrentBalance = Math.round(currentBalance);
    const roundedTotalAllocated = Math.round(totalAllocated);

    setCreditsInfo({
      currentBalance: roundedCurrentBalance,
      totalAllocated: roundedTotalAllocated,
    });
  };

  useEffect(() => {
    if (userId) {
      fetchCreditSummary();
    }
  }, [userId]);

  useEffect(() => {
    const calculatedProgressValue =
      creditsInfo.totalAllocated > 0
        ? (creditsInfo.currentBalance / creditsInfo.totalAllocated) * 100
        : 0;
    const progressValue = calculatedProgressValue > 0 ? Math.round(calculatedProgressValue) : 0;
    setProgressValue(Math.round(progressValue));
  }, [creditsInfo.currentBalance, creditsInfo.totalAllocated]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SoftTypography variant="button" sx={{ marginRight: "8px" }}>
        Balance: {creditsInfo.currentBalance} / {creditsInfo.totalAllocated}
      </SoftTypography>
      <Tooltip
        title={`Current Balance: ${creditsInfo.currentBalance}, Total Allocated: ${creditsInfo.totalAllocated}`}
      >
        <SoftProgress
          style={{ width: "100px" }}
          value={progressValue} // Use the state for progress value
          variant="gradient"
          sx={{ marginRight: "8px" }}
        />
      </Tooltip>
    </div>
  );
};

export default UserCreditsGauge;
