import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import SoftButton from 'components/SoftButton';

const DialogBox = (props) => {
console.log("props", props);

  const handleClose = () => {
    props.onClose(); //lefting the state up
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{props.message}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <SoftButton color="info" onClick={handleClose}>{props.cancelLabel}</SoftButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
