import React, { useState } from 'react';
import { Box, Card, CardContent, CardActionArea, Typography, Modal, Grid, TextField, Button, Link, useTheme } from '@mui/material';


import EmailIcon from '@mui/icons-material/AlternateEmail'; // Changed to a more appropriate icon
import DownloadIcon from '@mui/icons-material/CloudDownload';
import YouTubeIcon from '@mui/icons-material/YouTube';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { styled } from '@mui/material/styles';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

import SubmitTicketModal from './submitTicketModal';

const ResourceCard = styled(Card)(({ theme }) => ({
    transition: '0.3s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[10],
    },
}));

const LearningAndResources = () => {

    const theme = useTheme();
    const [modalOpen, setModalOpen] = useState(false);

    const downloadFile = (filePath) => {
        const link = document.createElement('a');
        link.href = filePath;
        link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Updated resources with colored icons
    const resources = [
        // { title: "Submit a Ticket", icon: <SupportAgentIcon fontSize="large" style={{ color: '#1976d2' }} />, action: () => setModalOpen(true) },
        { title: "Submit a Ticket", icon: <SupportAgentIcon fontSize="large" style={{ color: '#1976d2' }} />, action: () => window.location.href = "mailto:support@riskoa.odoo.com" },
        { title: "User Guide", icon: <DownloadIcon fontSize="large" style={{ color: '#4caf50' }} />, action: () => downloadFile(`/documentation/user_guide.pdf`) },
        { title: "About Emvide", icon: <DownloadIcon fontSize="large" style={{ color: '#ff9800' }} />, action: () => downloadFile(`/documentation/about_emvide.pdf`) },
        { title: "Tutorials", icon: <YouTubeIcon fontSize="large" style={{ color: '#ff0000' }} />, action: () => toast.info("YouTube Channel is Coming Soon!"), /* link: "https://youtube.com" */ },
        { title: "Help Center", icon: <HelpOutlineIcon fontSize="large" style={{ color: '#9c27b0' }} />, action: () => window.open('/help', '_blank') },
        { title: "Other Enquiries", icon: <EmailIcon fontSize="large" style={{ color: '#e91e63' }} />, action: () => window.location.href = "mailto:support@riskoa.com" },
    ];


    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 4 }}>
                <QuestionAnswerIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Need Help?
            </Typography>
            <Grid container spacing={2}>
                {resources.map((resource, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ResourceCard onClick={resource.action}>
                            <CardActionArea sx={{ textAlign: 'center', padding: '20px' }}>
                                {resource.icon}
                                <Typography variant="subtitle2">{resource.title}</Typography>
                            </CardActionArea>
                        </ResourceCard>
                    </Grid>
                ))}
            </Grid>
            {/* Modal for submitting a ticket */}
            <SubmitTicketModal open={modalOpen} onClose={() => setModalOpen(false)} />
        </Box>
    );
};

export default LearningAndResources;
