// react components
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios"; // Add the import for axios
import useAxios from "hooks/useAxios";
import { saveAs } from "file-saver";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftButton from "components/SoftButton";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    Grid,
    Card,
    CardContent,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
import SoftSelect from "components/SoftSelect";
import SoftBox from "components/SoftBox";

import { Card as SUDCard, CardBody, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";

import ProductsGraph from "./graphs/products";
import ProcessesGraph from "./graphs/processes";
import ResourcesGraph from "./graphs/resources";
import { Co2ByAssetPieChart, Co2ByFacilityPieChart } from "./graphs/assets_facilities_pies";
import {
    AverageCo2Card,
    AverageCoproductCo2Card,
    AverageWasteCo2Card,
    AverageResourceCo2Card,
    AverageProcessCo2Card,
    AverageNumberOfNodesCard,
} from "./graphs/avg_co2_cards";

function ProductAnalytics() {
    const [posts, error, loading, axiosFetch] = useAxios();
    const [analyticsData, setAnalyticsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("All Products");
    const [productOptions, setProductOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [isDownloading, setIsDownloading] = useState(false); // Add the new state variable for download status

    const { productId } = useParams("-1");

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const handleResponse = (response) => {
        console.log(response);
        setAnalyticsData(response.data);
        updateProductOptions(response.data);
        //console.log(filteredData);
        setIsLoading(false); // Set isLoading to false once data is available
    };
    const handleProductChange = (event) => {
        //console.log(event);
        setSelectedProduct(event);
        //console.log(filteredData);
    };
    const isProductSelected = selectedProduct && selectedProduct.value;
    const selectedValue = isProductSelected ? selectedProduct.value : null;

    const filteredData =
        analyticsData && isProductSelected && selectedValue !== "All Products"
            ? analyticsData.filter((item) => item.product_data.name === selectedValue)
            : analyticsData || [];

    if (filteredData && filteredData.length > 0) {
        filteredData.sort((a, b) => {
            // Check if a has co2_summary and b doesn't
            if (a.co2_summary && !b.co2_summary) {
                return -1; // a comes before b
            }

            // Check if b has co2_summary and a doesn't
            if (!a.co2_summary && b.co2_summary) {
                return 1; // b comes before a
            }

            // Check if both a and b have co2_summary
            if (a.co2_summary && b.co2_summary) {
                // Sort in descending order based on co2_summary.co2
                return b.co2_summary.co2 - a.co2_summary.co2;
            }

            return 0; // Preserve the original order if both don't have co2_summary
        });
    }

    const fetchAnalyticsData = (response) => {
        if (productId && productId != -1) {
            axiosFetch(
                {
                    method: "get",
                    url: "products/listAnalytics/" + productId,
                },
                handleResponse
            );
        } else {
            axiosFetch(
                {
                    method: "get",
                    url: "products/listAnalytics",
                },
                handleResponse
            );
        }
    };
    const updateProductOptions = (data) => {
        const options = [
            {
                //proID: "All Products",
                name: "All Products",
                value: "All Products",
                label: "All Products",
                proID: "-1",
            },
        ];
        data.forEach((item) => {
            // Modify the item to include "value" and "label" properties
            const modifiedItem = {
                value: item.product_data.name, // Set the "value" property to the name of the product
                label: item.product_data.name, // Set the "label" property to the name of the product
                ...item.product_data, // Include the rest of the properties from the original item
            };

            options.push(modifiedItem);
        });
        setProductOptions(options);
        if (options.length == 2) {
            setSelectedProduct(options[1]);
        } else {
            setSelectedProduct(options[0]);
        }
    };
    const viewCanvas = async () => {
        var option = productOptions.find((option) => option.name === selectedProduct.value);
        var proID = -1;
        if (option) {
            proID = option.proID;
        }
        const url = `/products/canvas/${proID}?product_name=${option.name}`;
        window.open(url, "_blank");
    };
    const downloadReport = async () => {
        setIsDownloading(true); // Set isDownloading to true when the download starts
        try {
            const authToken = localStorage.getItem("token");
            var option = productOptions.find((option) => option.name === selectedProduct.value);
            var proID = "-1";
            var resourceType = "Unknown";
            if (option) {
                proID = option.proID;
                resourceType = option.resource_type;
            }
            const currentDomain = window.location.hostname;
            const currentProtocol = window.location.protocol;
            const BASE_URL = currentProtocol + "//" + currentDomain + ":8080/api";
            //const url = `${BASE_URL}/getpdfreport/${proID}?productTitle=${selectedProduct.value}&resourceType=${resourceType}`;
            const url = `${BASE_URL}/ai/generatelcareport`;


            const response = await axios.post(
                url,
                {
                    productId: proID,
                    productTitle: selectedProduct.value,
                    resourceType: resourceType,
                    product: proID == "-1" ? null : filteredData,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                    //responseType: "arraybuffer", // This ensures the response is treated as binary data (for PDF)
                }
            );

            toast.success("Report generation in progress. You will recieve it by email!");
            /*
            // Handle the PDF response here (e.g., save or display the PDF)
            // Save the PDF using FileSaver.js
            const blob = new Blob([response.data], { type: "application/pdf" });
            // Get the current date and time
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split("T")[0];
      
            // Add the date to the file name
            const fileName = `EMVIDE_${selectedProduct.value}_${formattedDate}.pdf`;
      
            saveAs(blob, fileName);
            */
        } catch (error) {
            console.error("Error generating PDF:", error);
            // Handle errors
        } finally {
            setIsDownloading(false); // Set isDownloading to true when the download starts
        }
    };
    if (isLoading) {
        // Render a loading state while waiting for analyticsData
        return <div style={{ opacity: 0 }}>Loading...</div>;
    }

    return (
        <div id="home-container">
            <DashboardLayout>
                <DashboardNavbar id="navbar" />
                <div id="graph-container">
                    <div id="filters">
                        {/* Row 1: Filters */}
                        <Grid container spacing={2} alignItems="center">
                            {/* Left side: Dropdown */}
                            <Grid item xs={12} sm={6}>
                                <SoftBox mb={1} ml={0.5} display="inline-block">
                                    <SoftSelect
                                        name="Filter by Product"
                                        label="Filter by Product"
                                        options={productOptions.map((product) => ({
                                            value: product.name,
                                            label: product.name,
                                        }))}
                                        value={selectedProduct}
                                        onChange={handleProductChange}
                                        z-index={100}
                                    />
                                </SoftBox>
                            </Grid>

                            {/* Right side: Button */}
                            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                                <SoftBox mb={1} mr={0.5} display="inline-block">
                                    <div id="download-button">
                                        {/* Disable the button while downloading */}
                                        <SoftButton
                                            color={"success"}
                                            onClick={viewCanvas}
                                            disabled={selectedProduct.value === "All Products"}
                                        >
                                            {"View Canvas"}
                                        </SoftButton>
                                    </div>
                                </SoftBox>
                                <SoftBox mb={1} mr={0.5} display="inline-block">
                                    <div id="download-button">
                                        {/* Disable the button while downloading */}
                                        <SoftButton
                                            color="primary"
                                            variant="gradient"
                                            onClick={downloadReport}
                                            disabled={isDownloading}
                                        >
                                            {isDownloading ? "Generating..." : "Generate Report"}
                                        </SoftButton>
                                    </div>
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </div>
                    {/* Row 2: Stats Cards */}
                    <Grid container spacing={2} style={{ pageBreakInside: "avoid" }}>
                        <Grid item xs={12} sm={4}>
                            <SoftBox mb={2}>
                                <AverageNumberOfNodesCard data={filteredData} />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SoftBox mb={2}>
                                <AverageCo2Card data={filteredData} />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SoftBox mb={2}>
                                <AverageResourceCo2Card data={filteredData} />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SoftBox mb={2}>
                                <AverageProcessCo2Card data={filteredData} />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SoftBox mb={2}>
                                <AverageCoproductCo2Card data={filteredData} />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SoftBox mb={2}>
                                <AverageWasteCo2Card data={filteredData} />
                            </SoftBox>
                        </Grid>
                    </Grid>

                    {/* Row 3: Products */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <Card>
                                    <CardContent style={{ pageBreakInside: "avoid" }}>
                                        <Typography variant="h6">Products</Typography>
                                        <div style={{ overflowX: "auto", overflowY: "hidden", width: "100%" }}>
                                            <div style={{ width: "100%" }}>
                                                <ProductsGraph data={filteredData} />
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </SoftBox>
                        </Grid>
                    </Grid>
                    {/* Row 6: CO2 by Facilities and CO2 by Assets */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                                <Card style={{ "min-height": 425 }}>
                                    <CardContent style={{ pageBreakInside: "avoid" }}>
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            CO2 by Facilities
                                        </Typography>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                        >
                                            <Co2ByFacilityPieChart data={filteredData} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                                <Card style={{ "min-height": 425 }}>
                                    <CardContent style={{ pageBreakInside: "avoid" }}>
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            CO2 by Assets
                                        </Typography>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                        >
                                            <Co2ByAssetPieChart data={filteredData} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </SoftBox>
                        </Grid>
                    </Grid>

                    {/* Row 4: Processes */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <Card>
                                    <CardContent style={{ pageBreakInside: "avoid" }}>
                                        <Typography variant="h6">Processes</Typography>
                                        <div style={{ width: "100%", height: "100%" }}>
                                            <ProcessesGraph data={filteredData} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </SoftBox>
                        </Grid>
                    </Grid>

                    {/* Row 5: Resources */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <Card>
                                    <CardContent style={{ pageBreakInside: "avoid" }}>
                                        <Typography variant="h6">Resources</Typography>
                                        <div style={{ width: "100%", height: "100%" }}>
                                            <ResourcesGraph data={filteredData} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </DashboardLayout>
        </div>
    );
}
export default ProductAnalytics;
