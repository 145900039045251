import checkout from "layouts/resource/new-resource/schemas/form";

const {
  formField: {
    name,
    material,
    description,
    ghgFactor,
    eeioFactor,
    uom,
    units,
    co2,
    scope1,
    scope2,
    scope3,
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    material_id,
    notes,
    accuracy,
    geography,
    decomposition_assumptions,
    quantification_assumptions,
    verification_results,
    activity_selection_reasoning,

  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [material.name]: "",
  [description.name]: "",
  [ghgFactor.name]: "",
  [uom.name]: "",
  [units.name]: "",
  [co2.name]: "",
  [scope1.name]: "",
  [scope2.name]: "",
  [scope3.name]: "",
  [stage1.name]: "",
  [stage2.name]: "",
  [stage3.name]: "",
  [stage4.name]: "",
  [stage5.name]: "",
  [eeioFactor.name]: "",
  [material_id.name]: "",
  [notes.name]: "",
  [accuracy.name]: "1",
  [geography.name]: "Global",
  [decomposition_assumptions.name]: "",
  [quantification_assumptions.name]: "",
  [verification_results.name]: "",
  [activity_selection_reasoning.name]: "",
};

export default initialValues;
