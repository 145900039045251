import { useEffect, useState, useMemo } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

// formik components
import { Formik, Form, setFieldValue } from "formik";
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Edit Emission page components
import BasicInfo from "layouts/emission/edit-emission/components/BasicInfo";

import validations from "layouts/emission/new-emission/schemas/validations";
import form from "layouts/emission/new-emission/schemas/form";
import initialValues from "layouts/emission/new-emission/schemas/initialValues";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import VisibilityControl from "layouts/common/visibilityControl";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

function getSteps() {
  return ["Basic"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <BasicInfo formData={formData} />;
    default:
      return null;
  }
}

function NewEmission(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const [formValue, setFormValue] = useState(null);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { id } = useParams();

  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };
  const canChangeVisibility = useMemo(() => {
    if (formValue && formValue.security_user_owner_email) {
      const currentUserRole = localStorage.getItem("role");
      const currentUserEmail = localStorage.getItem("userEmail");
      if (
        currentUserRole === "superAdmin" ||
        currentUserRole === "organisationAdmin" ||
        formValue.security_user_owner_email === currentUserEmail
      ) {
        return true;
      }
    }
    return false;
  }, [formValue]);

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleCancel = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate("/emission-profiles");
    }
  };

  const submitForm = async (values, actions) => {
    console.log("values", values);

    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        navigate("/emission-profiles");
      }
    };

    axiosFetch(
      {
        method: "post",
        url: "emissionprofile/update",
        requestConfig: {
          name: values.profileName,
          calculation_method: values.methodOfCalculation,
          category: values.category,
          subcategory: values.subCategory,
          uom: values.uom,
          scope1: values.scope1,
          scope2: values.scope2,
          scope3: values.scope3,
          stage1: values.stage1,
          stage2: values.stage2,
          stage3: values.stage3,
          stage4: values.stage4,
          stage5: values.stage5,
          ghgfactor: values.ghgfactor != "" ? values.ghgfactor : values.eeioFactor,
          eeiofactor: values.eeioFactor,
          material: values.material,
          ID: id,
          notes: values.notes,
          activityUUIDs: values.activityUUIDs,
          security_isPublic: visibilityOptions.security_isPublic,
          security_organisation_visibility: visibilityOptions.security_organisation_visibility,
        },
      },
      handleResponseCallback
    );
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleResponse = (response) => {
    let formattedResponseData = response?.data.map((responseData) => {
      return {
        profileName: responseData.name,
        methodOfCalculation: responseData.calculation_method,
        category: responseData.category,
        subCategory: responseData.subcategory,
        material: responseData.material,
        ghgfactor: responseData.ghgfactor,
        eeioFactor: responseData.eeiofactor,
        uom: responseData.uom,
        scope1: responseData.scope1,
        scope2: responseData.scope2,
        scope3: responseData.scope3,
        stage1: responseData.stage1,
        stage2: responseData.stage2,
        stage3: responseData.stage3,
        stage4: responseData.stage4,
        stage5: responseData.stage5,
        notes: responseData.notes,
        activityUUIDs: responseData.activityUUIDs,

        security_user_owner_id: responseData.security_user_owner_id,
        security_user_owner_email: responseData.security_user_owner_email,
        security_isPublic: responseData.security_isPublic,
        security_organisation_visibility: responseData.security_organisation_visibility,
      };
    });

    const responseObject = formattedResponseData.reduce((obj, item) => {
      return item;
    }, {});
    setFormValue(responseObject);
    setVisibilityOptions({
      security_isPublic: responseObject.security_isPublic,
      security_organisation_visibility: responseObject.security_organisation_visibility,
    });
  };
  const fetchProfile = () => {
    axiosFetch(
      {
        method: "get",
        url: "emissionprofilelist/" + id,
      },
      handleResponse
    );
  };

  return (
    <>
      {props.isCalled == undefined && (
        <DashboardLayout>
          {loading && <LoaderSpinner />}
          <ToastContainer />
          <DashboardNavbar />
          <SoftBox py={3}>
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
              <Grid mb={2} item xs={12} lg={8}>
                <Card sx={{ overflow: "visible" }}>
                  <SoftBox p={3}>
                    <VisibilityControl
                      onVisibilityChange={handleVisibilityChange}
                      initialVisibility={{
                        security_isPublic: visibilityOptions.security_isPublic,
                        security_organisation_visibility:
                          visibilityOptions.security_organisation_visibility,
                      }}
                      disabled={!canChangeVisibility}
                    />
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Formik
                  initialValues={formValue || initialValues}
                  validationSchema={currentValidation}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form id={formId} autoComplete="off">
                      <Card sx={{ height: "100%" }}>
                        <SoftBox p={2}>
                          <SoftBox>
                            {getStepContent(activeStep, {
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                            })}
                            <SoftBox
                              mt={2}
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              {activeStep === 0 ? (
                                <SoftBox />
                              ) : (
                                <SoftButton variant="gradient" color="light" onClick={handleBack}>
                                  back
                                </SoftButton>
                              )}
                              <SoftButton variant="gradient" color="light" onClick={handleCancel}>
                                Cancel
                              </SoftButton>
                              <SoftBox flex={1} />{" "}
                              {/* This will take up available space, pushing the Save/next button to the far right */}
                              <SoftButton
                                disabled={isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="success"
                              >
                                {isLastStep ? "Save" : "next"}
                              </SoftButton>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </SoftBox>
          <Footer />
        </DashboardLayout>
      )}
      {props && props.isCalled == "true" && (
        <SoftBox py={3} mb={20}>
          {loading && <LoaderSpinner />}
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} lg={8}>
              <Formik
                initialValues={formValue || initialValues}
                validationSchema={currentValidation}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <SoftBox p={2}>
                        <SoftBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          })}
                          <SoftBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <SoftBox />
                            ) : (
                              <SoftButton variant="gradient" color="light" onClick={handleBack}>
                                back
                              </SoftButton>
                            )}
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="success"
                            >
                              {isLastStep ? "Save" : "next"}
                            </SoftButton>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </SoftBox>
      )}
    </>
  );
}

export default NewEmission;
