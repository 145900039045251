// react components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadgeDot from "components/SoftBadgeDot";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "layouts/product/product-list/components/ActionCell";
import LoaderSpinner from "components/LoaderSpinner";
import SoftInput from "components/SoftInput";

//import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";
import ComplexProjectCard from "layouts/product/product-list/components/ComplexProjectCard";

import { toast } from "react-toastify";

import Papa from "papaparse";
import { saveAs } from 'file-saver';


function ProductsList() {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [viewMode, setViewMode] = useState("card"); // "list" or "card"
  const [selectedId, setSelectedId] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const [filterOption, setFilterOption] = useState("My Products");
  const [searchTerm, setSearchTerm] = useState("");


  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Debounce search term
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredProducts(products);
    } else {
      const timerId = setTimeout(() => {
        console.log("searching... " + searchTerm)
        const filteredProducts = products.filter(product =>
          product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProducts(filteredProducts);
      }, 750); // Delay in ms

      return () => clearTimeout(timerId);
    }
  }, [searchTerm]);

  const handleResponse = (response) => {
    try {
      //refromatting data for  datatable column
      let transferredData = response?.data.map((responseData) => {
        responseData.id = responseData.proID;
        let copilotStatusDotColor = "info";
        if (responseData.copilot_status) {
          copilotStatusDotColor = responseData.copilot_status.toLowerCase() == "preparing" ? "warning"
            : responseData.copilot_status.toLowerCase() == "failed" ? "error" : "success";
        }
        return {
          productName: responseData.name || "",
          description:
            responseData.description && responseData.description.trim() !== ""
              ? responseData.description.length <= 100
                ? responseData.description
                : responseData.description.substring(0, 100) + "..."
              : "",
          purpose:
            responseData.purpose && responseData.purpose.trim() !== ""
              ? responseData.purpose.length <= 30
                ? responseData.purpose
                : responseData.purpose.substring(0, 30) + "..."
              : "",
          service_life_time: responseData.service_life_time || "",
          emission: responseData.emission ? responseData.emission.toFixed(1) : "",
          coproduct_co2: responseData.coproduct_co2 ? responseData.coproduct_co2.toFixed(1) : "",
          waste_co2: responseData.waste_co2 ? responseData.waste_co2.toFixed(1) : "",
          id: responseData.proID,
          productType: responseData.resource_type,
          number_resources: responseData.number_resources,
          number_processes: responseData.number_processes,
          number_resources_processes:
            (responseData.number_resources ? responseData.number_resources : 0) +
            "|" +
            (responseData.number_processes ? responseData.number_processes : 0),
          createdAt: responseData.createdAt
            ? new Date(responseData.createdAt).toLocaleString() // or use toLocaleDateString() for just the date
            : "",
          updatedAt: responseData.updatedAt
            ? new Date(responseData.updatedAt).toLocaleString() // or use toLocaleDateString() for just the date
            : "",
          organisation: responseData.security_organisation_owner,
          user: responseData.security_user_owner_email,
          customer: responseData.customer_organisation,
          copilot_status: responseData.copilot_status,
          copilot_status_badge: (
            <SoftBox ml={-1.325}>
              <SoftBadgeDot size="small"
                badgeContent={responseData.copilot_status} color={copilotStatusDotColor} />
            </SoftBox>
          ),
          copilot_completion_time: responseData.copilot_completion_time,
          accuracy: responseData.accuracy,
          image_url: responseData.image_url,
          responseData: responseData,
          action: (
            <ActionCell
              id={responseData.proID}
              {...responseData}
              deleteHandler={deleteHandler}
              jsonExportHandler={jsonExportHandler}
              csvExportHandler={csvExportHandler}
            />
          ),
        };
      });
      setProducts(transferredData);
      setFilteredProducts(transferredData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProduct = () => {
    let url = "products/list";
    if (filterOption === "My Organisation Products") {
      const organisation = localStorage.getItem("userOrganisationOwner");
      url += `?organisation=${organisation}`;
    } else if (filterOption === "My Products") {
      const userEmail = localStorage.getItem("userEmail");
      url += `?userEmail=${userEmail}`;
    } else if (filterOption === "My Private Products") {
      const userEmail = localStorage.getItem("userEmail");
      url += `?userEmail=${userEmail}&private=true`;
    }
    axiosFetch(
      {
        method: "get",
        url: url,
      },
      handleResponse
    );
  };

  // Update the fetchProduct effect to run whenever the filter option changes
  useEffect(() => {
    fetchProduct();
  }, [filterOption]);

  const deleteHandler = (prodId) => {
    axiosFetch({
      method: "post",
      url: "products/remove",
      requestConfig: {
        proID: prodId,
      },
    });
    setSelectedId(prodId);
  };

  const csvExportHandler = async (id) => {
    axiosFetch(
      {
        method: "get",
        url: "products/export-flat-csv/" + id,
      },
      handleCSVExportResponse
    );
  };


  const handleCSVExportResponse = (response) => {
    try {
      const csvContent = response.data.nodeDataCSV;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      saveAs(blob, `${response.data.product.name}_${formattedDate}_Export_Emvide.csv`);
    } catch (error) {
      toast.error("Error exporting product");
      console.error("Error exporting product:", error);
    }
  };


  const jsonExportHandler = async (id) => {
    axiosFetch(
      {
        method: "get",
        url: "products/export/" + id,
      },
      handleJsonExportResponse
    );
  };
  const handleJsonExportResponse = (response) => {
    try {
      const productName = response.data.product.name;
      const productID = response.data.product.proID;
      // Pretty print JSON with 2-space indentation
      const prettyJson = JSON.stringify(response.data, null, 2);
      // Create a blob with the pretty printed JSON
      const blob = new Blob([prettyJson], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", productID + "_" + productName + ".json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Error exporting product");
      console.error("Error exporting product:", error);
    }
  };

  useEffect(() => {
    if (selectedId != null) {
      let filterdTableData = products.filter((row) => row.id !== selectedId);
      //setProducts(transferredData);
      //setFilteredProducts(transferredData);
      setProducts(filterdTableData);
      setFilteredProducts(filterdTableData);
    }
  }, [selectedId]);

  useEffect(() => {
    fetchProduct();
  }, []);

  // Function to render products as cards
  const renderProductCards = () => {
    return (
      <Grid container spacing={3}>
        {filteredProducts.map((product, index) => (
          <Grid item xs={12} sm={6} lg={3} key={index}>
            <ComplexProjectCard
              image={product.image_url || "product-placeholder"} // Adjust according to your data structure
              productName={product.productName}
              createdAt={product.createdAt}
              updatedAt={product.updatedAt}
              user={product.user}
              customer={product.customer}
              productId={product.id.toString()}
              productType={product.productType}
              description={product.description}
              numberResourcesProcesses={product.number_resources_processes}
              emission={product.emission}
              coproductCo2={product.coproduct_co2}
              wasteCo2={product.waste_co2}
              copilotStatus={product.copilot_status ? product.copilot_status : "N/A"} // Example adjustment, ensure your data structure is correctly represented
              copilotCompletionTime={product.copilot_completion_time ? (parseFloat(product.copilot_completion_time) / 60).toFixed(1).toString() : "N/A"}
              accuracy={product.accuracy ? product.accuracy.toString() : "N/A"}
              dropdown={product.action}
              actionCellComponent={
                <ActionCell
                  id={product.id}
                  {...product.responseData}
                  deleteHandler={deleteHandler}
                  jsonExportHandler={jsonExportHandler}
                  csvExportHandler={csvExportHandler}
                />
              }
              members={[]}
            />
          </Grid>
        ))}
      </Grid>
    );
  };


  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox my={3} style={{ minHeight: "80vh", marginBottom: "20px" }}>
        <Card style={{ paddingBottom: "20px" }} >

          <SoftBox p={3}>
            <Grid container spacing={2} alignItems="center">
              {/* First row: Title and Filter buttons */}
              <Grid item xs={12} sm={8}>
                <SoftTypography variant="h5" fontWeight="medium">Products</SoftTypography>
                <Grid container spacing={1} mt={2}>
                  {/* Filter buttons */}
                  <Grid item>
                    <SoftButton variant={filterOption === "All Products" ? "gradient" : "outlined"} color="info" size="small" onClick={() => setFilterOption("All Products")}>All Products (Incl. Public)</SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton variant={filterOption === "My Organisation Products" ? "gradient" : "outlined"} color="info" size="small" onClick={() => setFilterOption("My Organisation Products")}>My Organisation Products</SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton variant={filterOption === "My Products" ? "gradient" : "outlined"} color="info" size="small" onClick={() => setFilterOption("My Products")}>My Products</SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton variant={filterOption === "My Private Products" ? "gradient" : "outlined"} color="info" size="small" onClick={() => setFilterOption("My Private Products")}>My Private Products</SoftButton>
                  </Grid>
                </Grid>
              </Grid>

              {/* First row: View mode buttons to the right */}
              <Grid item xs={12} sm={4} container justifyContent="flex-end">
                <Grid item>
                  <SoftButton variant={viewMode === "card" ? "gradient" : "outlined"} color="info" onClick={() => setViewMode("card")}><Icon>view_module</Icon>Card View</SoftButton>
                  <SoftButton variant={viewMode === "list" ? "gradient" : "outlined"} color="info" onClick={() => setViewMode("list")} style={{ marginLeft: "8px" }}><Icon>view_list</Icon>List View</SoftButton>
                </Grid>
                {/* Second row: New Product button aligned to the right */}
                <Grid item mt={2}>
                  <SoftBox display="flex" alignItems="center">
                    {/* Search input */}
                    <SoftInput
                      placeholder="Search products..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      style={{ marginLeft: "8px", width: "200px" }}
                    />
                    {/* New Product button */}
                    <Link to="/products/new">
                      <SoftButton variant="gradient" color="success" size="small" style={{ marginLeft: "8px", width: "200px" }}>+ New Product</SoftButton>
                    </Link>

                  </SoftBox>
                </Grid>
              </Grid>


            </Grid>
          </SoftBox>
          {viewMode === "list" ? (
            <DataTable
              table={{
                columns: [
                  { Header: "Date Created", accessor: "createdAt" },
                  { Header: "Date Updated", accessor: "updatedAt" },
                  { Header: "User", accessor: "user" },
                  { Header: "Customer", accessor: "customer" },
                  { Header: "Product ID", accessor: "id" },
                  { Header: "Product", accessor: "productName" },
                  { Header: "Product Type", accessor: "productType" },
                  // { Header: "Description", accessor: "description" },
                  // { Header: "R.Nodes", accessor: "number_resources" },
                  // { Header: "P.Nodes", accessor: "number_processes" },
                  { Header: "# Nodes (R|P)", accessor: "number_resources_processes" },

                  // { Header: "Purpose", accessor: "purpose" },
                  { Header: "C02e", accessor: "emission" },
                  { Header: "Coproduct C02e", accessor: "coproduct_co2" },
                  { Header: "Waste C02e", accessor: "waste_co2" },
                  // { Header: "Service life time", accessor: "service_life_time" },
                  { Header: "Copilot Status", accessor: "copilot_status_badge" },
                  { Header: "Copilot Ex. Time (s)", accessor: "copilot_completion_time" },
                  { Header: "Copilot Accuracy", accessor: "accuracy" },
                  { Header: "action", accessor: "action" },
                ],
                rows: filteredProducts,
              }}
              entriesPerPage={{
                defaultValue: 10,
                entries: [10, 20, 30, 40],
              }}
              pagination={{
                variant: "gradient",
                color: "success",
              }}
              canSearch
              isSorted
            />
          ) : (
            renderProductCards()
          )}




        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsList;
