import React, { useState, useRef } from "react";
import { Card, CardContent, Grid, Button, Icon, Modal, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import CsvGenerator from "layouts/copilot/components/csvGenerator.js";
import CopilotSearchAndCompose from "layouts/copilot/components/searchAndCompose.js";

import GenerateReport from "layouts/common/generateReport";

const copilotSearchCompoeStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
};
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 8, // Add this line to make rounded corners
};

const QuickActions = ({ productOptions, productsData }) => {
    const [openCsvGenerator, setOpenCsvGenerator] = useState(false);
    const [openSearchCompose, setOpenSearchCompose] = useState(false);
    const [openReportGenerator, setOpenReportGenerator] = useState(false);

    const handleOpenCsvGenerator = () => setOpenCsvGenerator(true);
    const handleCloseCsvGenerator = () => setOpenCsvGenerator(false);

    const handleOpenSearchCompose = () => setOpenSearchCompose(true);
    const handleCloseSearchCompose = () => setOpenSearchCompose(false);

    const handleOpenReportGenerator = () => setOpenReportGenerator(true);
    const handleCloseReportGenerator = () => setOpenReportGenerator(false);

    const actions = [
        { label: 'CSV from BOM', icon: 'cloud_download', onClick: handleOpenCsvGenerator },
        { label: 'New Product', icon: 'add_circle_outline', onClick: () => { window.open('/products/new', '_blank'); } },
        { label: 'Generate Reports', icon: 'analytics', onClick: handleOpenReportGenerator },
        { label: 'Search & Compose', icon: 'search', onClick: handleOpenSearchCompose },
        /*         { label: 'Create Facility', icon: 'business', onClick: () => { } },
                { label: 'Create Asset', icon: 'attach_money', onClick: () => {} },
                { label: 'Create Emission Profile', icon: 'bar_chart', onClick: () => { } }, */
    ];

    return (
        <Card>
            <CardContent>
                <SoftBox mb={1}>
                    <SoftTypography variant="h6" fontWeight="medium">
                        Quick Actions
                    </SoftTypography>
                </SoftBox>
                <Grid container spacing={2}>
                    {actions.map((action, index) => (
                        <Grid item xs={12} md={6} lg={3} key={index}> {/* Adjust as needed */}
                            <SoftButton
                                variant="gradient"
                                color="primary"
                                startIcon={<Icon>{action.icon}</Icon>}
                                onClick={action.onClick}
                                fullWidth
                            >
                                {action.label}
                            </SoftButton>
                        </Grid>
                    ))}
                </Grid>

                {/* CSV Generator Modal */}
                <Modal
                    open={openCsvGenerator}
                    onClose={handleCloseCsvGenerator}
                    aria-labelledby="csv-generator-modal-title"
                    aria-describedby="csv-generator-modal-description"
                >
                    <Box sx={modalStyle}>
                        {/* Close Button at the top right corner */}
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseCsvGenerator}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CsvGenerator />
                    </Box>
                </Modal>

                {/* Search & Compose Modal */}
                <Modal
                    open={openSearchCompose}
                    onClose={handleCloseSearchCompose}
                    aria-labelledby="search-compose-modal-title"
                    aria-describedby="search-compose-modal-description"
                >
                    <Box sx={copilotSearchCompoeStyle}>
                        {/* Close Button at the top right corner */}
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseSearchCompose}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <CopilotSearchAndCompose />
                    </Box>
                </Modal>

                {/* Report Generator Modal */}
                <Modal
                    open={openReportGenerator}
                    onClose={handleCloseReportGenerator}
                    aria-labelledby="report-generator-modal-title"
                    aria-describedby="report-generator-modal-description"
                >
                    <Box sx={modalStyle}>
                        {/* Close Button at the top right corner */}
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseReportGenerator}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <GenerateReport
                            productOptions={productOptions}
                            productsData={productsData}
                        />
                    </Box>
                </Modal>
            </CardContent>
        </Card>
    );
};

export default QuickActions;