import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

import FormField from "layouts/login/sign-up/FormField";
import { Formik, Form } from "formik";
import form from "layouts/login/sign-up/schemas/form";
import initialValues from "layouts/login/sign-up/schemas/initialValues";
import validations from "layouts/login/sign-up/schemas/validations";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import { handleSignUp } from "reducers/login-reducer";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUpValues } = useSelector((state) => state.loginReducer);
  const { formId, formField } = form;
  const { name, email, password, position, company, location, phone } = formField;

  useEffect(() => {
    if (signUpValues.accessToken) {
      //localStorage.setItem("token", signUpValues.accessToken);
      toast.success("Account created. Please verify your email and wait our activation!");
      navigate("/sign-in");
    }
  }, [signUpValues]);

  const handleSubmit = (values) => {
    const payload = {
      name: values.name,
      email: values.email,
      password: values.password,
      position: values.position,
      company: values.company,
      location: values.location,
      phone: values.phone,
      role: "viewer",
    };

    dispatch(handleSignUp(payload));
  };

  return (
    <Grid container spacing={1} justifyContent="center" marginTop="50px">
      <ToastContainer />
      <Grid item xs={12} sm={6}>
        <Card>
          <SoftBox p={3} mb={1} textAlign="center">
            <SoftTypography variant="h5" fontWeight="medium">
              User Registration
            </SoftTypography>
          </SoftBox>
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox pt={2} pb={3} px={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        placeholder={name.placeholder}
                        error={errors.name && touched.name}
                        success={values.name ? values.name.length > 0 && !errors.name : false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        placeholder={email.placeholder}
                        error={errors.email && touched.email}
                        success={values.email ? values.email.length > 0 && !errors.email : false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={password.type}
                        label={password.label}
                        name={password.name}
                        placeholder={password.placeholder}
                        error={errors.password && touched.password}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={position.type}
                        label={position.label}
                        name={position.name}
                        placeholder={position.placeholder}
                        error={errors.position && touched.position}
                        success={
                          values.position ? values.position.length > 0 && !errors.position : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={company.type}
                        label={company.label}
                        name={company.name}
                        placeholder={company.placeholder}
                        error={errors.company && touched.company}
                        success={
                          values.company ? values.company.length > 0 && !errors.company : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={phone.type}
                        label={phone.label}
                        name={phone.name}
                        placeholder={phone.placeholder}
                        error={errors.phone && touched.phone}
                        success={values.phone ? values.phone.length > 0 && !errors.phone : false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type={location.type}
                        label={location.label}
                        name={location.name}
                        placeholder={location.placeholder}
                        error={errors.location && touched.location}
                        success={
                          values.location ? values.location.length > 0 && !errors.location : false
                        }
                      />
                    </Grid>
                  </Grid>

                  <SoftBox mt={4} mb={1}>
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      fullWidth
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      sign up
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mt={3} textAlign="center">
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      Already have an account?&nbsp;
                      <SoftTypography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="dark"
                        fontWeight="bold"
                        textGradient
                      >
                        Sign in
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignUp;
