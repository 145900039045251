import React from "react";
import Chart from "react-apexcharts";

const Co2ByAssetPieChart = ({ data }) => {
  // Calculate CO2 values by asset
  const co2ByAssetData = data.flatMap((item) =>
    (item.canvas_nodes || []).flatMap((node) =>
      node.type === "react" && node.assets
        ? node.assets
            .filter((asset) => asset.asset && asset.profiles && asset.profiles.length > 0)
            .map((asset) => ({
              asset: asset.asset,
              co2: asset.profiles.reduce((sum, profile) => sum + (profile.co2 || 0), 0),
            }))
        : []
    )
  );

  // Group CO2 values by asset names
  const groupedCo2Data = co2ByAssetData.reduce((acc, item) => {
    const existingItem = acc.find((entry) => entry.asset === item.asset);
    if (existingItem) {
      existingItem.co2 += item.co2;
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  // Extract asset names and CO2 values
  const assetNames = groupedCo2Data.map((item) => item.asset);
  const co2ValuesByAsset = groupedCo2Data.map((item) => item.co2);

  // Pie chart data
  const chartData = {
    series: co2ValuesByAsset,
    options: {
      chart: {
        type: "pie",
        height: 350,
      },
      tooltip: {
        y: {
          formatter: (value) => value.toFixed(3),
        },
      },
      labels: assetNames,
      legend: {
        position: "bottom",
      },
      noData: {
        text: "NO DATA",
        align: "center",
        verticalAlign: "middle",
      },
    },
  };
  return <Chart options={chartData.options} series={chartData.series} type="pie" height={350} />;
};

const Co2ByFacilityPieChart = ({ data }) => {
  // Calculate CO2 values by facility
  const co2ByFacilityData = (data || []).flatMap((item) => {
    if (!item.canvas_nodes) return [];

    return item.canvas_nodes.flatMap((node) => {
      if (!node.facilities) return [];

      return node.facilities.flatMap((facility) => {
        if (!facility.profiles) return [];

        return facility.profiles.map((profile) => ({
          facility: facility.facility || "N/A",
          co2: profile.co2 || 0,
        }));
      });
    });
  });

  // Group CO2 values by facility names
  const groupedCo2Data = co2ByFacilityData.reduce((acc, item) => {
    const existingItem = acc.find((entry) => entry.facility === item.facility);
    if (existingItem) {
      existingItem.co2 += item.co2;
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  // Extract facility names and CO2 values
  const facilityNames = groupedCo2Data.map((item) => item.facility);
  const co2Values = groupedCo2Data.map((item) => item.co2);

  // Pie chart data
  const chartData = {
    series: co2Values,

    options: {
      chart: {
        type: "pie",
        height: 350,
      },
      tooltip: {
        y: {
          formatter: (value) => value.toFixed(3),
        },
      },
      labels: facilityNames,
      legend: {
        position: "bottom",
      },
      noData: {
        text: "NO DATA",
        align: "center",
        verticalAlign: "middle",
      },
    },
  };

  return <Chart options={chartData.options} series={chartData.series} type="pie" height={350} />;
};

export { Co2ByAssetPieChart, Co2ByFacilityPieChart };
