import * as Yup from "yup";
import checkout from "layouts/emission/new-emission/schemas/form";

const {
  formField: { profileName, methodOfCalculation },
} = checkout;

const validations = [
  Yup.object().shape({
    [profileName.name]: Yup.string().required(profileName.errorMsg),
    //[methodOfCalculation.name]: Yup.string()
    //  .required(methodOfCalculation.errorMsg)
    //  .oneOf(["Activity", "Fincancial", "Direct"]),
  }),
];

export default validations;
