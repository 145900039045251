import { React, useState, memo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import ConfirmDialog from "components/ConfirmDialog";
import DialogBox from "components/DialogBox";

const ActionCell = (props) => {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [associatedData, setAssociatedData] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [assetId, setAssetId] = useState("");

  const handleAssetCheckResponse = (response) => {
    const associatedData = response.data.map((item) => item);
    const associatedDataString = associatedData.join(",");
    setAssociatedData(associatedDataString);

    if (associatedDataString == "") {
      setShowConfirm(true);
    }

    if (associatedDataString != "") {
      setShowAlert(true);
    }
  };

  const deleteAsset = (data) => {
    setAssetId(data.ID);
    axiosFetch(
      {
        method: "post",
        url: "assets/check",
        requestConfig: {
          assetName: data.name,
        },
      },
      handleAssetCheckResponse
    );
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setShowAlert(false);
  };

  const handleConfirm = () => {
    props.deleteHandler(assetId);
    setShowConfirm(false);
  };

  return (
    <>
      {showConfirm && (
        <ConfirmDialog
          message="Are you sure you want to delete this record?"
          confirmLabel="Delete"
          cancelLabel="Cancel"
          open={showConfirm}
          onClose={handleCancel}
          onConfirm={handleConfirm}
        />
      )}

      {showAlert && (
        <DialogBox
          message={`This facility is associated with ${associatedData} products`}
          cancelLabel="Cancel"
          open={showAlert}
          onClose={handleCancel}
        />
      )}

      <SoftBox display="flex" alignItems="center">
        {/* <Link to={`/assets/edit/${props.id}`}> */}
        <a href={`/assets/edit/${props.id}`}>
          <SoftTypography
            variant="body1"
            color="secondary"
            sx={{ cursor: "pointer", lineHeight: 0 }}
          >
            <Tooltip title="Edit asset" placement="top">
              <Icon>edit</Icon>
            </Tooltip>
          </SoftTypography>
          {/* </Link> */}
        </a>
        &nbsp;&nbsp;&nbsp;
        <SoftTypography
          variant="body1"
          color="secondary"
          onClick={() => {
            deleteAsset(props);
          }}
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Delete asset" placement="top">
            <Icon>delete</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
    </>
  );
};

export default ActionCell;
