import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/assets/new-asset/components/FormField";
import SoftButton from "components/SoftButton";
import CustomSelect from "components/CustomSelect";
import { Field, ErrorMessage } from "formik";

const methodOfCalculationOptions = [
  { value: "activity", label: "Activity" },
  { value: "financial", label: "Financial" },
  { value: "direct", label: "Direct" },
];
function AssetInfo({ formData }) {
  const [state, setState] = useState("...");
  const { formField, values, errors, touched } = formData;
  const {
    processName,
    methodOfCalculation,
    description,
    emissionFactor,
    category,
    subcategory,
    material,
    uom,
    scope,
    ghgfactor,
    unit,

    coproduct_co2_percent,
    coproduct_co2,
    waste_co2_percent,
    waste_co2,
    copilot_status,
    output_resource,
    profiles,
    emissionProfiles,
    emissionProfilesFacility,
    decomposition_assumptions,
    quantification_assumptions,
    verification_results,
    activity_selection_reasoning,
    notes,
    geography,

    accuracy,
    uncertainty,


  } = formField;

  const {
    processName: processNameV,
    description: descriptionV,
    methodOfCalculation: methodOfCalculationV,
    category: categoryV,
    subcategory: subcategoryV,
    material: materialV,
    uom: uomV,
    scope: scopeV,
    ghgfactor: ghgfactorV,
    unit: unitV,

    coproduct_co2_percent: coproduct_co2_percentV,
    coproduct_co2: coproduct_co2V,
    waste_co2_percent: waste_co2_percentV,
    waste_co2: waste_co2V,
    copilot_status: copilot_statusV,
    output_resource: output_resourceV,
    profiles: profilesV,
    emissionProfiles: emissionProfilesV,
    emissionProfilesFacility: emissionProfilesFacilityV,
    decomposition_assumptions: decomposition_assumptionsV,
    quantification_assumptions: quantification_assumptionsV,
    verification_results: verification_resultsV,
    activity_selection_reasoning: activity_selection_reasoningV,
    notes: notesV,
    geography: geographyV,

    accuracy: accuracyV,
    uncertainty: uncertaintyV,
  } = values;

  const [editorValue, setEditorValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br>"
  );

  return (
    <SoftBox>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <SoftTypography variant="h5">Basic Details</SoftTypography>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Mandatory informations
          </SoftTypography>
        </div>


      </div>

      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid item mb={3}>
              <FormField
                type={processName.type}
                label={processName.label}
                name={processName.name}
                value={processNameV}
                placeholder={processName.placeholder}
                error={errors.processName && touched.processName}
              // success={processNameV.length > 0 && !errors.processName}
              />
            </Grid>
            <Grid item mb={3}>
              <FormField
                type={geography.type}
                label={geography.label}
                name={geography.name}
                value={geographyV}
                placeholder={geography.placeholder}
              //error={errors.processName && touched.processName}
              // success={processNameV.length > 0 && !errors.processName}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              multiline
              rows={5}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Co-product Co2 percentage (comulative)"
              name="coproduct_co2_percent"
              value={coproduct_co2_percentV}
              placeholder="Co-product Co2 percent"
            />
            <FormField
              type="text"
              label="Additional co-product Co2"
              name="coproduct_co2"
              value={coproduct_co2V}
              placeholder="Additional co-product Co2"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Waste Co2 percentage (comulative)"
              name="waste_co2_percent"
              value={waste_co2_percentV}
              placeholder="Waste Co2 percent"
            />
            <FormField
              type="text"
              label="Additional waste Co2"
              name="waste_co2"
              value={waste_co2V}
              placeholder="Additional waste Co2"
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
AssetInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AssetInfo;
