import { useEffect, useState } from "react";

import useAxios from "hooks/useAxios";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/assets/new-asset/components/FormField";
import SoftSelect from "components/SoftSelect";
import { Link } from "react-router-dom";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";
import { set } from "date-fns";

function BasicInfo({ formData }) {
  const [state, setState] = useState("...");
  const [posts, error, loading, axiosFetch] = useAxios();
  const defaultOption = { value: "new", label: "+ New" };
  const emptyItem = { value: "", label: "" };
  const { formField, values, errors, touched, setFieldValue } = formData;
  const [resourceOptions, setResourceOptions] = useState([]);
  const [isNewResource, setIsNewResource] = useState(false);

  const [productsLoaded, setProductsLoaded] = useState(false);
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  const datasource = [
    { value: "ecoinvent", label: "Ecoinvent" },
    /*    { value: "ghg", label: "GHG Library" },*/
    { value: "custom", label: "Custom Resource" },
    { value: "interimProduct", label: "Interim Product" },
    { value: "finishedProduct", label: "Finished Product" },
    { value: "new", label: "Create New Resource" },
  ];
  //const [selectedDatasource, setSelectedDatasource] = useState(datasource[0].value);
  const [selectedDatasource, setSelectedDatasource] = useState();
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [resourcesGhg, setResourcesGhg] = useState([]);
  const [resourcesCustom, setResourcesCustom] = useState([]);
  const [interimProducts, setInterimProducts] = useState([]);
  const [finishedProducts, setFinishedProducts] = useState([]);

  // make a variable for current product lcia_model and lcia_method
  const [lciaModel, setLciaModel] = useState("");
  const [lciaMethod, setLciaMethod] = useState("");

  const [isCurrentProductFetched, setIsCurrentProductFetched] = useState(false);

  const {
    name,
    material,
    description,
    ghgFactor,
    uom,
    units,
    co2,
    scope1,
    scope2,
    scope3,
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    resource_type,
    resource_datasource,
    id,
    product_id,
    notes,
    accuracy,
    uncertainty,
    geography,
    activityUUIDs,

    decomposition_assumptions,
    quantification_assumptions,
    verification_results,
    activity_selection_reasoning,

  } = formField;

  const {
    name: nameV,
    material: materialV,
    description: descriptionV,
    uom: uomV,
    ghgFactor: ghgFactorV,
    units: unitsV,
    co2: co2V,
    scope1: scope1V,
    scope2: scope2V,
    scope3: scope3V,
    stage1: stage1V,
    stage2: stage2V,
    stage3: stage3V,
    stage4: stage4V,
    stage5: stage5V,
    resource_type: resourceTypeV,
    resource_datasource: resource_datasourceV,
    id: idV,
    product_id: product_idV,
    notes: notesV,
    accuracy: accuracyV,
    uncertainty: uncertaintyV,
    geography: geographyV,
    activityUUIDs: activityUUIDsV,

    decomposition_assumptions: decomposition_assumptionsV,
    quantification_assumptions: quantification_assumptionsV,
    verification_results: verification_resultsV,
    activity_selection_reasoning: activity_selection_reasoningV,

  } = values;
  /*
  const handleResponse = (response) => {
    setResourceOptions([defaultOption]);
    response.data.map((item) =>
      setResourceOptions((prevState) => [
        ...prevState,
        {
          value: item.material,
          label: item.material,
          id: item.resID,
          description: item.description,
          uom: item.uom,
          scope1: item.scope1 ? item.scope1 : "",
          scope2: item.scope2 ? item.scope2 : "",
          scope3: item.scope3 ? item.scope3 : "",
          stage1: item.stage1 ? item.stage1 : "",
          stage2: item.stage2 ? item.stage2 : "",
          stage3: item.stage3 ? item.stage3 : "",
          stage4: item.stage4 ? item.stage4 : "",
          stage5: item.stage5 ? item.stage5 : "",
          ghgFactor: item.ghgFactor ? item.ghgFactor : 0,
        },
      ])
    );
  };
*/
  const handleEcoProfilesResponse = (response) => {
    console.log(response);
  };

  const handleListResourceResponse = (response) => {
    const dynamicOptions = response.data.map((item) => ({
      value: item.material,
      label: item.material,
      id: item.resID,
      description: item.description,
      uom: item.uom,
      scope1: item.scope1 ? item.scope1 : "",
      scope2: item.scope2 ? item.scope2 : "",
      scope3: item.scope3 ? item.scope3 : "",
      stage1: item.stage1 ? item.stage1 : "",
      stage2: item.stage2 ? item.stage2 : "",
      stage3: item.stage3 ? item.stage3 : "",
      stage4: item.stage4 ? item.stage4 : "",
      stage5: item.stage5 ? item.stage5 : "",
      ghgFactor: item.ghgFactor ? item.ghgFactor : 0,
      resource_datasource: item.datasource,
    }));
    const resourcesWithICE = dynamicOptions.filter(
      (item) => item.resource_datasource && item.resource_datasource == "ghg"
    );

    const resourcesWithoutICE = dynamicOptions.filter(
      (item) =>
        item.resource_datasource == null ||
        (item.resource_datasource && item.resource_datasource != "ghg")
    );

    setResourcesGhg(resourcesWithICE);
    setResourcesCustom(resourcesWithoutICE);
    //setResourceOptions([...resourcesWithICE]);

    if (selectedDatasource.value === "custom") {
      setResourceOptions(resourcesWithoutICE);
    }

    setResourcesLoaded(true);
  };

  const handleListProductResponse = (response) => {
    //console.log(response);
    const dynamicOptions = response.data.map((item) => ({
      value: item.name,
      label: item.name,
      id: item.proID,
      description: item.purpose || item.description,
      //uom: item.uom,
      scope1: item.scope1 ? item.scope1 : "",
      scope2: item.scope2 ? item.scope2 : "",
      scope3: item.scope3 ? item.scope3 : "",
      stage1: item.stage1 ? item.stage1 : "",
      stage2: item.stage2 ? item.stage2 : "",
      stage3: item.stage3 ? item.stage3 : "",
      stage4: item.stage4 ? item.stage4 : "",
      stage5: item.stage5 ? item.stage5 : "",
      ghgFactor: item.emission ? item.emission : 0,
      resource_type: item.resource_type ? item.resource_type : null,
    }));

    const interimProducts = dynamicOptions.filter((item) =>
      item.resource_type == "interim"
      // item.resource_type == "interim" && (product_idV ? item.id !== Number(product_idV) : true)
    );

    const finishedProducts = dynamicOptions.filter((item) =>
      item.resource_type != "interim"
      // item.resource_type != "interim" && (product_idV ? item.id !== Number(product_idV) : true)
    );

    setInterimProducts(interimProducts);
    setFinishedProducts(finishedProducts);
    let selectedMaterialOption = null
    if (selectedDatasource.value === "interimProduct") {
      setResourceOptions([...interimProducts]);
      selectedMaterialOption = interimProducts.find((item) => item.value === materialV);
    }
    if (selectedDatasource.value === "finishedProduct") {
      console.log('finishedProducts', finishedProducts)
      setResourceOptions([...finishedProducts]);
      selectedMaterialOption = finishedProducts.find((item) => item.value === materialV);
    }

    if (selectedMaterialOption) {
      setSelectedMaterial(selectedMaterialOption);
    }

    // setProductsLoaded(true);
    // fetchCurrentProduct(); // TODO, move this from
  };

  // function fetchEcoProfile, takes name and description and calls a function to handle response
  const fetchEcoProfile = (name, description) => {
    axiosFetch(
      {
        method: "get",
        url: "/ecoinvent/activity/" + name + " (" + description + ")",
      },
      handleEcoProfilesResponse
    );
  };
  const fetchEcoActivityNames = (name, description, geography) => {
    axiosFetch(
      {
        method: "post",
        url: "/ai/getactivitynames",
        requestConfig: {
          search_term: name + " (" + description + ")"
        },
      },
      handleEcoActivityNamesResponse
    );
  };
  const fetchEcoDecomposedMaterials = (product_id, name, description, units, uom, geography) => {
    axiosFetch(
      {
        method: "post",
        url: "/ai/getdecomposedmaterials",
        requestConfig: {
          product_id: product_id,
          material_name: name,
          material_description: description,
          material_units: units,
          material_unit_of_measurement: uom,
          geography: geography || "Global",
          enable_decompose: true,
        },
      },
      handleEcoDecompositionResponse
    );
  };
  const handleEcoDecompositionResponse = (response) => {
    console.log(response);
    if (response.data) {
      const option = response.data;

      let totalCo2 = 0.0;
      let ghgFactor = 0.0;
      let materialName = "Copilot Composite";
      let units = option.material_units;
      let uom = option.material_unit_of_measurement;

      let totalAccuracyCo2Product = 0.0;
      let decomposition_assumptions = option.decomposition_assumptions || [];
      let quantification_assumptions = option.quantification_assumptions || [];
      let verification_results = option.verification_results || [];
      let activity_selection_reasoning = option.activity_selection_reasoning || [];

      if (option.decomposed_materials.length > 1) {
        for (const material of option.decomposed_materials) {
          if (material.co2) {
            totalCo2 += material.co2;
            totalAccuracyCo2Product += material.co2 * material.accuracy;
          }
        }
        ghgFactor = option.material_units != 0 ? totalCo2 / option.material_units : 0;
        units = option.material_units;
      } else {
        if (option.decomposed_materials[0].co2) {
          totalCo2 = option.decomposed_materials[0].co2;
          //ghgFactor = option.decomposed_materials[0].co2_factor;
          ghgFactor = option.material_units != 0 ? option.decomposed_materials[0].co2 / option.material_units : 0;
          totalAccuracyCo2Product =
            option.decomposed_materials[0].co2 *
            option.decomposed_materials[0].accuracy;
          //materialName = option.decomposed_materials[0].decomposed_material_activity_name;
          //uom = option.decomposed_materials[0].decomposed_material_unit_of_measurement;
        }
      }
      let accuracy =
        totalCo2 !== 0
          ? (totalAccuracyCo2Product / totalCo2).toFixed(2)
          : 1.0;

      //setIsNewResource(true);
      //setFieldValue("is_new_resource", true);
      setFieldValue("uom", uom);
      setFieldValue("units", units);
      //setFieldValue("description", option.description);
      setFieldValue("ghgFactor", ghgFactor);
      setFieldValue("co2", totalCo2);
      //const targetField = document.querySelector('[name="units"]');
      //targetField.focus();
      //setFieldValue("material_id", option.id);
      setFieldValue("material", materialName);
      //setFieldValue("resource_type", option.resource_type);

      setFieldValue("notes", JSON.stringify(option.decomposed_materials));
      //setFieldValue("accuracy", option.accuracy);
      setFieldValue("accuracy", accuracy);
      setFieldValue("uncertainty", option.uncertainty);
      setFieldValue("activityUUIDs", option.activityUUIDs);
      setFieldValue("resource_datasource", "ecoinvent");

      setFieldValue("decomposition_assumptions", decomposition_assumptions);
      setFieldValue
        ("quantification_assumptions", quantification_assumptions);
      setFieldValue("verification_results", verification_results);
      setFieldValue("activity_selection_reasoning", activity_selection_reasoning);
      setFieldValue("lca_classification", option.lca_classification);



    }
  };

  const handleEcoActivityNamesResponse = (response) => {
    console.log(response);
    const activityOptions = response.data.map((item) => ({
      value: item.activityName + " (" + item.geography + ")",
      label: item.activityName + " (" + item.geography + ")",
      //id: item._id,
      description: item.productInformation,
      uom: item.unit,
      ghgFactor: item.gwp ? item.gwp : 0,
      resource_datasource: "ecoinvent",
      activityUUIDs: [
        {
          datasource: "ecoinvent",
          activityName: item.activityName,
          activityUUID: item.activityUUID,
          activityUUID_APOS: item.activityUUID_APOS,
          activityUUID_consequential: item.activityUUID_consequential,
          activityUUID_en15804: item.activityUUID_en15804,
          unit_of_measurement: item.unit_of_measurement,
          search_term: nameV + " (" + descriptionV + ")",
        },
      ],
    }));

    setResourceOptions([...activityOptions]);
    //   const targetField = document.querySelector('[name="material"]');
    //    targetField.focus();
  };

  const fetchResource = () => {
    axiosFetch(
      {
        method: "get",
        url: "resource/list",
      },
      handleListResourceResponse
    );
  };
  const fetchProduct = () => {
    axiosFetch(
      {
        method: "get",
        url: "products/list",
      },
      handleListProductResponse
    );
  };

  // fetch current product
  const fetchCurrentProduct = () => {
    axiosFetch(
      {
        method: "get",
        url: "products/" + idV,
      },
      handleCurrentProductResponse
    );
  };

  const handleCurrentProductResponse = (response) => {
    console.log(response);
    if (response.data) {
      setLciaModel(response.data.lcia_model);
      setLciaMethod(response.data.lcia_method);
    }
  };

  useEffect(() => {
    if (
      selectedDatasource &&
      (selectedDatasource.value === "interimProduct" ||
        selectedDatasource.value === "finishedProduct")
    ) {
      fetchProduct();
    }
    if (selectedDatasource && selectedDatasource.value === "custom") {
      if (!resourcesLoaded) {
        fetchResource();
      }
    }
  }, [selectedDatasource?.value]);
  console.log('values.material == "new" ', values.material == "new", resourceOptions)
  useEffect(() => {
    setSelectedDatasource(datasource[0]);

    if (values.material == "new" || resourceOptions.length == 0) {
      setIsNewResource(true);
      //setFieldValue("is_new_resource", true);
    } else if (values.datasource === 'new') {
      setSelectedDatasource(datasource[4])
      setIsNewResource(true);
      setFieldValue('resource_datasource', values.datasource)
    } else {
      setIsNewResource(false);
      //setFieldValue("is_new_resource", false);
    }
  }, []);

  console.log('resourceOptions', resourceOptions)
  useEffect(() => {
    //if (idV && isCurrentProductFetched == false) {
    //fetchCurrentProduct();
    //setIsCurrentProductFetched(true);
    //}
    //if (values.material == "new" || resourceOptions.length == 0) {
    if (values.material == "new" || resourceOptions.length == 0) {
      setIsNewResource(true);
      //setFieldValue("is_new_resource", true);
    } else {
      setIsNewResource(false);
      //setFieldValue("is_new_resource", false);
    }

    if (
      values.material == "new" ||
      (selectedDatasource && selectedDatasource?.value == "ecoinvent")
    ) {
      setFieldValue("is_new_resource", true);
    } else {
      setFieldValue("is_new_resource", false);
    }

    if (selectedDatasource?.value === 'new' || selectedDatasource?.value === 'ecoinvent' && values.datasource === 'new') {
      setIsNewResource(true);
      setFieldValue("is_new_resource", true);
      setSelectedDatasource(datasource[4])
    }

    if (values.material && values.resource_datasource !== 'new') {
      console.log("here0");
      console.log(values);
      if (values.resource_datasource) {
        // if (values.resource_datasource == "ghg") {
        if (values.resource_datasource == "ecoinvent") {
          setSelectedDatasource(datasource[0]);
        } else {
          setSelectedDatasource(datasource[1]);
        }
      } else if (values.resource_type) {
        if (values.resource_type == "interim") {
          setSelectedDatasource(datasource[2]);
        } else if (values.resource_type == "finished") {
          setSelectedDatasource(datasource[3]);
        }
      } else {
        //setSelectedDatasource(datasource[4]);
      }
    }
  }, [values?.resource_type, values?.resource_datasource, values?.material, resourceOptions, resourceOptions?.length, selectedDatasource?.value, values.datasource]);
  //}, [values.resource_type, values.material, resourceOptions, values.resource_datasource]);
  useEffect(() => {
    if (values.ghgFactor && values.units) {
      // check if ghgFactor and units are actually valid numbers
      const ghgFactorNum = parseFloat(values.ghgFactor);
      const unitsNum = parseFloat(values.units);

      if (!isNaN(ghgFactorNum) && !isNaN(unitsNum)) {
        setFieldValue("co2", ghgFactorNum * unitsNum);
      }
    }
  }, [values.ghgFactor, values.units]);

  const calculateCO2ByGHG = (event) => {
    setFieldValue("units", event.target.value);
    setFieldValue("co2", event.target.value * (values.ghgFactor ? values.ghgFactor : 0));
  };
  const handleDatasourceChange = (option) => {
    console.log("Datasource changed", option);
    clearComponents();
    setFieldValue("resource_datasource", option.value);
    setSelectedDatasource(option);
    if (option.value != "new") {
      setIsNewResource(false);
      setFieldValue("is_new_resource", false);
    }
    if (option.value == "ecoinvent") {
      setResourceOptions([]);
    }
    if (option.value == "ghg") {
      setResourceOptions([...resourcesGhg]);
    } else if (option.value == "custom") {
      setResourceOptions([...resourcesCustom]);
    } else if (option.value == "new") {
      setIsNewResource(true);
      setFieldValue("is_new_resource", true);
    } else if (option.value == "interimProduct") {
      setResourceOptions([...interimProducts]);
      setFieldValue("is_new_resource", false);
      setIsNewResource(false);
    } else if (option.value == "finishedProduct") {
      setResourceOptions([...finishedProducts]);
      setIsNewResource(false);
      setFieldValue("is_new_resource", false);
    }
  };

  const clearComponents = () => {
    setFieldValue("is_new_resource", false);
    //setFieldValue("units", "");
    setFieldValue("co2", "");
    //setFieldValue("uom", "");
    setFieldValue("scope1", "");
    setFieldValue("scope2", "");
    setFieldValue("scope3", "");
    setFieldValue("stage1", "");
    setFieldValue("stage2", "");
    setFieldValue("stage3", "");
    setFieldValue("stage4", "");
    setFieldValue("stage5", "");
    //setFieldValue("description", "");
    setFieldValue("ghgFactor", "");
    setFieldValue("material_id", "");
    setFieldValue("material", "");
    setFieldValue("notes", "");
    setFieldValue("accuracy", "");
    setFieldValue("uncertainty", "");

    setFieldValue("decomposition_assumptions", "");
    setFieldValue
      ("quantification_assumptions", "");
    setFieldValue("verification_results", "");
    setFieldValue("activity_selection_reasoning", "");

    setSelectedMaterial(null);
  };
  const handleMaterial = (option) => {
    console.log("option", option);
    if (option.value == "new" || option.resource_datasource == "ecoinvent") {
      setFieldValue("is_new_resource", true);
      if (option.value == "new") {
        clearComponents();
        setIsNewResource(true);
        setFieldValue("resource_datasource", "custom");
      }
    } else {
      setIsNewResource(false);
      setFieldValue("is_new_resource", false);
    }

    setFieldValue("resource_datasource", option.resource_datasource);

    setFieldValue("uom", option.uom);

    setFieldValue("scope1", option.scope1);
    setFieldValue("scope2", option.scope2);
    setFieldValue("scope3", option.scope3);
    setFieldValue("stage1", option.stage1);
    setFieldValue("stage2", option.stage2);
    setFieldValue("stage3", option.stage3);
    setFieldValue("stage4", option.stage4);
    setFieldValue("stage5", option.stage5);
    console.log('option.description', option.description)
    if (option.description) {
      setFieldValue("description", option.description);
    }
    setFieldValue("ghgFactor", option.ghgFactor);
    const targetField = document.querySelector('[name="units"]');
    setFieldValue("material_id", option.id);
    setFieldValue("id", option.id);
    setFieldValue("material", option.value);

    setSelectedMaterial(option);
    if (option.resource_type) {
      setFieldValue("resource_type", option.resource_type);
    }
    if (option.accuracy) {
      setFieldValue("accuracy", option.accuracy);
    }
    if (option.uncertainty) {
      setFieldValue("uncertainty", option.uncertainty);
    }
    setFieldValue("activityUUIDs", option.activityUUIDs);

    setFieldValue("decomposition_assumptions", option.decomposition_assumptions);
    setFieldValue
      ("quantification_assumptions", option.quantification_assumptions);
    setFieldValue("verification_results", option.verification_results);
    setFieldValue("activity_selection_reasoning", option.activity_selection_reasoning);

    console.log("material value: " + option.value);
    targetField.focus();
  };

  function prettyPrintArray(value) {
    if (Array.isArray(value)) {
      return value.map(item => `- ${item}`).join("\n");
    }
    return value;
  }
  function prettyPrintJSONParts(value) {
    if (value) {
      try {
        // Check if the entire value is a single JSON string
        try {
          const wholeJson = JSON.parse(value);
          return JSON.stringify(wholeJson, null, 2);
        } catch (e) {
          // If parsing as a whole JSON fails, continue to split by lines
        }

        // Split the value into parts by newline
        const parts = value.split("\n");
        // Try to pretty print each part as JSON
        const prettyParts = parts.map((part) => {
          try {
            // Try to parse the part as JSON
            const json = JSON.parse(part);
            // If parsing was successful, pretty print the JSON data
            return JSON.stringify(json, null, 2);
          } catch (error) {
            // If parsing failed, return the original part
            return part;
          }
        });
        // Join the pretty printed parts back together with newlines
        return prettyParts.join("\n");
      } catch (error) {
        return value;
      }
    } else {
      return value;
    }
  }

  console.log('isNewResource', isNewResource)

  return (
    <SoftBox>
      {loading && <LoaderSpinner />}
      <SoftTypography mt={1} variant="h5">
        Resource Information
      </SoftTypography>
      <SoftBox mb={1.5}>
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Datasource
          </SoftTypography>
        </SoftBox>
        <SoftSelect
          isSearchable={false}
          value={selectedDatasource}
          //defaultValue={datasource[0]}
          options={datasource}
          onChange={handleDatasourceChange}
        />
      </SoftBox>
      <SoftBox mt={1.625}>
        <SoftBox>
          <SoftButton
            disabled={!nameV || (selectedDatasource && selectedDatasource.value != "ecoinvent")}
            variant="gradient"
            color="primary"
            onClick={() => fetchEcoActivityNames(nameV, descriptionV, geographyV)}
          >
            Copilot Search
          </SoftButton>
          <SoftButton
            style={{ marginLeft: "10px" }}
            disabled={
              !nameV ||
              !uomV ||
              !unitsV ||
              (selectedDatasource && selectedDatasource.value != "ecoinvent")
            }
            variant="gradient"
            color="primary"
            onClick={() => fetchEcoDecomposedMaterials(product_idV, nameV, descriptionV, unitsV, uomV, geographyV)}
          >
            Copilot Compose
          </SoftButton>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                rows={1}
              />
              {!isNewResource && (
                <SoftBox mb={1.5}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Material
                    </SoftTypography>
                  </SoftBox>
                  {console.log('selectedMaterial', selectedMaterial)}
                  <SoftSelect
                    name={material.name}
                    onChange={handleMaterial}
                    value={selectedMaterial}
                    options={resourceOptions}
                    isSearchable
                  />
                </SoftBox>
              )}
              {isNewResource && (
                <FormField
                  type={material.type}
                  label={material.label}
                  name={material.name}
                  value={materialV}
                  placeholder={material.placeholder}
                  rows={1}
                />
              )}
              {(resourceTypeV == "interim" || resourceTypeV == "finished") && (
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    fullWidth
                    onClick={() =>
                      window.open(
                        location.protocol +
                        "//" +
                        location.host +
                        "/products/canvas/" +
                        Number(idV) +
                        "?product_name=" +
                        materialV,
                        "_blank",
                        "noreferrer"
                      )
                    }
                  // disabled={isSubmitting}
                  >
                    Navigate to child product 🡥
                  </SoftButton>
                </SoftBox>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={uom.type}
                label={uom.label}
                name={uom.name}
                value={uomV}
                placeholder={uom.placeholder}
                multiline
                rows={1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={ghgFactor.type}
                label={ghgFactor.label}
                name={ghgFactor.name}
                value={ghgFactorV}
                placeholder={ghgFactor.placeholder}
                multiline
                rows={1}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={units.type}
                label={units.label}
                name={units.name}
                value={unitsV}
                placeholder={units.placeholder}
                multiline
                rows={1}
                onChange={calculateCO2ByGHG}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={co2.type}
                label={co2.label}
                name={co2.name}
                value={co2V}
                placeholder={co2.placeholder}
                multiline
                rows={1}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={geography.type}
                label={geography.label}
                name={geography.name}
                value={geographyV}
                placeholder={geography.placeholder}
                multiline
                rows={1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={accuracy.type}
                label={accuracy.label}
                name={accuracy.name}
                value={accuracyV}
                placeholder={accuracy.placeholder}
                multiline
                rows={1}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={notes.type}
                label={notes.label}
                name={notes.name}
                placeholder={notes.placeholder}
                value={prettyPrintJSONParts(notesV)}
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={decomposition_assumptions.type}
                label={decomposition_assumptions.label}
                name={decomposition_assumptions.name}
                placeholder={decomposition_assumptions.placeholder}
                value={
                  prettyPrintArray(decomposition_assumptionsV)}
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={quantification_assumptions.type}
                label={quantification_assumptions.label}
                name={quantification_assumptions.name}
                placeholder={quantification_assumptions.placeholder}
                value={prettyPrintArray(quantification_assumptionsV)}
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={verification_results.type}
                label={verification_results.label}
                name={verification_results.name}
                placeholder={verification_results.placeholder}
                value={prettyPrintArray(verification_resultsV)}
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={activity_selection_reasoning.type}
                label={activity_selection_reasoning.label}
                name={activity_selection_reasoning.name}
                placeholder={activity_selection_reasoning.placeholder}
                value={prettyPrintArray(activity_selection_reasoningV)}
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
        </SoftBox>





        {/* <SoftBox mt={2} bgColor="cornsilk" borderRadius="lg" shadow="lg" opacity={2} p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}></Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
              <FormField
                type={scope1.type}
                label={scope1.label}
                name={scope1.name}
                value={scope1V}
                placeholder={scope1.placeholder}
                error={errors.scope1 && touched.scope1}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={scope2.type}
                label={scope2.label}
                name={scope2.name}
                value={scope2V}
                placeholder={scope2.placeholder}
                error={errors.scope2 && touched.scope2}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                type={scope3.type}
                label={scope3.label}
                name={scope3.name}
                value={scope3V}
                placeholder={scope3.placeholder}
                error={errors.scope3 && touched.scope3}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mt={2} bgColor="cornsilk" borderRadius="lg" shadow="lg" opacity={2} p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={2.3}>
              <FormField
                type={stage1.type}
                label={stage1.label}
                name={stage1.name}
                value={stage1V}
                placeholder={stage1.placeholder}
                error={errors.stage1 && touched.stage1}
              />
            </Grid>
            <Grid item xs={12} sm={2.3}>
              <FormField
                type={stage2.type}
                label={stage2.label}
                name={stage2.name}
                value={stage2V}
                placeholder={stage2.placeholder}
                error={errors.stage2 && touched.stage2}
              />
            </Grid>
            <Grid item xs={12} sm={2.3}>
              <FormField
                type={stage3.type}
                label={stage3.label}
                name={stage3.name}
                value={stage3V}
                placeholder={stage3.placeholder}
                error={errors.stage3 && touched.stage3}
              />
            </Grid>
            <Grid item xs={12} sm={2.3}>
              <FormField
                type={stage4.type}
                label={stage4.label}
                name={stage4.name}
                value={stage4V}
                placeholder={stage4.placeholder}
                error={errors.stage4 && touched.stage4}
              />
            </Grid>
            <Grid item xs={12} sm={2.3}>
              <FormField
                type={stage5.type}
                label={stage5.label}
                name={stage5.name}
                value={stage5V}
                placeholder={stage5.placeholder}
                error={errors.stage5 && touched.stage5}
              />
            </Grid>
          </Grid>
        </SoftBox> */}
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
