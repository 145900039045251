import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { green, red, amber } from "@mui/material/colors";
import { formatDate } from "utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


function ComplexProjectCard({
    image,
    productName,
    createdAt,
    updatedAt,
    user,
    customer,
    productId,
    productType,
    description,
    numberResourcesProcesses,
    emission,
    coproductCo2,
    wasteCo2,
    copilotStatus,
    copilotCompletionTime,
    accuracy,
    dropdown,
    actionCellComponent,
    members,

}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    // Custom styles for ComplexProjectCard
    const renderMembers = members.map((member, key) => {
        const memberKey = `member-${key}`;

        return (
            <SoftAvatar
                key={memberKey}
                src={member}
                alt="member profile"
                size="xs"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",

                    "&:not(:first-of-type)": {
                        ml: -1.25,
                    },

                    "&:hover, &:focus": {
                        zIndex: "10",
                    },
                })}
            />
        );
    });

    return (
        <Card >
            <SoftBox p={2}>
                <SoftBox display="flex" alignItems="center">
                    <SoftAvatar
                        src={image}
                        alt={productName}
                        size="xl"
                        variant="rounded"
                        bgColor="dark"
                        sx={{ p: 1 }}
                    />
                    <SoftBox ml={2} lineHeight={0}>
                        <SoftBox mb={1} lineHeight={0}>
                            <SoftTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                                {productName}
                            </SoftTypography>
                        </SoftBox>
                        {members.length > -1 ? <SoftBox display="flex">{renderMembers}</SoftBox> : null}
                    </SoftBox>
                    <SoftBox ml="auto">
                        <CardActions disableSpacing>
                            {actionCellComponent}
                        </CardActions>
                    </SoftBox>
                </SoftBox>
                <SoftBox my={2} lineHeight={1} style={{ minHeight: "50px" }}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {description ? description : "No description available..."}
                    </SoftTypography>
                </SoftBox>
                <Divider />
                <SoftBox my={2}>
                    <SoftBox display="flex" alignItems="center">
                        <Icon>fingerprint</Icon>
                        <Typography variant="overline" display="block" ml={1} sx={{ mt: 1 }}>
                            ID: {productId}
                        </Typography>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                        <Icon>category</Icon>
                        <Typography variant="overline" display="block" ml={1} sx={{ mt: 1 }}>
                            Type: {productType}
                        </Typography>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                        <Icon>person</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            User: {user}
                        </Typography>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                        <Icon>business</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            Customer: {customer}
                        </Typography>
                    </SoftBox>

                    <SoftBox display="flex" alignItems="center">
                        <Icon>co2</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            GWP (kgCo2e): {emission ? emission : 0} | CO: {coproductCo2 ? coproductCo2 : 0.0} | W: {wasteCo2 ? wasteCo2 : 0.0}
                        </Typography>
                    </SoftBox>

                    {/*                     <SoftBox display="flex" alignItems="center">
                        <Icon>co2</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            Coproduct CO2e: {coproductCo2 ? coproductCo2 : 0} kgCo2e
                        </Typography>
                    </SoftBox>

                    <SoftBox display="flex" alignItems="center">
                        <Icon>co2</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            Waste CO2e: {wasteCo2 ? wasteCo2 : 0} kgCo2e
                        </Typography>
                    </SoftBox>
 */}
                    <SoftBox display="flex" alignItems="center">
                        <Icon>stars</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            Accuracy: {accuracy && accuracy != "N/A" ? accuracy * 100 : 0} %
                        </Typography>
                    </SoftBox>

                    <SoftBox display="flex" alignItems="center">
                        <Icon>timer</Icon>
                        <Typography variant="overline" display="block" ml={1}>
                            Copilot Completion Time: {copilotCompletionTime && copilotCompletionTime != "N/A" ? copilotCompletionTime : 0} minutes
                        </Typography>
                    </SoftBox>



                </SoftBox>

                <SoftBox my={2} style={{ minHeight: "30px" }}>
                    {copilotStatus && copilotStatus != "N/A" && (
                        <Chip
                            label={`Copilot Status: ${copilotStatus}`}
                            size="small"
                            sx={{
                                mt: 1,
                                backgroundColor: copilotStatus === "done" ? green[500] : copilotStatus === "preparing" ? amber[500] : red[500],
                                color: "#FFFFFF",
                            }}
                        />
                    )}
                </SoftBox>
                <Divider />
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    {createdAt ? (
                        <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {(createdAt)}
                            </SoftTypography>
                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                                Created At
                            </SoftTypography>
                        </SoftBox>
                    ) : null}
                    {updatedAt ? (
                        <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {(updatedAt)}
                            </SoftTypography>
                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                                Last Updated
                            </SoftTypography>
                        </SoftBox>
                    ) : null}
                </SoftBox>
            </SoftBox>
        </Card>
    );

    /*
    return (
        <Card >
            <CardMedia component="img" height="140" image={image || "/static/default-product.jpg"} alt={productName} />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {productName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
                <Typography variant="overline" display="block" sx={{ mt: 1 }}>
                    Type: {productType}
                </Typography>
                <Typography variant="overline" display="block">
                    User: {user}
                </Typography>
                <Typography variant="overline" display="block">
                    Customer: {customer}
                </Typography>
                <Typography variant="overline" display="block">
                    CO2e: {emission} kg, Coproduct CO2e: {coproductCo2} kg, Waste CO2e: {wasteCo2} kg
                </Typography>
                <Chip
                    label={`Copilot Status: ${copilotStatus}`}
                    size="small"
                    sx={{
                        mt: 1,
                        backgroundColor: copilotStatus === "success" ? green[500] : copilotStatus === "preparing" ? amber[500] : red[500],
                        color: "white",
                    }}
                />
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                    Created: {formatDate(createdAt)}, Updated: {formatDate(updatedAt)}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Tooltip title="More actions">
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {dropdown.map((item, index) => (
                        <MenuItem key={index} onClick={() => { handleClose(); item.action(); }}>{item.label}</MenuItem>
                    ))}
                </Menu>
            </CardActions>
        </Card>
    );
    */
}

ComplexProjectCard.propTypes = {
    image: PropTypes.string,
    productName: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    user: PropTypes.string,
    customer: PropTypes.string,
    productId: PropTypes.string,
    productType: PropTypes.string,
    description: PropTypes.string,
    numberResourcesProcesses: PropTypes.string,
    emission: PropTypes.string,
    coproductCo2: PropTypes.string,
    wasteCo2: PropTypes.string,
    copilotStatus: PropTypes.string,
    copilotCompletionTime: PropTypes.string,
    accuracy: PropTypes.string,
    dropdown: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            action: PropTypes.func,
        })
    ),
    actionCellComponent: PropTypes.element,
};

export default ComplexProjectCard;
