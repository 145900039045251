import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse";
// formik components
import { Formik, Form } from "formik";
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
import BasicInfo from "layouts/product/new-product/components/BasicInfo";
import SoftTypography from "components/SoftTypography";

import validations from "layouts/product/new-product/schemas/validations";
import form from "layouts/product/new-product/schemas/form";
import initialValues from "layouts/product/new-product/schemas/initialValues";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import VisibilityControl from "layouts/common/visibilityControl";

function getSteps() {
  return ["Basic"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      if (formData.values) {
        return <BasicInfo formData={formData} />;
      } else {
        return null;
      }
    default:
      return null;
  }
}

function EditProduct() {
  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const steps = getSteps();
  const { id } = useParams();
  const { formId, formField } = form;

  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();
  const handleBack = () => setActiveStep(activeStep - 1);
  const [formValue, setFormValue] = useState(null);

  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };
  const canChangeVisibility = useMemo(() => {
    if (formValue && formValue.security_user_owner_email) {
      const currentUserRole = localStorage.getItem("role");
      const currentUserEmail = localStorage.getItem("userEmail");
      if (
        currentUserRole === "superAdmin" ||
        currentUserRole === "organisationAdmin" ||
        formValue.security_user_owner_email === currentUserEmail
      ) {
        return true;
      }
    }
    return false;
  }, [formValue]);
  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        navigate(`/products`);
        //navigate(`/products/new-canvas/${response.data[0].proID}`);
      }
    };
    const handleCopilotResponseCallback = (response) => {
      if (response.status == "success") {
        toast.success("Copilot's Product Creation Initiated Successfully!");
        navigate(`/products`);
        //navigate(`/products/new-canvas/${response.data[0].proID}`);
      }
    };

    let combinedData = {
      product: {
        name: values.productName,
        description: values.description,
        transport: values.transport,
        transport_mode: values.transportMode?.value || null,
        purpose: values.purpose,
        distance: values.travelDistance,
        service_life_time: values.lifeTime,
        supplier: values.supplier?.value || null,
        ID: id,
        proID: id,
        resource_type: values.productType,

        security_isPublic: visibilityOptions.security_isPublic,
        security_organisation_visibility: visibilityOptions.security_organisation_visibility,

        image_url: values.image_url,
        customer_organisation: values.customer_organisation,
        customer_contact: values.customer_contact,
        customer_email: values.customer_email,
        reporting_period: values.reporting_period,
        revision: values.revision,
        disclosing_gases: values.disclosing_gases,
        unit_of_analysis: values.unit_of_analysis,
        lcia_method: values.lcia_method,
        lcia_model: values.lcia_model,
      },
      nodes: [],
      proID: values.productUID, // Assuming productUID is the product ID
    };
    // Append product information to combinedData.product
    Object.keys(values).forEach((key) => {
      if (key !== "csvFile") {
        combinedData.product[key] = values[key];
      }
    });

    // Check if CSV file is uploaded
    if (values.csvFile) {
      try {
        // Convert CSV to JSON and add to combinedData.nodes
        const csvJson = await convertCsvToJson(values.csvFile);
        combinedData.nodes = csvJson;

        axiosFetch(
          {
            method: "post",
            url: "products-copilot/add",
            requestConfig: combinedData,
          },
          handleCopilotResponseCallback
        );
      } catch (error) {
        console.error("Error parsing CSV:", error);
        toast.error(error.response.data.error);
      }
    } else {
      axiosFetch(
        {
          method: "post",
          url: "products/update",
          requestConfig: combinedData.product,
        },
        handleResponseCallback
      );
    }

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const convertCsvToJson = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  /*
  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        navigate(`/products`);
      }
    };

    axiosFetch(
      {
        method: "post",
        url: "products/update",
        requestConfig: {
          name: values.productName,
          description: values.description,
          transport: values.transport,
          transport_mode: values.transportMode?.value || null,
          purpose: values.purpose,
          distance: values.travelDistance,
          service_life_time: values.lifeTime,
          supplier: values.supplier?.value || null,
          ID: id,
          resource_type: values.productType,

          security_isPublic: visibilityOptions.security_isPublic,
          security_organisation_visibility: visibilityOptions.security_organisation_visibility,

          image_url: values.image_url,
          customer_organisation: values.customer_organisation,
          customer_contact: values.customer_contact,
          customer_email: values.customer_email,
          reporting_period: values.reporting_period,
          revision: values.revision,
          disclosing_gases: values.disclosing_gases,
          unit_of_analysis: values.unit_of_analysis,
        },
      },
      handleResponseCallback
    );
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };
  */

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  const handleResponse = (response) => {
    let formattedResponseData = response?.data
      ? {
          productUID: response.data._id || null,
          productName: response.data.name || null,
          transport: response.data?.transport || null,
          description: response.data?.description || null,
          transportMode: response.data?.transport_mode || null,
          lifeTime: response.data?.service_life_time || null,
          travelDistance: response.data?.distance || null,
          purpose: response.data?.purpose || null,
          supplier: response.data?.supplier || null,
          productType: response.data?.resource_type || null,

          security_user_owner_id: response.data?.security_user_owner_id || null,
          security_user_owner_email: response.data?.security_user_owner_email || null,
          security_isPublic: response.data?.security_isPublic || null,
          security_organisation_visibility: response.data?.security_organisation_visibility || null,

          image_url: response.data?.image_url || null,
          customer_organisation: response.data?.customer_organisation || null,
          customer_contact: response.data?.customer_contact || null,
          customer_email: response.data?.customer_email || null,
          reporting_period: response.data?.reporting_period || null,
          revision: response.data?.revision || null,
          disclosing_gases: response.data?.disclosing_gases || null,
          unit_of_analysis: response.data?.unit_of_analysis || null,
          lcia_method: response.data?.lcia_method || null,
          lcia_model: response.data?.lcia_model || null,
        }
      : {};
    const responseObject = Object.keys(formattedResponseData).reduce((obj, key) => {
      return {
        ...obj,
        [key]: formattedResponseData[key],
      };
    }, {});
    setFormValue(formattedResponseData);
    setVisibilityOptions({
      security_isPublic: responseObject.security_isPublic,
      security_organisation_visibility: responseObject.security_organisation_visibility,
    });
  };
  const fetchProduct = () => {
    axiosFetch(
      {
        method: "get",
        url: "products/" + id,
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid mb={2} item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={3}>
                <VisibilityControl
                  onVisibilityChange={handleVisibilityChange}
                  initialVisibility={{
                    security_isPublic: visibilityOptions.security_isPublic,
                    security_organisation_visibility:
                      visibilityOptions.security_organisation_visibility,
                  }}
                  disabled={!canChangeVisibility}
                />
              </SoftBox>
            </Card>
          </Grid>

          <Grid id="product-information-container" item xs={12} lg={8}>
            <Formik
              initialValues={formValue}
              validationSchema={validations}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </SoftButton>
                          )}
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                          >
                            {isLastStep ? "Save" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProduct;
