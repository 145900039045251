
import * as Yup from "yup";
import checkout from "layouts/product/new-product/schemas/form";

const {
  formField: { productName }
} = checkout;


const validations =
  Yup.object().shape({
    [productName.name]: Yup.string().required(productName.errorMsg),
  });



export default validations;
