import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "apis/apiV1";

const initialState = {
  isLoading: false,
  signUpValues: {},
  loginValues: {},
};

export const handleSignUp = createAsyncThunk("post/signUp", async (payload) => {
  const res = await axiosInstance.post("signup", payload).then((response) => response.data);

  return res;
});

export const handleSignIn = createAsyncThunk("post/signIn", async (payload) => {
  const res = await axiosInstance
    .post("login", {
      email: payload.email,
      password: payload.password,
      role: payload.role,
    })
    .then((response) => response.data);

  return res;
});

const loginReducer = createSlice({
  name: "loginReducer",
  initialState: initialState,
  reducers: {
    resetLoginValues(state) {
      state.loginValues = {};
    },
  },
  extraReducers: {
    [handleSignUp.fulfilled]: (state, action) => {
      state.signUpValues = action.payload;
    },
    [handleSignIn.fulfilled]: (state, action) => {
      state.loginValues = action.payload;
    },
  },
});

export const { resetLoginValues } = loginReducer.actions;
export default loginReducer.reducer;
