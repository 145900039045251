import * as Yup from "yup";
import checkout from "layouts/organization/new-organization/schemas/form";

const {
  formField: { organizationName, comments },
} = checkout;

const validations = Yup.object().shape({
  [organizationName.name]: Yup.string().required(organizationName.errorMsg),
  [comments.name]: Yup.string().required(comments.errorMsg),
});

export default validations;
