import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

// Soft UI Dashboard PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MiniInfoCard from "examples/Cards/InfoCards/MiniInfoCard";

// Soft UI Dashboard PRO components
import SoftTypography from "components/SoftTypography";

// see this: https://www.creative-tim.com/learning-lab/material-ui-marketplace/info-cards/soft-ui-dashboard/
const AverageCo2Card = ({ data }) => {
  // Filter out data items without co2_summary
  const filteredData = data.filter((item) => item.co2_summary);

  // Calculate average CO2
  const totalCo2 = filteredData.reduce((sum, item) => sum + item.co2_summary.co2, 0);
  const averageCo2 = totalCo2 / filteredData.length;

  return (
    <MiniInfoCard
      icon="co2"
      //value={averageCo2.toFixed(3)}
      title={averageCo2.toFixed(3) + " Co2e"}
      description="Product Emissions (AVG)"
      //description="Average Co2e"
      color="info"
      //count={averageCo2}
      //suffix="Co2e"
    />
  );
};

const AverageCoproductCo2Card = ({ data }) => {
  // Filter out data items without co2_summary
  const filteredData = data.filter((item) => item.co2_summary);

  // Calculate average coproduct CO2
  const totalCoproductCo2 = filteredData.reduce(
    (sum, item) => sum + item.co2_summary.coproduct_co2,
    0
  );
  const averageCoproductCo2 = totalCoproductCo2 / filteredData.length;

  return (
    <MiniInfoCard
      icon="co2"
      title={averageCoproductCo2.toFixed(3) + " Co2e"}
      description="Co-Product Emissions (AVG)"
      //description="Average Co2e"
      color="success"
      /*
      count={averageCoproductCo2}
      suffix="Co2e"
      color="success"
      title="Co-Product Emissions" 
      //description="all devices"
      */
    />
  );
};

const AverageWasteCo2Card = ({ data }) => {
  // Filter out data items without co2_summary
  const filteredData = data.filter((item) => item.co2_summary);

  // Calculate average waste CO2
  const totalWasteCo2 = filteredData.reduce((sum, item) => sum + item.co2_summary.waste_co2, 0);
  const averageWasteCo2 = totalWasteCo2 / filteredData.length;

  return (
    <MiniInfoCard
      icon="co2"
      title={averageWasteCo2.toFixed(3) + " Co2e"}
      description="Waste Emissions (AVG)"
      //description="Average Co2e"
      color="warning"
      /*
      count={averageWasteCo2}
      suffix="Co2e"
      color="warning"
      title="Waste Emissions" 
      //description="all devices"
      */
    />
  );
};

const AverageResourceCo2Card = ({ data }) => {
  // Filter out data items without co2_summary
  const filteredData = data.filter((item) => item.co2_summary);

  const totalResourceCo2 = filteredData.reduce(
    (sum, item) => sum + (item.co2_summary.resource_co2 ? item.co2_summary.resource_co2 : 0),
    0
  );
  const averageResourceCo2 = totalResourceCo2 / filteredData.length;

  return (
    <MiniInfoCard
      icon="co2"
      title={averageResourceCo2.toFixed(3) + " Co2e"}
      description="Total Resource Emissions (AVG)"
      color="error"
    />
  );
};

const AverageProcessCo2Card = ({ data }) => {
  // Filter out data items without co2_summary
  const filteredData = data.filter((item) => item.co2_summary);

  // Calculate average process CO2
  const totalProcessCo2 = filteredData.reduce(
    (sum, item) => sum + (item.co2_summary.process_co2 ? item.co2_summary.process_co2 : 0),
    0
  );
  const averageProcessCo2 = totalProcessCo2 / filteredData.length;

  return (
    <MiniInfoCard
      icon="co2"
      title={averageProcessCo2.toFixed(3) + " Co2e"}
      description="Total Process Emissions (AVG)"
      color="secondary"
    />
  );
};

const AverageNumberOfNodesCard = ({ data }) => {
  // Filter out data items without co2_summary
  const filteredData = data.filter((item) => item.co2_summary);

  // Calculate total number of nodes
  const totalNumberOfNodes = filteredData.reduce(
    (sum, item) => sum + (item.co2_summary.number_resources + item.co2_summary.number_processes),
    0
  );
  const averageNumberOfNodes = totalNumberOfNodes / filteredData.length;

  return (
    <MiniInfoCard
      icon="hub"
      title={averageNumberOfNodes.toFixed(3)}
      description="Number of Nodes (AVG)"
      color="primary"
    />
  );
};

export {
  AverageCo2Card,
  AverageCoproductCo2Card,
  AverageWasteCo2Card,
  AverageResourceCo2Card,
  AverageProcessCo2Card,
  AverageNumberOfNodesCard,
};
