import { useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse";
// formik components
import { Formik, Form } from "formik";
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
import BasicInfo from "layouts/product/new-product/components/BasicInfo";

import validations from "layouts/product/new-product/schemas/validations";
import form from "layouts/product/new-product/schemas/form";
import initialValues from "layouts/product/new-product/schemas/initialValues";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

import VisibilityControl from "layouts/common/visibilityControl";

function getSteps() {
  return ["Basic"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <BasicInfo formData={formData} />;
    default:
      return null;
  }
}

function NewEmission() {
  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const navigate = useNavigate();

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        navigate(
          `/products/canvas/${response.data.proID}?product_name=${response.data.name}&new=true`
        );
        //navigate(`/products/new-canvas/${response.data[0].proID}`);
      }
    };
    const handleCopilotResponseCallback = (response) => {
      if (response.status == "success") {
        toast.success("Copilot's Product Creation Initiated Successfully!");
        navigate(`/products`);
      }
    };

    let combinedData = {
      product: {
        name: values.productName,
        description: values.description,
        //transport: values.transport,
        //transport_mode: values.transportMode.value,
        purpose: values.purpose,
        //distance: values.travelDistance,
        service_life_time: values.lifeTime,
        //supplier: values.supplier.value,
        resource_type: values.productType ? values.productType : "interim",

        security_isPublic: visibilityOptions.security_isPublic,
        security_organisation_visibility: visibilityOptions.security_organisation_visibility,

        image_url: values.image_url,
        customer_organisation: values.customer_organisation,
        customer_contact: values.customer_contact,
        customer_email: values.customer_email,
        reporting_period: values.reporting_period,
        revision: values.revision,
        disclosing_gases: values.disclosing_gases,
        unit_of_analysis: values.unit_of_analysis,
        lcia_method: values.lcia_method,
        lcia_model: values.lcia_model,
      },
      nodes: [],
      proID: values.productUID, // Assuming productUID is the product ID
    };
    // Append product information to combinedData.product
    Object.keys(values).forEach((key) => {
      if (key !== "csvFile") {
        combinedData.product[key] = values[key];
      }
    });

    // Check if CSV file is uploaded
    if (values.csvFile) {
      try {
        // Convert CSV to JSON and add to combinedData.nodes
        const csvJson = await convertCsvToJson(values.csvFile);
        combinedData.nodes = csvJson;

        axiosFetch(
          {
            method: "post",
            url: "products-copilot/add",
            requestConfig: combinedData,
          },
          handleCopilotResponseCallback
        );
      } catch (error) {
        console.error("Error parsing CSV:", error);
        toast.error(error.response.data.error);
      }
    } else {
      axiosFetch(
        {
          method: "post",
          url: "products/add",
          requestConfig: combinedData.product,
        },
        handleResponseCallback
      );
    }

    actions.setSubmitting(false);
    //actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const convertCsvToJson = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid mb={1} container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={3}>
                <VisibilityControl
                  onVisibilityChange={handleVisibilityChange}
                  initialVisibility={{
                    security_isPublic: visibilityOptions.security_isPublic,
                    security_organisation_visibility:
                      visibilityOptions.security_organisation_visibility,
                  }}
                  disabled={false}
                />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </SoftButton>
                          )}
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                          >
                            {isLastStep ? "Save" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewEmission;
