const form = {
  formId: "signup-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Name",
      errorMsg: "Name is required.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Email",
      errorMsg: "Email is required.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
      errorMsg: "Password is required.",
    },
    position: {
      name: "position",
      label: "position ",
      type: "text",
      placeholder: "Position",
      errorMsg: "Position is required.",
    },
    company: {
      name: "company",
      label: "company ",
      type: "text",
      placeholder: "Company",
      errorMsg: "company is required.",
    },
    phone: {
      name: "phone",
      label: "phone ",
      type: "text",
      placeholder: "Phone",
      errorMsg: "phone is required.",
    },
    location: {
      name: "location",
      label: "location ",
      type: "text",
      placeholder: "Location",
      errorMsg: "location is required.",
    },
  },
};

export default form;
