import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, IconButton, Tooltip } from '@mui/material';
import Icon from '@mui/material/Icon';

import SoftBox from 'components/SoftBox';
import ConfirmDialog from 'components/ConfirmDialog';

function ActionCell(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showConfirm, setShowConfirm] = useState(false);
  const [prodId, setProdId] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleConfirm = () => {
    props.deleteHandler(prodId);
    setShowConfirm(false);
  };

  const deleteProduct = (id) => {
    setProdId(id);
    setShowConfirm(true);
    handleClose(); // Close menu when action is initiated
  };

  const jsonExportProduct = (id) => {
    props.jsonExportHandler(id);
    handleClose(); // Close menu when action is initiated
  };

  const csvExportProduct = (id) => {
    props.csvExportHandler(id);
    handleClose(); // Close menu when action is initiated
  };

  return (
    <>
      {showConfirm && (
        <ConfirmDialog
          message="Are you sure you want to delete this record?"
          confirmLabel="Delete"
          cancelLabel="Cancel"
          open={showConfirm}
          onClose={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
      <SoftBox display="flex" alignItems="center">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component={Link} to={`/products/edit/${props.id}`}>
            Edit Product
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to={`/products/canvas/${props.id}?product_name=${props.name}`}>
            View/Edit Canvas
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to={`/products/analytics/${props.id}`}>
            View Product Analytics
          </MenuItem>
          <MenuItem onClick={() => jsonExportProduct(props.id)}>
            Export Product (JSON)
          </MenuItem>
          <MenuItem onClick={() => csvExportProduct(props.id)}>
            Export Product (CSV)
          </MenuItem>
          <MenuItem onClick={() => deleteProduct(props.id)}>
            Delete Product
          </MenuItem>
        </Menu>
      </SoftBox>
    </>
  );
}

export default ActionCell;
