import { useState, useEffect, useMemo } from "react";
import axios from "axios"; // Add this import for making API requests

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";
import AuthRoute from "router/AuthRoute";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo.png";
import brandCt from "assets/images/logo-ct.png";

//Routes
import EditAsset from "layouts/assets/edit-asset";
import EditFacility from "layouts/facility/edit-facility";
import EditEmission from "layouts/emission/edit-emission";
import EditProduct from "layouts/product/edit-product";
import SignUp from "layouts/login/sign-up";
import SignIn from "layouts/login/sign-in";
import EditUser from "layouts/user/edit-user";
import EditOrganization from "layouts/organization/edit-organization";

import HomeDashboard from "layouts/home";
import ProductAnalytics from "layouts/product/product-analytics";

import D3 from "layouts/pages/D3/index";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [token, setToken] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    setToken(localStorage.getItem("token"));
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<AuthRoute>{route.component}</AuthRoute>}
            key={route.key}
          />
        );
      }

      return null;
    });
  /*
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      // Or you can store the token in the React state
      // setToken(token);
    }
  }, [location.search]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (token) {
      localStorage.setItem("token", token);
      // Or you can store the token in the React state
      // setToken(token);
    }
  }, []);
  */

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      // width="3.5rem"
      // height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      {
        // <Icon fontSize="default" color="inherit">
        //   settings
        // </Icon>
      }
    </SoftBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />

        {layout === "dashboard" && token != null && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandCt={brandCt}
              //brandName="EMVIDE"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/default" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && token != null && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandCt={brandCt}
            //brandName="EMVIDE"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route
          path="*"
          element={
            <AuthRoute>
              <Navigate to="/home" />
            </AuthRoute>
          }
        />
        <Route
          path="/products/analytics/:productId"
          element={
            <AuthRoute>
              <ProductAnalytics />
            </AuthRoute>
          }
        />
        <Route
          path="/assets/edit/:id"
          element={
            <AuthRoute>
              <EditAsset />
            </AuthRoute>
          }
        />
        <Route
          path="/facilities/edit/:id"
          element={
            <AuthRoute>
              <EditFacility />
            </AuthRoute>
          }
        />
        <Route
          path="/emission-profiles/edit/:id"
          element={
            <AuthRoute>
              <EditEmission />
            </AuthRoute>
          }
        />
        <Route
          path="/products/canvas/:id"
          element={
            <AuthRoute>
              <D3 />
            </AuthRoute>
          }
        />
        <Route
          path="/products/edit/:id"
          element={
            <AuthRoute>
              <EditProduct />
            </AuthRoute>
          }
        />
        <Route
          path="/users/edit/:id"
          element={
            <AuthRoute>
              <EditUser />
            </AuthRoute>
          }
        />
        <Route
          path="/organisations/edit/:id"
          element={
            <AuthRoute>
              <EditOrganization />
            </AuthRoute>
          }
        />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
      </Routes>
      <ToastContainer />
    </ThemeProvider>
  );
}
