import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

// formik components
import { Formik, Form, setFieldValue } from "formik";
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// NewProduct page components
import BasicInfo from "layouts/resource/new-resource/components/BasicInfo";

import validations from "layouts/resource/new-resource/schemas/validations";
import form from "layouts/resource/new-resource/schemas/form";
import initialValues from "layouts/resource/new-resource/schemas/initialValues";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function getSteps() {
  return ["Basic"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <BasicInfo formData={formData} />;
    default:
      return null;
  }
}

function NewResource({ resource, ...props }) {
  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValue, setFormValue] = useState(null);
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        props.onUpdate(true);
        props.nodeData({ title: values.name, fillColor: "", outline: "" });
      }
    };
    if (values.is_new_resource) {
      /* this is wrong - not sure why i did it in the first place
      if (values.activityUUIDs) {
        // if this is just a singe profile, then update the activity with resource values
        if (values.activityUUIDs.length == 1) {
          values.activityUUIDs[0].units = values.units != undefined ? values.units : 0;
          values.activityUUIDs[0].accuracy = values.accuracy != undefined ? values.accuracy : 0.9;
          values.activityUUIDs[0].unit_of_measurement = values.uom;
        }
      }
        */

      axiosFetch(
        {
          method: "post",
          url: "resource/add",
          requestConfig: {
            name: values.name,
            material: values.material,
            description: values.description,
            ghgFactor: values.ghgFactor,
            uom: values.uom,
            co2: values.co2,
            scope1: values.scope1,
            scope2: values.scope2,
            scope3: values.scope3,
            stage1: values.stage1,
            stage2: values.stage2,
            stage3: values.stage3,
            stage4: values.stage4,
            stage5: values.stage5,
            eeiofactor: values.eeiofactor != undefined ? values.eeiofactor : 0,
            co2: values.co2,
            units: values.units != undefined ? values.units : 0,
            node_id: props.node,
            product_id: id,
            notes: values.notes,
            accuracy: values.accuracy,
            uncertainty: values.uncertainty,
            geography: values.geography || "Global",
            activityUUIDs: values.activityUUIDs,
            datasource: values.resource_datasource,
            decomposition_assumptions: values.decomposition_assumptions,
            quantification_assumptions: values.quantification_assumptions,
            verification_results: values.verification_results,
            activity_selection_reasoning: values.activity_selection_reasoning,
          },
        },
        handleResponseCallback
      );
    }
    // console.log('values', values)
    // return
    if (!values.is_new_resource) {
      axiosFetch(
        {
          method: "post",
          url: "saveproductresource",
          requestConfig: {
            resource_id: !(values.resource_type == "interim" || values.resource_type == "finished")
              ? values.material_id
              : null,
            child_product_id:
              values.resource_type == "interim" || values.resource_type == "finished"
                ? values.material_id
                : null,
            co2: values.co2,
            units: values.units != undefined ? values.units : null,
            node_id: props.node,
            product_id: id,
          },
        },
        handleResponseCallback
      );
    }

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleResponse = (response) => {
    if (response && response.data && !Array.isArray(response.data)) {
      let backData = response.data;
      response.data = [backData];
    }

    // { value: "interimProduct", label: "Interim Product" },
    // { value: "finishedProduct", label: "Finished Product" },
    let formattedResponseData = response?.data.map((responseData) => {
      const isInterimProduct = responseData.resource_type == "interim";
      const isFinishedProduct = responseData.resource_type == "finished";

      if (responseData.proID) {
        return {
          name: isInterimProduct || isFinishedProduct ? resource.title : responseData.name, //name for interim and finished products
          material: responseData.material ? responseData.material : responseData.name,
          description: responseData.description,
          ghgFactor: responseData.emission,
          uom: "unit",
          co2: responseData.co2,
          //scope1: responseData.scope1 ? responseData.scope1 : "",
          //scope2: responseData.scope2 ? responseData.scope2 : "",
          //scope3: responseData.scope3 ? responseData.scope1 : "",
          //stage1: responseData.stage1 ? responseData.stage1 : "",
          //stage2: responseData.stage2 ? responseData.stage2 : "",
          //stage3: responseData.stage3 ? responseData.stage3 : "",
          //stage4: responseData.stage4 ? responseData.stage4 : "",
          //stage5: responseData.stage5 ? responseData.stage5 : "",
          //eeiofactor: responseData.eeiofactor ? responseData.eeiofactor : null,
          units: responseData.units ? responseData.units : null,
          resource_type: responseData.resource_type,
          id: responseData.proID,
          product_id: id,
          notes: responseData.notes,
          accuracy: responseData.accuracy,
          uncertainty: responseData.uncertainty,
          geography: responseData.geography || "Global",
          activityUUIDs: responseData.activityUUIDs,
          decomposition_assumptions: responseData.decomposition_assumptions,
          quantification_assumptions: responseData.quantification_assumptions,
          verification_results: responseData.verification_results,
          activity_selection_reasoning: responseData.activity_selection_reasoning,
          datasource: responseData.datasource,

        };
      } else {
        return {
          // name: responseData.name,
          name: isInterimProduct || isFinishedProduct ? resource.title : responseData.name, //name for interim and finished products
          material: responseData.material, // TODO: change to name if resource type/datasource is custom
          description: responseData.description,
          ghgFactor: responseData.ghgFactor,
          uom: responseData.uom,
          co2: responseData.co2,
          scope1: responseData.scope1 ? responseData.scope1 : "",
          scope2: responseData.scope2 ? responseData.scope2 : "",
          scope3: responseData.scope3 ? responseData.scope1 : "",
          stage1: responseData.stage1 ? responseData.stage1 : "",
          stage2: responseData.stage2 ? responseData.stage2 : "",
          stage3: responseData.stage3 ? responseData.stage3 : "",
          stage4: responseData.stage4 ? responseData.stage4 : "",
          stage5: responseData.stage5 ? responseData.stage5 : "",
          eeiofactor: responseData.eeiofactor ? responseData.eeiofactor : null,
          units: responseData.units ? responseData.units : null,
          resource_datasource: responseData.datasource,
          id: responseData.resID,
          product_id: id,
          notes: responseData.notes,
          accuracy: responseData.accuracy,
          uncertainty: responseData.uncertainty,
          geography: responseData.geography || "Global",
          activityUUIDs: responseData.activityUUIDs,
          decomposition_assumptions: responseData.decomposition_assumptions,
          quantification_assumptions: responseData.quantification_assumptions,
          verification_results: responseData.verification_results,
          activity_selection_reasoning: responseData.activity_selection_reasoning,
          datasource: responseData.datasource,

        };
      }
    });

    const responseObject = formattedResponseData.reduce((obj, item) => {
      return item;
    }, {});

    setFormValue(responseObject);
  };

  const fetchResource = () => {
    axiosFetch(
      {
        method: "get",
        url: "resource/" + id + "/" + props.node,
      },
      handleResponse
    );
  };

  useEffect(() => {
    fetchResource();
  }, []);

  return (
    <SoftBox py={3}>
      {loading && <LoaderSpinner />}
      <Grid container justifyContent="center" sx={{ height: "100%" }}>
        <Grid item xs={12} lg={12} sm={12}>
          <Formik
            initialValues={formValue || initialValues}
            validationSchema={currentValidation}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <SoftBox p={2}>
                    <SoftBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      })}
                      <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <SoftBox />
                        ) : (
                          <SoftButton variant="gradient" color="light" onClick={handleBack}>
                            back
                          </SoftButton>
                        )}
                        <SoftButton
                          disabled={
                            isSubmitting ||
                            //!values.name ||
                            !values.material ||
                            values.co2 == "" ||
                            !values.units
                          }
                          type="submit"
                          variant="gradient"
                          color="success"
                        >
                          {isLastStep ? "Save" : "next"}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default NewResource;
