import React, { useState } from "react";
import { Formik, Form } from "formik";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import FormField from "layouts/assets/new-asset/components/FormField";
import useAxios from "hooks/useAxios";
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import ReactJson from "react-json-view";
import SoftSelect from "components/SoftSelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoaderSpinner from "components/LoaderSpinner";

function CopilotSearchCompose() {
    const [searchResults, setSearchResults] = useState([]);
    const [posts, error, loading, axiosFetch] = useAxios();
    const publicVisibilityOptions = [
        { label: "True", value: "true" },
        { label: "False", value: "false" },
    ];
    const [nodeType, setNodeType] = useState(
        { label: "Resource", value: "resource" }
    );

    const handleNodeTypeChange = (option) => {
        setNodeType(option);
    };

    const handleSearch = (searchParams) => {
        setSearchResults([]);
        axiosFetch(
            {
                method: "post",
                url: "/ai/getactivitynames",
                requestConfig: {
                    search_term: searchParams.name + " ("+ searchParams.description + ")",
                    node_type: nodeType.value,
                    source: "playground",
                },
            },
            (response) => {
                // Just show the first 50 results
                setSearchResults(response.data.slice(0, 50));
            }
        );
    };

    const handleCompose = (composeParams) => {
        setSearchResults([]);
        axiosFetch(
            {
                method: "post",
                url: "/ai/getdecomposedmaterials",
                requestConfig: {
                    material_name: composeParams.name,
                    material_description: composeParams.description,
                    material_units: composeParams.units,
                    material_unit_of_measurement: composeParams.uom,
                    node_type: nodeType.value,
                    enable_decompose: true,
                    source: "playground",
                },
            },
            (response) => {
                setSearchResults(response.data);
            }
        );
    };

    const clearComponents = (resetForm) => {
        setSearchResults([]);
        resetForm();
    };

    return (
        <SoftBox mt={2}>
            {loading && <LoaderSpinner />}
            <Formik
                initialValues={{ name: "", description: "", units: "", uom: "" }}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                }}
            >
                {({ values, handleChange, handleSubmit, resetForm }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <SoftBox my={3}>
                            <Card>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SoftTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    Copilot Search & Compose
                                                </SoftTypography>
                                                <SoftTypography fontSize="small">
                                                    Find out any material decomposition and best matches for their emission activities.
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftButton
                                                disabled={!values.name}
                                                variant="gradient"
                                                color="primary"
                                                fullWidth
                                                onClick={() => handleSearch(values)}
                                            >
                                                Copilot Search
                                            </SoftButton>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftButton
                                                disabled={!values.name || !values.uom || !values.units}
                                                variant="gradient"
                                                color="primary"
                                                fullWidth
                                                onClick={() => handleCompose(values)}
                                            >
                                                Copilot Compose
                                            </SoftButton>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={() => clearComponents(resetForm)}
                                            >
                                                Clear
                                            </SoftButton>
                                        </Grid>

                                        {/* Input Fields */}
                                        <Grid item xs={4}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Node Type
                                            </SoftTypography>
                                            <SoftSelect
                                                label="Type"
                                                options={[
                                                    { label: "Resource", value: "resource" },
                                                    { label: "Process", value: "process" },
                                                ]}
                                                value={nodeType}
                                                onChange={handleNodeTypeChange}
                                            />
                                            <FormField label="Name" name="name" value={values.name} onChange={handleChange} />
                                            <FormField label="Description" name="description" value={values.description} onChange={handleChange} multiline rows={4} />
                                            <FormField label="Unit of Measurement" name="uom" value={values.uom} onChange={handleChange} />
                                            <FormField label="Units" name="units" value={values.units} onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SoftBox mb={1}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Copilot Results
                                                </SoftTypography>
                                            </SoftBox>
                                            <div style={{ maxHeight: "400px", overflow: "auto" }}>
                                                <ReactJson
                                                    src={searchResults}
                                                    collapsed={1}
                                                    theme="brewer"
                                                    displayDataTypes={false}
                                                    style={{ fontSize: "16px", lineHeight: "1.5" }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Card>
                        </SoftBox>
                    </Form>
                )}
            </Formik>
        </SoftBox>
    );
}

export default CopilotSearchCompose;
