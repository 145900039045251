import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/assets/new-asset/components/FormField";
import CustomSelect from "components/CustomSelect";
import { Field, ErrorMessage } from "formik";

const categoryOptions = [
  { value: "clothing", label: "Clothing" },
  { value: "electronics", label: "Electronics" },
  { value: "furniture", label: "Furniture" },
  { value: "others", label: "Others" },
];
function BasicInfo({ formData }) {
  const [state, setState] = useState("...");
  const { formField, values, errors, touched } = formData;
  const { facilityName, description, category } = formField;

  const { facilityName: facilityNameV, description: descriptionV, category: categoryV } = values;

  const [editorValue, setEditorValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br>"
  );

  return (
    <SoftBox>
      <SoftTypography variant="h5">Facility Information</SoftTypography>
      <SoftTypography variant="button" fontWeight="regular" color="text">
        Mandatory informations
      </SoftTypography>

      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid item mb={3}>
              <FormField
                type={facilityName.type}
                label={facilityName.label}
                name={facilityName.name}
                value={facilityNameV}
                placeholder={facilityName.placeholder}
                error={errors.facilityName && touched.facilityName}
                success={facilityNameV.length > 0 && !errors.facilityName}
              />
            </Grid>
            {/*             <SoftBox mb={3}>
              <CustomSelect name={category.name} label={category.label} options={categoryOptions} />
            </SoftBox> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
BasicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default BasicInfo;
