import React, { useState } from "react";
import Chart from "react-apexcharts";

const ProductsGraph = ({ data }) => {
  const ITEMS_PER_PAGE = 4;
  const [currentPage, setCurrentPage] = useState(0);

  const filteredData = data
    .filter((item) => item.product_data && item.product_data.name && item.co2_summary)
    .sort((a, b) => b.co2_summary.co2 - a.co2_summary.co2);

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const currentData = filteredData.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE
  );

  const productNames = currentData.map((item) => item.product_data.name);
  const co2Data = currentData.map((item) => item.co2_summary.co2);
  const coproductCo2Data = currentData.map((item) => item.co2_summary.coproduct_co2);
  const wasteCo2Data = currentData.map((item) => item.co2_summary.waste_co2);

  const chartOptions = {
    noData: {
      text: "NO DATA",
      align: "center",
      verticalAlign: "middle",
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: productNames,
    },
    yaxis: {
      title: {
        text: "CO2 (kgCo2e)",
        offsetX: -65,
      },
      labels: {
        formatter: (value) => value.toFixed(0), // Round y-axis labels to 0 decimal points
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toFixed(3), // Round tooltip values to 2 decimal points
      },
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
  };

  const chartSeries = [
    {
      name: "CO2",
      data: co2Data.map((value) => (value !== null ? +value.toFixed(3) : 0)), // Round CO2 data to 2 decimal places
    },
    {
      name: "Coproduct CO2",
      data: coproductCo2Data.map((value) => (value !== null ? +value.toFixed(3) : 0)), // Round Coproduct CO2 data to 2 decimal places
    },
    {
      name: "Waste CO2",
      data: wasteCo2Data.map((value) => (value !== null ? +value.toFixed(3) : 0)), // Round Waste CO2 data to 2 decimal places
    },
  ];

  const buttonStyle = {
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#3498db",
    color: "white",
    cursor: "pointer",
    transition: "background-color 0.2s",
  };

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="bar" stacked="true" height={400} />
      <div
        className="graph-nav-button"
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        {currentPage > 0 && (
          <button
            style={buttonStyle}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#2980b9")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#3498db")}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {"<<"}
          </button>
        )}
        <span style={{ margin: "0 10px", alignItems: "center", display: "flex" }}>
          Page {currentPage + 1} of {totalPages}
        </span>
        {currentPage < totalPages - 1 && (
          <button
            style={buttonStyle}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#2980b9")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#3498db")}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            {">>"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductsGraph;
