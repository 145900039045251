import checkout from 'layouts/pages/product-entity/schemas/form';

const { formField: {
    processName,
    methodOfCalculation,
    description,
    emissionProfiles,
    category,
    subcategory,
    material,
    uom,
    scope,
    ghgfactor,
    profiles,
    emissionProfilesFacility,
   
},
} = checkout;

const initialValues = {
    [processName.name]: '',
    [methodOfCalculation.name]: { label: 'Select method', value: '' },
    [description.name]: '',
    [category.name]: { label: 'Select category', value: '-' },
    [subcategory.name]: { label: 'Select sub category', value: '-' },
    [material.name]: { label: 'Select material', value: '-' },
    [uom.name]: { label: 'Select uom', value: '-' },
    [scope.name]: { label: 'Select scope', value: '-' },
    emissionProfiles: [{
        asset: '',
        profiles: [],
    },

    ],
    emissionProfilesFacility: [{
        facility: '',
        profiles: [],
    },
    ],
    profiles : []
}

export default initialValues;