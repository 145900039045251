import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import { FieldArray, Field, ErrorMessage } from "formik";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import FormField from "layouts/assets/new-asset/components/FormField";

import SoftSelect from "components/SoftSelect";

import useAxios from "hooks/useAxios";

function Assets({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const [assetOptions, setassetOptions] = useState([]);
  const [posts, error, loading, axiosFetch] = useAxios();
  const [unitValue, setUnitValue] = useState(0);
  const [selectedAsset, setselectedAsset] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleResponse = (response) => {
    let asset = response?.data.map((responseData) => ({
      value: responseData.name,
      label: responseData.name,
      id: responseData.ID,
    }));
    setassetOptions(asset);
  };
  const fetchAssets = () => {
    axiosFetch(
      {
        method: "get",
        url: "assets/list",
      },
      handleResponse
    );
  };

  const handleAssetResponse = (response) => {
    let emissionProfile = response.data[0].emissionFactor;
    let updatedEmissionFactor = emissionProfile.map((profile) => {
      return {
        ...profile,
        units: 0,
        co2: 0,
      };
    });

    values.emissionProfiles[selectedIndex].profiles = updatedEmissionFactor;
  };

  const fetchAsset = (id) => {
    if (id != undefined) {
      axiosFetch(
        {
          method: "get",
          url: "assetslist/" + id,
        },
        handleAssetResponse
      );
    }
  };

  const handleAsset = (selectedOption, key) => {
    setselectedAsset(selectedOption);
    setSelectedIndex(key);
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  useEffect(() => {
    fetchAsset(selectedAsset.id);
  }, [selectedAsset]);

  return (
    <SoftBox>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} sm={8}>
          <SoftTypography variant="h5">Assets information</SoftTypography>
        </Grid>
        <Grid item xs={12} sm={4} container justifyContent="flex-end" alignItems="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px", // This will provide the spacing between buttons
            }}
          >
            <SoftButton
              variant="outlined"
              color="secondary"
              size="medium"
              style={{ width: "150px" }}
              onClick={fetchAssets}
            >
              Refresh
            </SoftButton>
            <Link to="/assets/new" target="_blank">
              <SoftButton variant="outlined" color="info" size="medium" style={{ width: "150px" }}>
                + new asset 🡥
              </SoftButton>
            </Link>
          </div>
        </Grid>
      </Grid>

      <SoftBox mb={2} mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} mb={18}>
            <FieldArray name="emissionProfiles">
              {(emissionProfilesArray) => {
                const { form, push, remove } = emissionProfilesArray;
                const { values } = form;
                const { emissionProfiles } = values;

                return (
                  <SoftBox>
                    {values.emissionProfiles.map((emission, outerIndex) => (
                      <SoftBox
                        mt={3}
                        key={outerIndex}
                        color="grey"
                        borderRadius="lg"
                        shadow="lg"
                        opacity={2}
                        p={2}
                      >
                        <Grid container spacing={6}>
                          <Grid item xs={12} sm={5}>
                            <SoftBox mb={1.5}>
                              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Asset
                                </SoftTypography>
                              </SoftBox>
                              <SoftSelect
                                name={`emissionProfiles[${outerIndex}][asset]`}
                                options={assetOptions}
                                value={assetOptions.find(
                                  (option) => option.value === emissionProfiles[outerIndex].asset
                                )}
                                onChange={(selectedOption) => {
                                  handleAsset(selectedOption, outerIndex);
                                  setFieldValue(
                                    `emissionProfiles[${outerIndex}][asset]`,
                                    selectedOption.value
                                  );
                                }}
                                isSearchable
                              />
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} mt={4.5} sm={2}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {/* Add button */}
                              {outerIndex === 0 && (
                                <SoftButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  onClick={() => push({ name: "", innerItems: [] })}
                                  style={{ marginRight: "8px" }} // Optional: for a little spacing between the buttons
                                >
                                  +
                                </SoftButton>
                              )}

                              {/* Remove button */}
                              <SoftButton
                                variant="gradient"
                                color="error"
                                size="small"
                                onClick={() => {
                                  if (outerIndex === 0) {
                                    remove(outerIndex);
                                    push({ name: "", innerItems: [] });
                                  } else {
                                    // Remove the asset
                                    remove(outerIndex);
                                  }
                                }}
                              >
                                X
                              </SoftButton>
                            </div>
                          </Grid>
                        </Grid>
                        {emission.profiles != undefined && emission.profiles.length > 0 && (
                          <FieldArray name={`emissionProfiles.${outerIndex}.profiles`}>
                            {({ push: innerPush, remove: innerRemove }) => (
                              <SoftBox>
                                {emission.profiles.map((profile, innerIndex) => (
                                  <SoftBox
                                    mt={3}
                                    key={innerIndex}
                                    bgColor="cornsilk"
                                    borderRadius="lg"
                                    shadow="lg"
                                    opacity={1}
                                    p={2}
                                  >
                                    {console.log('values.emissionProfiles', values.emissionProfiles)}
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={4}>
                                        <FormField
                                          type="text"
                                          label="Profile Name"
                                          readOnly
                                          name={`emissionProfiles.${outerIndex}.profiles.${innerIndex}.profile`}
                                          value={
                                            values.emissionProfiles[outerIndex].profiles[innerIndex]
                                              .profile || ""
                                          }
                                          placeholder="Name"
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <FormField
                                          type="text"
                                          label="Unit of Measurement"
                                          readOnly
                                          name={`emissionProfiles.${outerIndex}.profiles.${innerIndex}.profile`}
                                          value={
                                            values.emissionProfiles[outerIndex].profiles[innerIndex]
                                              .uom || ""
                                          }
                                          placeholder="Name"
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <FormField
                                          type="text"
                                          label="GHG Factor"
                                          readOnly
                                          name={`emissionProfiles.${outerIndex}.profiles.${innerIndex}.ghg_factor`}
                                          value={
                                            values.emissionProfiles[outerIndex].profiles[innerIndex]
                                              .ghg_factor
                                          }
                                          placeholder="GHG Factor"
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <FormField
                                          key={`emissionProfiles.${outerIndex}.profiles.${innerIndex}.units`}
                                          type="text"
                                          label="Units"
                                          name={`emissionProfiles.${outerIndex}.profiles.${innerIndex}.units`}
                                          placeholder="Units"
                                          onChange={(e) => {
                                            const newUnit = e.target.value;
                                            const ghgFactor =
                                              values.emissionProfiles[outerIndex].profiles[
                                                innerIndex
                                              ].ghg_factor;
                                            setFieldValue(
                                              `emissionProfiles.${outerIndex}.profiles.${innerIndex}.units`,
                                              newUnit
                                            );
                                            setFieldValue(
                                              `emissionProfiles.${outerIndex}.profiles.${innerIndex}.co2`,
                                              newUnit * ghgFactor
                                            );
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2}>
                                        <FormField
                                          type="text"
                                          label="CO2"
                                          readOnly
                                          name={`emissionProfiles.${outerIndex}.profiles.${innerIndex}.co2`}
                                          placeholder="CO2"
                                        />
                                      </Grid>
                                    </Grid>
                                  </SoftBox>
                                ))}
                              </SoftBox>
                            )}
                          </FieldArray>
                        )}
                      </SoftBox>
                    ))}
                  </SoftBox>
                );
              }}
            </FieldArray>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mb={10}></SoftBox>
    </SoftBox>
  );
}

Assets.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Assets;
