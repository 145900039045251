import { editNodeAction, deleteNodeAction, deleteNodeLinkAction, connectNodeAction } from './utils';

export const EVENT_CHART_INITIALIZED = "EVENT_CHART_INITIALIZED";
export const EVENT_NODE_ADD_REACTANGLE = "EVENT_NODE_ADD_REACTANGLE";
export const EVENT_NODE_ADD_CIRCLE = "EVENT_NODE_ADD_CIRCLE";
export const EVENT_CHART_DELETE = "EVENT_CHART_DELETE";
export const EVENT_GRAPH_DELETE = "EVENT_GRAPH_DELETE";

export const CHART_EVENTS_INIT = "CHART_EVENTS_INIT";
export const CHART_EVENTS_CLICK = "CHART_EVENTS_CLICK";

export const CHART_EVENT_DELETE_NODE = "CHART_EVENT_DELETE_NODE"
export const CHART_EVENT_DELETE_EDGE = "CHART_EVENT_DELETE_EDGE"

export const CHART_EVENT_CONNECT_EDGE = "CHART_EVENT_CONNECT_EDGE"

export const MENU_ACTIONS = [
    { id: "connect-nodes", label: "Connect nodes", hotkey: 'Shift + left mouse', onEvent: (graph, state, node, edge, callback) => connectNodeAction(graph, state, node, edge, callback) },
    {
        id: "edit-nodes",
        label: "Edit node",
        hotkey: 'Ctrl + left mouse',
        onEvent: (graph, state, node, edge, callback) => editNodeAction(graph, state, node, edge, callback)
    },
    {
        id: "delete-nodes",
        hotkey: 'Del', label: "Delete node", onEvent: (graph, state, node, edge, callback) => deleteNodeAction(graph, state, node, edge, callback)
    },
];


export const MENU_LINE_ACTIONS = [
    { id: "delete-link", label: "Delete a link", onEvent: (graph, state, node, edge, callback) => deleteNodeLinkAction(graph, state, node, edge, callback) },
];