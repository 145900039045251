import * as d3 from "d3";
import {
  EVENT_NODE_ADD_REACTANGLE,
  EVENT_NODE_ADD_CIRCLE,
  EVENT_CHART_INITIALIZED,
  EVENT_CHART_DELETE,
  EVENT_GRAPH_DELETE,
  CHART_EVENTS_CLICK,
  CHART_EVENTS_INIT,
  MENU_ACTIONS,
  MENU_LINE_ACTIONS,
} from "./constant";
import { deleteNodeAction, removeAllChildrenInD3, shiftCircleMouseDownAction } from './utils';

const chartEventsClick = (data) => {
  return new CustomEvent(CHART_EVENTS_CLICK, { detail: data })
};

const Chart = function (saveAs, Blob, clickeventFn, canvasData) {
  ("use strict");

  // TODO add user settings
  var consts = {
    defaultTitle: "Process",
  };
  var settings = {
    appendElSpec: "#graph",
  };

  // define graphcreator object
  var GraphCreator = function (svg, nodes, edges) {
    var thisGraph = this;
    thisGraph.idct = 0;
    thisGraph.paneOpen = false;

    thisGraph.justResetted = false;

    thisGraph.nodes = nodes || [];
    thisGraph.edges = edges || [];
    thisGraph.state = {
      selectedNode: null,
      selectedEdge: null,
      mouseDownNode: null,
      mouseDownLink: null,
      justDragged: false,
      justScaleTransGraph: false,
      lastKeyDown: -1,
      shiftNodeDrag: false,
      selectedText: null,
      SVG_REF: document.getElementById("graph"),
      tempMouseDownNode: null,
    };

    // define arrow markers for graph links
    var defs = svg.append("svg:defs");
    defs
      .append("svg:marker")
      .attr("id", "end-arrow")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", "280")
      .attr("markerWidth", 3.5)
      .attr("markerHeight", 3.5)
      .attr("orient", "auto")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5");

    // define arrow markers for leading arrow
    defs
      .append("svg:marker")
      .attr("id", "mark-end-arrow")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", "80")
      .attr("markerWidth", 3.5)
      .attr("markerHeight", 3.5)
      .attr("orient", "auto")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5");

    thisGraph.svg = svg;
    thisGraph.svgG = svg.append("g").classed(thisGraph.consts.graphClass, true);
    var svgG = thisGraph.svgG;

    // displayed when dragging between nodes
    thisGraph.dragLine = svgG
      .append("svg:path")
      .attr("class", "link dragline hidden")
      .attr("d", "M0,0L0,0")
      .style("marker-end", "url(#mark-end-arrow)")
      .on('mousedown', () => {
        console.log('mousedown')
      });


    // svg nodes and edges
    thisGraph.paths = svgG.append("g").selectAll("g");
    thisGraph.circles = svgG.append("g").selectAll("g");

    // Append rectangles for numbers
    thisGraph.rectangles = svgG.append("g").selectAll("g");

    thisGraph.drag = d3.behavior
      .drag()
      .origin(function (d) {
        return { x: d.x, y: d.y };
      })
      .on("drag", function (args) {
        thisGraph.state.justDragged = true;
        thisGraph.dragmove.call(thisGraph, args);
      })
      .on("dragend", function () {
        // todo check if edge-mode is selected
      });

    // listen for key events
    d3.select(window)
      .on("keydown", function () {
        thisGraph.svgKeyDown.call(thisGraph);
      })
      .on("keyup", function () {
        thisGraph.svgKeyUp.call(thisGraph);
      });
    svg.on("mousedown", function (d) {
      thisGraph.svgMouseDown.call(thisGraph, d);
    });
    svg.on("mouseup", function (d) {
      thisGraph.svgMouseUp.call(thisGraph, d);
    });

    thisGraph.svg.on('mousemove', (event) => {
      if (thisGraph.state.connectMode && thisGraph.state.tempMouseDownNode) {
        thisGraph.dragmove.call(thisGraph, thisGraph.state.tempMouseDownNode)
        thisGraph.dragLine.classed("hidden", false);
      }
    })

    thisGraph.zoomListener = d3.behavior.zoom().on("zoom", thisGraph.zoomed.bind(thisGraph)); //.scaleExtent([0.1, 3])
    svg.call(thisGraph.zoomListener);

    // listen for dragging
    var dragSvg = d3.behavior
      .zoom()
      .on("zoom", function () {
        if (d3.event.sourceEvent.shiftKey) {
          // TODO  the internal d3 state is still changing
          return false;
        } else {
          thisGraph.zoomed.call(thisGraph);
        }
        return true;
      })
      .on("zoomstart", function () {
        var ael = d3.select("#" + thisGraph.consts.activeEditId).node();

        if (ael) {
          ael.blur();
        }
        if (!d3.event.sourceEvent.shiftKey) d3.select("body").style("cursor", "move");
      })
      .on("zoomend", function () {
        d3.select("body").style("cursor", "auto");
      });

    svg.call(dragSvg).on("dblclick.zoom", null);

    d3.select("#download-input").on("click", function () {
      var saveEdges = [];
      thisGraph.edges.forEach(function (val, i) {
        saveEdges.push({ source: val.source.id, target: val.target.id });
      });
      saveAs.call(this, { nodes: thisGraph.nodes, edges: saveEdges });
    });

    // listen for resize
    window.onresize = function () {
      thisGraph.updateWindow(svg);
    };
    d3.select("#delete-graph").on("click", function () {
      thisGraph.deleteGraph(false);
    });

    try {
      var jsonObj = mock;
      thisGraph.deleteGraph(true);
      thisGraph.nodes = jsonObj.nodes;

      thisGraph.setIdCt(jsonObj.nodes.length + 1);
      var newEdges = jsonObj.edges;
      newEdges.forEach(function (e, i) {
        newEdges[i] = {
          source: thisGraph.nodes.filter(function (n) {
            return n.id == e.source;
          })[0],
          target: thisGraph.nodes.filter(function (n) {
            return n.id == e.target;
          })[0],
        };
      });
      thisGraph.edges = newEdges;
      thisGraph.updateGraph();
      thisGraph.state.SVG_REF.dispatchEvent(new CustomEvent(EVENT_CHART_INITIALIZED));
    } catch (err) {
      // window.alert("Error parsing uploaded file\nerror message: " + err.message);
      return;
    }
  };

  GraphCreator.prototype.loadData = function (data) {
    try {
      var jsonObj = data;
      this.deleteGraph(true);
      this.nodes = jsonObj.nodes;
      this.setIdCt(jsonObj.nodes.length + 1);
      var newEdges = jsonObj.edges;
      var newEdges = jsonObj.edges;
      newEdges.forEach(function (e, i) {
        newEdges[i] = {
          source: this.nodes.filter(function (n) {
            return n.id == e.source;
          })[0],
          target: this.nodes.filter(function (n) {
            return n.id == e.target;
          })[0],
        };
      });
      this.edges = newEdges;
      this.updateGraph();
    } catch (err) {
      window.alert("Error parsing uploaded file\nerror message: " + err.message);
      return;
    }
  };
  GraphCreator.prototype.setIdCt = function (idct) {
    this.idct = idct;
  };

  GraphCreator.prototype.isCtrlKey = function () {
    return d3.event?.ctrlKey;
  }

  // Check if the event is a left-click with the control key (or command key on Mac) pressed
  GraphCreator.prototype.isMetaKey = function () {
    return d3.event?.isMetaKey;
  }

  GraphCreator.prototype.isShiftKey = function () {
    return d3.event?.isShiftKey;
  }

  GraphCreator.prototype.consts = {
    selectedClass: "selected",
    connectClass: "connect-node",
    circleGClass: "conceptG",
    graphClass: "graph",
    activeEditId: "active-editing",
    BACKSPACE_KEY: 8,
    DELETE_KEY: 46,
    ENTER_KEY: 13,
    nodeRadius: 50,
  };

  /* PROTOTYPE FUNCTIONS */

  GraphCreator.prototype.dragmove = function (d) {
    var thisGraph = this;
    if (thisGraph.state.shiftNodeDrag) {
      thisGraph.dragLine.attr(
        "d",
        "M" +
        d.x +
        "," +
        d.y +
        "L" +
        d3.mouse(thisGraph.svgG.node())[0] +
        "," +
        d3.mouse(this.svgG.node())[1]
      );
    } else {
      if (!(isNaN(d3.event.dx) && isNaN(d3.event.dy))) {
        d.x += d3.event.dx;
        d.y += d3.event.dy;
      }

      thisGraph.updateGraph();
    }
  };

  GraphCreator.prototype.deleteGraph = function (skipPrompt) {
    var thisGraph = this,
      doDelete = true;
    if (!skipPrompt) {
      doDelete = window.confirm("Press OK to delete this graph");
    }
    if (doDelete) {
      thisGraph.nodes = [];
      thisGraph.edges = [];

      thisGraph.updateGraph();
      thisGraph.state.SVG_REF.dispatchEvent(new CustomEvent(EVENT_GRAPH_DELETE));
    }
  };

  GraphCreator.prototype.selectElementContents = function (el) {
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };

  GraphCreator.prototype.insertTitleLinebreaks = function (gEl, title) {
    var words = title.split(/\s+/g),
      nwords = words.length,
      typeofElement = gEl.select("rect")[0][0];

    var el = gEl.append("text").attr("text-anchor", "middle");
    if (typeofElement === null) {
      el.attr("dy", "-" + (nwords - 1) * 7.5);
      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", 0).attr("dy", "15");
      }
    } else {
      const width = typeofElement.getBBox().width,
        height = typeofElement.getBBox().height;

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", width / 2).attr("dy", "15");
      }

      el.attr("y", height / 2 - (words.length * 15 - 15) / 2);
      el.attr("x", width / 2);
      el.attr("dominant-baseline", "middle");
      el.attr("text-anchor", "middle");
    }
  };

  // remove edges associated with a node
  GraphCreator.prototype.spliceLinksForNode = function (node) {
    var thisGraph = this,
      toSplice = thisGraph.edges.filter(function (l) {
        return l.source === node || l.target === node;
      });
    toSplice.map(function (l) {
      thisGraph.edges.splice(thisGraph.edges.indexOf(l), 1);
    });
  };

  GraphCreator.prototype.replaceSelectEdge = function (d3Path, edgeData) {
    var thisGraph = this;
    d3Path.classed(thisGraph.consts.selectedClass, true);
    if (thisGraph.state.selectedEdge) {
      thisGraph.removeSelectFromEdge();
    }
    thisGraph.state.selectedEdge = edgeData;
  };

  GraphCreator.prototype.replaceSelectNode = function (d3Node, nodeData) {
    var thisGraph = this;
    d3Node.classed(this.consts.selectedClass, true);
    if (thisGraph.state.selectedNode) {
      thisGraph.removeSelectFromNode();
    }
    thisGraph.state.selectedNode = nodeData;
  };

  GraphCreator.prototype.removeSelectFromNode = function () {
    var thisGraph = this;
    thisGraph.circles
      .filter(function (cd) {
        return cd.id === thisGraph.state.selectedNode.id;
      })
      .classed(thisGraph.consts.selectedClass, false);
    thisGraph.state.selectedNode = null;
  };

  GraphCreator.prototype.removeSelectFromEdge = function () {
    var thisGraph = this;
    thisGraph.paths
      .filter(function (cd) {
        console.log('thisGraph.state.selectedEdge', cd, thisGraph.edges, thisGraph.state.selectedEdge)
        return cd === thisGraph.state.selectedEdge;
      })
      .classed(thisGraph.consts.selectedClass, false);
    thisGraph.state.selectedEdge = null;
  };

  GraphCreator.prototype.removeLinkFromEdge = function (node, d) {
    var thisGraph = this;
    thisGraph.edges = thisGraph.edges.filter((cd) => {
      return !(d.source.id === cd.source.id && d.target.id === cd.target.id)
    })

    thisGraph.updateGraph();
  }

  GraphCreator.prototype.pathMouseDown = function (d3path, d) {
    var thisGraph = this,
      state = thisGraph.state;
    d3.event.stopPropagation();
    state.mouseDownLink = d;

    if (state.selectedNode) {
      thisGraph.removeSelectFromNode();
    }

    var prevEdge = state.selectedEdge;
    if (!prevEdge || prevEdge !== d) {
      thisGraph.replaceSelectEdge(d3path, d);
    } else {
      thisGraph.removeSelectFromEdge();
    }
  };

  // mousedown on node
  GraphCreator.prototype.circleMouseDown = function (d3node, d) {
    shiftCircleMouseDownAction(this, d3node, d)
  };

  /* place editable text on node in place of svg text */
  GraphCreator.prototype.changeTextOfNode = function (d3node, d) {
    var thisGraph = this,
      consts = thisGraph.consts,
      htmlEl = d3node.node();
    d3node.selectAll("text").remove();
    var nodeBCR = htmlEl.getCTM(),
      curScale = nodeBCR.width / consts.nodeRadius,
      placePad = 5 * curScale,
      useHW = curScale > 1 ? nodeBCR.width * 0.71 : consts.nodeRadius * 1.42;
    // replace with editableconent text

    var d3txt = thisGraph.svg
      .selectAll("foreignObject")
      .data([d])
      .enter()
      .append("foreignObject")
      .attr("x", nodeBCR.e - 32)
      .attr("y", nodeBCR.f - 32)
      .attr("height", 2 * useHW)
      .attr("width", useHW)
      .append("xhtml:p")
      .attr("id", consts.activeEditId)
      .attr("contentEditable", "true")
      .text(d.title)
      .on("mousedown", function (d) {
        d3.event.stopPropagation();
      })
      .on("keydown", function (d) {
        d3.event.stopPropagation();
        if (d3.event.keyCode == consts.ENTER_KEY && !thisGraph.isShiftKey()) {
          this.blur();
        }
      })
      .on("blur", function (d) {
        d.title = this.textContent;

        thisGraph.insertTitleLinebreaks(d3node, d.title);
        d3.select(this.parentElement).remove();
      });
    return d3txt;
  };

  // mouseup on nodes
  GraphCreator.prototype.circleMouseUp = function (d3node, d, clickeventFn, mouseRight = false) {
    const thisGraph = this,
      state = thisGraph.state,
      consts = thisGraph.consts;
    // reset the states
    state.shiftNodeDrag = false;
    d3node.classed(consts.connectClass, false);

    var mouseDownNode = state.mouseDownNode;

    if (!mouseDownNode) return;

    thisGraph.dragLine.classed("hidden", true);
    if (mouseDownNode !== d) {
      // we're in a different node: create new edge for mousedown edge and add to graph
      var newEdge = { source: mouseDownNode, target: d };
      if (mouseRight) {
        newEdge = { source: d, target: mouseDownNode };
      }
      var filtRes = thisGraph.paths.filter(function (d) {
        if (d.source === newEdge.target && d.target === newEdge.source) {
          thisGraph.edges.splice(thisGraph.edges.indexOf(d), 1);
        }
        return d.source === newEdge.source && d.target === newEdge.target;
      });
      if (!filtRes[0].length) {
        thisGraph.edges.push(newEdge);
        thisGraph.updateGraph();
      }
    } else {
      // we're in the same node
      if (state.justDragged) {
        // dragged, not clicked
        state.justDragged = false;
      } else {
        // clicked, not dragged
        if (thisGraph.isCtrlKey() || thisGraph.isMetaKey()) {
          thisGraph.state.SVG_REF.dispatchEvent(chartEventsClick(d));
          return;
        }
        // return clickeventFn && clickeventFn()

        if (thisGraph.isShiftKey()) {
          // shift-clicked node: edit text content
          var d3txt = thisGraph.changeTextOfNode(d3node, d);
          var txtNode = d3txt.node();
          thisGraph.selectElementContents(txtNode);
          txtNode.focus();
        } else {
          if (state.selectedEdge) {
            thisGraph.removeSelectFromEdge();
          }
          var prevNode = state.selectedNode;

          if (!prevNode || prevNode.id !== d.id) {
            thisGraph.replaceSelectNode(d3node, d);
          } else {
            thisGraph.removeSelectFromNode();
          }
        }
      }
    }
    state.mouseDownNode = null;
    return;
  }; // end of circles mouseup

  // mouseup on nodes
  GraphCreator.prototype.circleClick = function (d3node, d, clickeventFn) {
    const thisGraph = this,
      state = thisGraph.state,
      consts = thisGraph.consts;
    // reset the states
    state.shiftNodeDrag = false;
    d3node.classed(consts.connectClass, false);

    var mouseDownNode = state.mouseDownNode;

    if (!mouseDownNode) return;

    thisGraph.dragLine.classed("hidden", true);

    if (mouseDownNode !== d) {
      // we're in a different node: create new edge for mousedown edge and add to graph
      var newEdge = { source: mouseDownNode, target: d };
      var filtRes = thisGraph.paths.filter(function (d) {
        if (d.source === newEdge.target && d.target === newEdge.source) {
          thisGraph.edges.splice(thisGraph.edges.indexOf(d), 1);
        }
        return d.source === newEdge.source && d.target === newEdge.target;
      });
      if (!filtRes[0].length) {
        thisGraph.edges.push(newEdge);
        thisGraph.updateGraph();
      }
    } else {
      // we're in the same node
      if (state.justDragged) {
        // dragged, not clicked
        state.justDragged = false;
      } else {
        // clicked, not dragged

        if (d3.event.type == "click") {
          thisGraph.state.SVG_REF.dispatchEvent(chartEventsClick(d));
          return;
        }
        if (thisGraph.isCtrlKey() || thisGraph.isMetaKey()) {
          thisGraph.state.SVG_REF.dispatchEvent(chartEventsClick(d));
          return;
        }
        // return clickeventFn && clickeventFn()

        if (thisGraph.isShiftKey()) {
          // shift-clicked node: edit text content
          var d3txt = thisGraph.changeTextOfNode(d3node, d);
          var txtNode = d3txt.node();
          thisGraph.selectElementContents(txtNode);
          txtNode.focus();
        } else {
          if (state.selectedEdge) {
            thisGraph.removeSelectFromEdge();
          }
          var prevNode = state.selectedNode;

          if (!prevNode || prevNode.id !== d.id) {
            thisGraph.replaceSelectNode(d3node, d);
          } else {
            thisGraph.removeSelectFromNode();
          }
        }
      }
    }
    state.mouseDownNode = null;
    return;
  }; // end of circles mouseup

  // mousedown on main svg
  GraphCreator.prototype.svgMouseDown = function () {
    this.state.graphMouseDown = true;
  };

  // mouseup on main svg
  GraphCreator.prototype.svgMouseUp = function (thigraph, event) {
    var thisGraph = this,
      state = thisGraph.state;
    if (state.justScaleTransGraph) {
      // dragged not clicked
      state.justScaleTransGraph = false;
    } else if (state.graphMouseDown && thisGraph.isShiftKey()) {
      // clicked not dragged from svg
      var xycoords = d3.mouse(thisGraph.svgG.node()),
        d = { id: thisGraph.idct++, title: consts.defaultTitle, x: xycoords[0], y: xycoords[1] };
      thisGraph.nodes.push(d);
      thisGraph.updateGraph();
      // make title of text immediently editable
      var d3txt = thisGraph.changeTextOfNode(
        thisGraph.circles.filter(function (dval) {
          return dval.id === d.id;
        }),
        d
      ),
        txtNode = d3txt.node();
      thisGraph.selectElementContents(txtNode);
      txtNode.focus();
    } else if (state.shiftNodeDrag) {
      // dragged from node
      state.shiftNodeDrag = false;
      thisGraph.dragLine.classed("hidden", true);
    }
    state.graphMouseDown = false;
  };


  // keydown on main svg
  GraphCreator.prototype.svgKeyDown = function () {
    var thisGraph = this,
      state = thisGraph.state,
      consts = thisGraph.consts;
    // make sure repeated key presses don't register for each keydown
    if (state.lastKeyDown !== -1) return;

    state.lastKeyDown = d3.event.keyCode;
    var selectedNode = state.selectedNode,
      selectedEdge = state.selectedEdge;

    switch (d3.event.keyCode) {
      //case consts.BACKSPACE_KEY:
      case consts.DELETE_KEY:
        deleteNodeAction(thisGraph, state, selectedNode, selectedEdge)
        break;
    }
  };

  GraphCreator.prototype.svgKeyUp = function () {
    this.state.lastKeyDown = -1;
  };



  GraphCreator.prototype.setPaneState = function (state) {
    this.paneOpen = state;
  };

  GraphCreator.prototype.updateSingleNodeFromID = function (id, data) {
    const graphEdges = this.edges;
    const allIteam = this.nodes.map((item) => {
      if (Number(item.id) === Number(id) && data != null) {
        item.title = data.title ? data.title : item.title;
        return item;
      }
      return item;
    });
    this.deleteGraph(true);
    this.nodes = allIteam;
    this.edges = graphEdges;
    this.updateGraph();
  };

  // call to propagate changes to graph
  GraphCreator.prototype.updateGraph = function () {
    var thisGraph = this,
      consts = thisGraph.consts,
      state = thisGraph.state;

    thisGraph.paths = thisGraph.paths.data(thisGraph.edges, function (d) {
      return String(d.source.id) + "+" + String(d.target.id);
    });

    // Update rectangles for numbers
    thisGraph.rectangles = thisGraph.rectangles.data(thisGraph.nodes, function (d) {
      return d.id;
    });

    var paths = thisGraph.paths;
    // update existing paths
    paths
      .style("marker-end", "url(#end-arrow)")
      .classed(consts.selectedClass, function (d) {
        return d === state.selectedEdge;
      })
      .attr("d", function (d) {
        return ['M', d.source.x, ',', d.source.y, 'L', d.target.x, ',', d.target.y].filter(Boolean).join('')
      });

    var renderMenuLink = function (d) {
      dropdown.selectAll("*").remove();
      MENU_LINE_ACTIONS.forEach(action => {
        dropdown.style('width', '150px');
        const divContent = dropdown.append("li")
          .classed('action-item', true)
          .attr("id", action.id)
          .on("click", () => {
            if (action.id === 'delete-link') {
              thisGraph.state.selectedEdge = d;
              thisGraph.removeLinkFromEdge(thisGraph, d);
            } else {
              action?.onEvent?.(thisGraph, state, d, state.selectedEdge, chartEventsClick);
            }
            removeAllChildrenInD3(dropdown);
            dropdown.attr("style", null);
          });

        divContent.append('span').classed("action-item-left-text", true).text(action.label);
        if (action.hotkey) {
          divContent.append('span').classed("action-item-right-text", true).text(action.hotkey);
        }

      });
    }

    // add new paths
    paths
      .enter()
      .append("path")
      .style("marker-end", "url(#end-arrow)")
      .classed("link", true)
      .attr("d", function (d) {
        return "M" + d.source.x + "," + d.source.y + "L" + d.target.x + "," + d.target.y;
      })
      .on("mousedown", function (d) {
        let isRightMouseDown = false;
        if ("which" in d3.event) {
          isRightMouseDown = d3.event.which === 3
        } else if ("button" in d3.event) {
          isRightMouseDown = d3.event.button === 2
        }

        if (isRightMouseDown) {
          renderMenuLink(d);
          dropdownContainer.classed('dropdown-collapse', true)
          dropdown
            .style("top", (d3.event.offsetY + 24) + "px")
            .style("opacity", "1")
            .style("left", d3.event.offsetX + "px")
            .style("display", "block")
          document.body.style.overflow = 'hidden';
        }


        thisGraph.pathMouseDown.call(thisGraph, d3.select(this), d);
      })
      .on("mouseup", function (d) {
        state.mouseDownLink = null;
      })
      .on("mouseover", function () { });

    // remove old links
    paths.exit().remove();

    // update existing nodes
    thisGraph.circles = thisGraph.circles.data(thisGraph.nodes, function (d) {
      return d.id;
    });
    thisGraph.circles.attr("transform", function (d) {
      if (d.type == "react") {
        return "translate(" + (d.x - 100) + "," + (d.y - 50) + ")";
      }
      return "translate(" + d.x + "," + d.y + ")";
    });

    // add new nodes
    var newGs = thisGraph.circles.enter().append("g");
    //add tooltip
    var tooltip = d3
      .select("#graph")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", "0")
      .style("visibility", "hidden")
      .text("");

    var dropdownContainer = d3
      .select("#graph")
      .append("div")
      .attr("class", "dropdown-container").on('contextmenu', () => {
        d3.event.preventDefault();
      })

    var dropdown = dropdownContainer.append("ul")
      .attr("class", "dropdown-nodes")

    var renderMenu = function (d) {
      dropdown.selectAll("*").remove();
      MENU_ACTIONS.forEach(action => {
        const divContent = dropdown.append("li").classed('action-item', true).attr("id", action.id).on("click", () => {
          action?.onEvent?.(thisGraph, state, d, state.selectedEdge, chartEventsClick);
          removeAllChildrenInD3(dropdown);
          dropdown.attr("style", null);
        })
        divContent.append('span').classed("action-item-left-text", true).text(action.label);
        if (action.hotkey) {
          divContent.append('span').classed("action-item-right-text", true).text(action.hotkey);
        }
      });
    }

    newGs
      .classed(consts.circleGClass, true)
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .on("mouseover", function (d) {
        var tileText = d3.select(this).text();
        tooltip.text(tileText);

        if (state.shiftNodeDrag) {
          d3.select(this).classed(consts.connectClass, true);
        }
        return tooltip.style("visibility", "visible");
      })
      .on("mousemove", function () {
        return tooltip
          .style("top", d3.event.pageY - 90 + "px")
          .style("opacity", "1")
          .style("left", d3.event.pageX - 230 + "px");
      })
      .on("mouseout", function (d) {
        tooltip.style("visibility", "hidden");
        d3.select(this).classed(consts.connectClass, false);
      })
      .on("mousedown", function (d) {
        dropdown.style("display", "none")

        let isRightMouseDown = false;
        if ("which" in d3.event) {
          isRightMouseDown = d3.event.which === 3
        } else if ("button" in d3.event) {
          isRightMouseDown = d3.event.button === 2
        }

        if (isRightMouseDown) {
          renderMenu(d)
          dropdownContainer.classed('dropdown-collapse', true)
          dropdown
            .style("top", (d3.event.offsetY + 24) + "px")
            .style("opacity", "1")
            .style("left", d3.event.offsetX + "px")
            .style("display", "block")
          document.body.style.overflow = 'hidden';
        }
        thisGraph.circleMouseDown.call(thisGraph, d3.select(this), d);
      })
      .on("mouseup", function (d) {
        tooltip.style("visibility", "hidden");

        if (thisGraph.state.connectMode && thisGraph.state.tempMouseDownNode) {
          thisGraph.circleMouseUp.call(thisGraph, d3.select(this), thisGraph.state.tempMouseDownNode, clickeventFn, true)
          thisGraph.state.connectMode = false;
          thisGraph.state.mouseDownNode = null;
          thisGraph.state.shiftNodeDrag = false;
          thisGraph.state.tempMouseDownNode = null;
        } else {
          thisGraph.circleMouseUp.call(thisGraph, d3.select(this), d, clickeventFn);
        }
      })
      .on("click", function (d) { })
      .on("dblclick", function () { })
      .call(thisGraph.drag);

    const onHandleCollapse = (event) => {
      const dropdownEl = document.querySelector('.dropdown-nodes');
      dropdownContainer.classed('dropdown-collapse', false)
      if (dropdownEl && !dropdownEl?.contains?.(event.target)) {
        document.body.style.overflow = 'unset';
        removeAllChildrenInD3(dropdown);
        dropdown.attr("style", null);
      }
    }

    document.addEventListener('click', onHandleCollapse)

    newGs.each(function (item) {
      if (item.type == "react") {
        d3.select(this)
          .append("rect")
          .attr("class", "process-rect")
          /*  .attr(
            "class",
            !item.accuracy
              ? "process-rect"
              : item.accuracy < 0.6
              ? "accuracy-low"
              : item.accuracy < 0.8
              ? "accuracy-medium"
              : "accuracy-high"
          ) */
          .attr("width", 200)
          .attr("height", 100)
          //set color
          .style("fill", item.color);
        //.style("stroke", ({ borderColor }) => borderColor);
        return;
      }
      d3.select(this)
        .append("circle")
        /*         .attr(
          "class",
          !item.accuracy
            ? "resource-rect"
            : item.accuracy < 0.7
            ? "accuracy-low"
            : item.accuracy < 0.85
            ? "accuracy-medium"
            : "accuracy-high"
        ) */
        .attr("class", "resource-rect")
        .style("fill", item.color)
        .attr("r", String(consts.nodeRadius));
      // .style("fill", ({ fillColor }) => fillColor)
      // .style("stroke", ({ borderColor }) => borderColor);
    });

    newGs.style("fill", ({ textColor }) => textColor);
    //console.log(newGs);
    newGs.each(function (d) {
      thisGraph.insertTitleLinebreaks(d3.select(this), d.title);

      // add a small circle to the left of each node, and make vertically centered
      d3.select(this)
        .append("circle")
        .attr("r", 10)
        .attr("cx", d.type == "react" ? -25 : -consts.nodeRadius - 25)
        .attr("cy", d.type == "react" ? 50 : 0)
        .attr("class", "circle")
        .attr(
          "fill",
          !d.accuracy
            ? "white"
            : d.accuracy < 0.65
              ? "#ff0000"
              : d.accuracy < 0.8
                ? "#ff9900"
                : "#00ff00"
        )
        .attr("stroke", "black")
        .attr("stroke-width", "1px");
      // Append rectangles for numbers
      for (let i = 0; i < 3; i++) {
        const rect = d3
          .select(this)
          .append("rect")
          .attr("class", "co2-rect")
          .attr("x", d.type == "react" ? 200 + 10 : consts.nodeRadius + 10)
          .attr("y", d.type == "react" ? i * 25 : -consts.nodeRadius + i * 25)
          .attr("width", 82) // Adjust the width as needed
          .attr("height", 25) // Adjust the height as needed
          .attr("rx", 5) // Adjust the rx attribute as needed for rounded corners
          .attr("ry", 5); // Adjust the ry attribute as needed for rounded corners
      }

      // Add Co2 text
      for (let i = 0; i < 3; i++) {
        d3.select(this)
          .append("text")
          .attr("class", i == 0 ? "co2-num" : i == 1 ? "cp-num" : "w-num")
          .text(function (d) {
            if (i == 0) {
              let inCo2 = d.in_co2 != null ? d.in_co2 : 0;
              let co2 = d.co2 != null ? Number(d.co2) : 0;
              let comulativeCo2 = inCo2 + co2;
              return comulativeCo2 != 0 ? " C: " + comulativeCo2.toFixed(3) : " C: " + "-";
            } else if (i == 1) {
              let inCo2 = d.in_coproduct_co2 != null ? d.in_coproduct_co2 : 0;
              let co2 = d.coproduct_co2 != null ? Number(d.coproduct_co2) : 0;
              let comulativeCo2 = inCo2 + co2;
              return comulativeCo2 != 0 ? " CP: " + comulativeCo2.toFixed(3) : " CP: " + "-";
            } else {
              let inCo2 = d.in_waste_co2 != null ? d.in_waste_co2 : 0;
              let co2 = d.waste_co2 != null ? Number(d.waste_co2) : 0;
              let comulativeCo2 = inCo2 + co2;
              return comulativeCo2 != 0 ? " W: " + comulativeCo2.toFixed(3) : " W: " + "-";
            }
          })
          .attr("x", function (d) {
            return d.type == "react" ? 200 + 12 + 50 / 2 : consts.nodeRadius + 12 + 50 / 2;
          })
          .attr("y", d.type == "react" ? 25 / 2 + i * 25 : 25 / 2 - consts.nodeRadius + i * 25) // Adjust the y position as needed
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .style("font-weight", "bold")
          .style("font-size", "12px"); // Adjust the font size as needed
        //.style("fill", "black"); // Adjust the text color as needed
      }
      // Add Co2 text
      //if (d.type === "react" || (d.type != "react" && d.is_linked_upstream)) {
      d3.select(this)
        .append("text")
        .attr("class", "co2-diff")
        .text(function (d) {
          let co2 = d.co2 ? Number(d.co2).toFixed(3) : 0.0;
          let coproductCo2 = d.coproduct_co2 ? Number(d.coproduct_co2).toFixed(3) : 0.0;
          let wasteCo2 = d.waste_co2 ? Number(d.waste_co2).toFixed(3) : 0.0;
          return "+ " + "C:" + co2 + "/CP:" + coproductCo2 + "/W:" + wasteCo2;
        })
        .attr("x", function (d) {
          return d.type == "react" ? 200 / 2 : 0;
        })
        .attr("y", function (d) {
          return d.type == "react" ? +100 + 50 / 2 : +consts.nodeRadius + 25 / 2;
        })
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .style("font-size", "12px"); // Adjust the font size as needed
      //.style("fill", "black"); // Adjust the text color as needed
      //}

      if (d.type == "react") {
        let assets = "";
        let assetsArr = [];
        if (d.assets && d.assets.length > 0) {
          d.assets.forEach(function (asset) {
            if (asset.asset) {
              assetsArr.push(asset.asset);
            }
          });
        }
        if (assetsArr.length > 0) {
          assets = assetsArr.join(" - ");
        }
        if (assets) {
          //rect above process for assets
          d3.select(this)
            .append("rect")
            .attr("class", "asset-rect")
            .attr("x", 0)
            .attr("y", -25)
            .attr("width", 200) // Adjust the width as needed
            .attr("height", 25) // Adjust the height as needed
            .attr("rx", 50) // Adjust the rx attribute as needed for rounded corners
            .attr("ry", 5); // Adjust the ry attribute as needed for rounded corners

          d3.select(this)
            .append("text")
            .text(assets)
            .attr("x", 100)
            .attr("y", -25 + 25 / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .style("font-size", "12px"); // Adjust the font size as needed
          //.style("fill", "black"); // Adjust the text color as needed
        }
        if (d.facilities && d.facilities.length > 0 && d.facilities[0].facility) {
          d3.select(this)
            .append("rect")
            .attr("class", "facility-rect")
            .attr("x", 0)
            .attr("y", -50)
            .attr("width", 100) // Adjust the width as needed
            .attr("height", 25) // Adjust the height as needed
            .attr("rx", 50) // Adjust the rx attribute as needed for rounded corners
            .attr("ry", 5); // Adjust the ry attribute as needed for rounded corners

          d3.select(this)
            .append("text")
            .text(d.facilities[0].facility)
            .attr("x", 50)
            .attr("y", -50 + 25 / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .style("font-size", "12px"); // Adjust the font size as needed
          //.style("fill", "black"); // Adjust the text color as needed
        }
      }
      if (d.is_last_node) {
        /*
        d3.select(this)
          .append("rect")
          //.attr("class", "last-node")
          .attr("x", d.type == "react" ? 200 + 5 : 100 + 5)
          //.attr("x", -10)
          .attr("y", -10)
          //.attr("width", (d.type == "react" ? 300 : 150)) // Adjust the width as needed
          .attr("width", 72)
          .attr("height", 95)
          //.attr("height", 160) // Adjust the height as needed
          .attr("rx", 5) // Adjust the rx attribute as needed for rounded corners
          .attr("ry", 5) // Adjust the ry attribute as needed for rounded corners
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "5,5")
          .style("fill", "transparent");
  */
        // Define the offset from the last node to the imaginary node
        const offsetX = 100; // Adjust this value as needed
        const offsetY = 50;
        // Calculate positions for the imaginary node and text
        const lastNodeEndX = d.type == "react" ? 200 + 72 : 100 + 72; // X position of the end of the last node
        const imaginaryNodeX = lastNodeEndX + offsetX; // X position of the imaginary node
        const imaginaryNodeY = offsetY; // Y position of the imaginary node, same as the last node
        const co2RectX = imaginaryNodeX + consts.nodeRadius + 60; // X position of the CO2 text
        const co2RectY = 0; // Y position of the CO2 text, same as the imaginary node

        // Draw a line to the imaginary node
        d3.select(this)
          .append("line")
          .attr("class", "imaginary-line")
          .attr("x1", lastNodeEndX)
          .attr("y1", imaginaryNodeY)
          .attr("x2", imaginaryNodeX)
          .attr("y2", imaginaryNodeY)
          .attr("stroke-width", 2)
          .attr("stroke", "black");

        // Draw the imaginary node (circle)
        d3.select(this)
          .append("circle")
          .attr("class", "conceptG")
          .attr("class", "last-node")
          .attr("class", "imaginary-node")
          .attr("cx", imaginaryNodeX)
          .attr("cy", imaginaryNodeY)
          .attr("r", consts.nodeRadius + 20)
          .style("fill", "#ffbb00"); // Use the same fill color as the resource circle

        // Add text inside the imaginary node
        d3.select(this)
          .append("text")
          .attr("class", "imaginary-node-text") // Add a class for styling if needed
          .attr("x", imaginaryNodeX)
          .attr("y", imaginaryNodeY)
          .attr("text-anchor", "middle") // Center the text horizontally
          .attr("alignment-baseline", "middle") // Center the text vertically
          .style("font-size", "20px") // Adjust the font size as needed
          .text("End Node"); // Replace with the text you want to display

        // Append rectangles for numbers
        for (let i = 0; i < 3; i++) {
          const rect = d3
            .select(this)
            .append("rect")
            .attr("class", "end-co2-rect")
            .attr("x", co2RectX - 31) // Adjust X position as needed
            .attr("y", co2RectY + i * 25) // Adjust Y position as needed

            .attr("width", 82) // Adjust the width as needed
            .attr("height", 25) // Adjust the height as needed
            .attr("rx", 5) // Adjust the rx attribute as needed for rounded corners
            .attr("ry", 5); // Adjust the ry attribute as needed for rounded corners
        }

        // Add text for CO2, waste and co-product CO2
        for (let i = 0; i < 3; i++) {
          // Text
          d3.select(this)
            .append("text")
            .attr("class", i == 0 ? "co2-num" : i == 1 ? "cp-num" : "w-num")
            .text(function () {
              if (i == 0) {
                let inCo2 = d.in_co2 != null ? d.in_co2 : 0;
                let co2 = d.co2 != null ? Number(d.co2) : 0;
                let comulativeCo2 = inCo2 + co2;
                return comulativeCo2 != 0 ? " C: " + comulativeCo2.toFixed(3) : " C: " + "-";
              } else if (i == 1) {
                let inCo2 = d.in_coproduct_co2 != null ? d.in_coproduct_co2 : 0;
                let co2 = d.coproduct_co2 != null ? Number(d.coproduct_co2) : 0;
                let comulativeCo2 = inCo2 + co2;
                return comulativeCo2 != 0 ? " CP: " + comulativeCo2.toFixed(3) : " CP: " + "-";
              } else {
                let inCo2 = d.in_waste_co2 != null ? d.in_waste_co2 : 0;
                let co2 = d.waste_co2 != null ? Number(d.waste_co2) : 0;
                let comulativeCo2 = inCo2 + co2;
                return comulativeCo2 != 0 ? " W: " + comulativeCo2.toFixed(3) : " W: " + "-";
              }
            })
            .attr("x", co2RectX)
            .attr("y", co2RectY + 25 / 2 + i * 25) // Adjust the y position as needed
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .style("font-weight", "bold")
            .style("font-size", "12px"); // Adjust the font size as needed
        }
      }
    });

    // remove old nodes
    thisGraph.circles.exit().remove();
  };

  GraphCreator.prototype.zoomed1 = function () {
    var thisGraph = this;
    this.state.justScaleTransGraph = true;

    if (d3.event.translate != undefined) {
      d3.select("." + this.consts.graphClass).attr(
        "transform",
        "translate(" + d3.event.translate + ") scale(" + d3.event.scale + ")"
      );
    }

    if (d3.event.translate == undefined) {
      d3.select("." + this.consts.graphClass).call(
        thisGraph.zoomListener.translate([0, 0]).scale(1).event
      );
    }
  };

  GraphCreator.prototype.zoomed = function () {
    var thisGraph = this;
    this.state.justScaleTransGraph = true;
    var svgContainer = d3.select("#graph");
    // Get the current zoom transformation
    var scale = d3.event.scale;
    /*
    if (thisGraph.justResetted) {
      thisGraph.justResetted = false;
      scale = thisGraph.zoomListener.scale();
    }
    */

    if (d3.event.translate != undefined) {
      d3.select("." + this.consts.graphClass).attr(
        "transform",
        "translate(" + d3.event.translate + ") scale(" + scale + ")"
      );
      thisGraph.zoomListener.translate(d3.event.translate).scale(scale);
      svg.call(thisGraph.zoomListener);
    }

    if (d3.event.translate == undefined) {
      d3.select("." + this.consts.graphClass).attr("transform", "translate(0,0) scale(1,1)");
      thisGraph.zoomListener
        .translate([0, 0])
        .scale(1)
        .event(d3.select("." + this.consts.graphClass));
    }
  };

  function getCenterPoint(bbox) {
    var centerX = bbox.x + bbox.width / 2;
    var centerY = bbox.y + bbox.height / 2;
    return { x: centerX, y: centerY };
  }
  GraphCreator.prototype.resetZoom = function () {
    var thisGraph = this;

    var svgElement = d3.select("#graph > svg");
    var graphElement = svgElement.select("g.graph");

    var svgWidth = +svgElement.attr("width");
    var svgHeight = +svgElement.attr("height");

    var nodeElements = graphElement.selectAll(".conceptG");
    var nodeData = nodeElements.data();
    if (nodeData && nodeData.length > 0) {
      var nodeRadius = 50; // You may adjust this value to match the radius of your nodes
      var margin = nodeRadius * 3; // Ensure there's enough space for the nodes at the edges

      var minX =
        d3.min(nodeData, function (d) {
          return d.x;
        }) - margin;
      var maxX =
        d3.max(nodeData, function (d) {
          return d.x;
        }) +
        4 * margin; //added extra margin here to cater for the end node
      var minY =
        d3.min(nodeData, function (d) {
          return d.y;
        }) - margin;
      var maxY =
        d3.max(nodeData, function (d) {
          return d.y;
        }) + margin;

      var graphWidth = maxX - minX;
      var graphHeight = maxY - minY;

      var graphCenterX = minX + graphWidth / 2;
      var graphCenterY = minY + graphHeight / 2;

      var svgCenterX = svgWidth / 2;
      var svgCenterY = svgHeight / 2;

      var scale = Math.min(svgWidth / graphWidth, svgHeight / graphHeight);

      var translateX = svgCenterX - graphCenterX * scale;
      var translateY = svgCenterY - graphCenterY * scale;

      graphElement.attr(
        "transform",
        "translate(" + translateX + "," + translateY + ") scale(" + scale + ")"
      );

      thisGraph.zoomListener.translate([translateX, translateY]).scale(scale);
      svgElement.call(thisGraph.zoomListener);
    }
  };

  //svgElement.call(thisGraph.zoomListener);
  /*
  GraphCreator.prototype.resetZoom = function () {
    var thisGraph = this;
    this.state.justScaleTransGraph = true;
   
    var svgElement = d3.select("#graph > svg"); // Select the SVG element inside #graph
    var graphElement = svgElement.select("g.graph"); // Select the <g> element with class "graph"
   
    var svgCenter = getCenterPoint(svgElement.node().getBoundingClientRect());
    var graphCenter = getCenterPoint(graphElement.node().getBBox());
    console.log(svgCenter);
    console.log(graphCenter);
   
    // Get the SVG's width and height attributes
    var svgWidth = +svgElement.attr("width");
    var svgHeight = +svgElement.attr("height");
   
    // Calculate the size of the graph
    var graphWidth = graphElement.node().getBBox().width + 100;
    var graphHeight = graphElement.node().getBBox().height + 100;
   
    // Calculate the scale needed to fit the entire graph within the SVG container
    var scaleX = svgWidth / graphWidth;
    var scaleY = svgHeight / graphHeight;
    var scale = Math.min(scaleX, scaleY);
   
    // Calculate the translation to center the graph
    //var translateX = (svgWidth - graphWidth * scale) / 2;
    //var translateY = (svgHeight - graphHeight * scale) / 2;
   
    // Get the distance from the top, left, bottom, and right of the SVG to the corresponding viewport edges
    var svgRect = svgElement.node().getBoundingClientRect();
    var svgTopOffset = svgRect.top;
    var svgLeftOffset = svgRect.left;
    var svgBottomOffset = window.innerHeight - svgRect.bottom;
    var svgRightOffset = window.innerWidth - svgRect.right;
   
    // Get the distance from the right of the SVG to the right of the viewport
    // Adjust the translations using the offsets
    var xOffset = svgRightOffset - svgLeftOffset;
    var yOffset = svgTopOffset - svgBottomOffset;
   
    // Adjust the translations using the offsets
    var translateX = xOffset + svgCenter.x - graphCenter.x * scale;
    var translateY = -svgTopOffset + svgCenter.y - graphCenter.y * scale;
   
    console.log("svgCenterX: " + svgCenter.x);
    console.log("svgCenterY: " + svgCenter.y);
    console.log("graphCenterX: " + graphCenter.x * scale);
    console.log("graphCenterY: " + graphCenter.y) * scale;
    console.log("svgRightOffset: " + svgRightOffset);
    console.log("svgLeftOffset: " + svgLeftOffset);
    // Use D3's transform function to set the translation and scale
    graphElement.attr(
      "transform",
      "translate(" + translateX + "," + translateY + ") scale(" + scale + ")"
    );
    //for animation
    //graphElement
    //    .transition()
    //    .duration(500)
    // Apply the minimum scale and translation to reset the graph
   
    thisGraph.zoomListener.translate([translateX, translateY]).scale(scale);
    thisGraph.justResetted = true;
    svgElement.call(thisGraph.zoomListener);
  };*/
  GraphCreator.prototype.calculateGraphSize = function () {
    var minNodeX = Infinity,
      maxNodeX = -Infinity,
      minNodeY = Infinity,
      maxNodeY = -Infinity;

    // Traverse nodes to find min and max x and y coordinates
    this.nodes.forEach(function (node) {
      minNodeX = Math.min(minNodeX, node.x);
      maxNodeX = Math.max(maxNodeX, node.x);
      minNodeY = Math.min(minNodeY, node.y);
      maxNodeY = Math.max(maxNodeY, node.y);
    });

    // Traverse edges to find min and max x and y coordinates
    this.edges.forEach(function (edge) {
      minNodeX = Math.min(minNodeX, edge.source.x, edge.target.x);
      maxNodeX = Math.max(maxNodeX, edge.source.x, edge.target.x);
      minNodeY = Math.min(minNodeY, edge.source.y, edge.target.y);
      maxNodeY = Math.max(maxNodeY, edge.source.y, edge.target.y);
    });

    const paddingRatio = 0;
    // Calculate the padding as a percentage of the graph's width and height
    var graphWidth = maxNodeX - minNodeX;
    var graphHeight = maxNodeY - minNodeY;
    var paddingX = graphWidth * paddingRatio;
    var paddingY = graphHeight * paddingRatio;

    var highestPadding = paddingX > paddingY ? paddingX : paddingY;

    // Update the dimensions of the graph by adding the padding
    var graphWidth = graphWidth + 2 * paddingX;
    var graphHeight = graphHeight + 2 * paddingY;

    return { width: graphWidth, height: graphHeight };
  };

  GraphCreator.prototype.updateWindow = function (svg) {
    var docEl = document.documentElement,
      bodyEl = document.getElementById("graph");
    if (bodyEl) {
      var x = bodyEl.clientWidth;
      var y = bodyEl.clientHeight;
      svg.attr("width", x).attr("height", y);
    }
  };
  GraphCreator.prototype.unsubscribe = function (svg) { };


  var graphwidth = document.getElementById("graph");
  var width = graphwidth.clientWidth,
    height = graphwidth.clientHeight;

  var xLoc = width / 2 - 25,
    yLoc = 100;

  // initial node data
  // var nodes = canvasData.nodes|| [];
  // var edges = canvasData.edges ||[];
  var nodes = [];
  var edges = [];

  if (canvasData.length > 0 && canvasData[0].canvas_data != undefined) {
    nodes = canvasData[0].canvas_data.nodes;
    edges = canvasData[0].canvas_data.edges;

    const processNodes = nodes.filter((node) => node.type === "react");
    const resourceNodes = nodes.filter((node) => node.type !== "react");
    const minMaxCo2Process = getMinMaxCo2(processNodes);
    const minMaxCo2Resource = getMinMaxCo2(resourceNodes);
    // now update each node with its color
    processNodes.forEach((node) => {
      node.color = interpolateColor(
        minMaxCo2Process.minCo2,
        minMaxCo2Process.maxCo2,
        node.co2,
        "#44ce1b", //or darker green #2E7F18
        "#C82538"
      );
    });
    resourceNodes.forEach((node) => {
      node.color = interpolateColor(
        minMaxCo2Resource.minCo2,
        minMaxCo2Resource.maxCo2,
        node.co2,
        "#44ce1b", //or darker green #2E7F18
        "#C82538"
      );
    });

    //trying for edges
    edges.forEach(function (e, i) {
      edges[i] = {
        source: nodes.filter(function (n) {
          return n.id == e.source;
        })[0],
        target: nodes.filter(function (n) {
          return n.id == e.target;
        })[0],
      };
    });
  }

  /** MAIN SVG **/
  var svg = d3
    .select(settings.appendElSpec)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .on('contextmenu', function (d, i) {
      /* Disabled Right Mouse */
      d3.event.preventDefault();
    });

  var graph = new GraphCreator(svg, nodes, edges);
  graph.updateGraph();

  /*
  d3.select("#circle").on("click", function (d) {
    let ctrlId = 1;
    if (graph.nodes.length > 0) {
      ctrlId = Math.max(...graph.nodes.map((obj) => obj.id));
      ctrlId = ctrlId + 1;
    }
   
    // Get the current translation and scale from the zoom behavior
    var translation = graph.zoomListener.translate();
    var scale = graph.zoomListener.scale();
   
    // Calculate the adjusted coordinates
    var adjustedX = (graph.consts.nodeRadius - translation[0]) / scale;
    var adjustedY = (graph.consts.nodeRadius - translation[1]) / scale;
   
    var d = { id: ctrlId, title: "Resource", x: adjustedX, y: adjustedY };
    //var d = { id: ctrlId, title: "Resource", x: 100, y: 100 };
   
    graph.setIdCt(ctrlId);
    graph.nodes.push(d);
    graphwidth.dispatchEvent(new CustomEvent(EVENT_NODE_ADD_CIRCLE, { detail: d }));
    graph.updateGraph();
  });
  */
  var draggingNode = null;
  var circleButton = d3.select("#circle").node();
  var rectangleButton = d3.select("#reactangle").node();

  var circleY = circleButton.getBoundingClientRect().top;
  var rectangleY = rectangleButton.getBoundingClientRect().top;

  // Create a drag behavior
  var dragResource = d3.behavior
    .drag()
    .on("dragstart", function () {
      // Create the new node here
      let ctrlId = 1;
      if (graph.nodes.length > 0) {
        ctrlId = Math.max(...graph.nodes.map((obj) => obj.id));
        ctrlId = ctrlId + 1;
      }

      // Get the current translation and scale from the zoom behavior
      var translation = graph.zoomListener.translate();
      var scale = graph.zoomListener.scale();
      var svgElement = d3.select("#graph > svg");
      var svgHeight = svgElement.attr("height");
      // Calculate the adjusted coordinates
      var adjustedX = (d3.mouse(this)[0] - translation[0]) / scale;
      var adjustedY = (d3.mouse(this)[1] - translation[1] - 75 + svgHeight / 2) / scale;

      var d = { id: ctrlId, title: "Resource", x: adjustedX, y: adjustedY };
      graph.setIdCt(ctrlId);
      graph.nodes.push(d);
      graphwidth.dispatchEvent(new CustomEvent(EVENT_NODE_ADD_CIRCLE, { detail: d }));
      graph.updateGraph();

      // Set the 'dragging' node to the newly created node
      draggingNode = d;

      // Modify the drag behavior to move the new node
      dragResource.on("drag", function () {
        // Calculate the adjusted coordinates for dragging
        var adjustedX = (d3.mouse(this)[0] - translation[0]) / scale;
        var adjustedY = (d3.mouse(this)[1] - translation[1] - 75 + svgHeight / 2) / scale;

        draggingNode.x = adjustedX;
        draggingNode.y = adjustedY;
        graph.updateGraph();
      });
    })
    .on("dragend", function () {
      // Clean up after the drag operation is finished
      draggingNode = null;
      dragResource.on("drag", null);
    });
  // Create a drag behavior
  var dragProcess = d3.behavior
    .drag()
    .on("dragstart", function () {
      // Create the new node here
      let ctrlId = 1;
      if (graph.nodes.length > 0) {
        ctrlId = Math.max(...graph.nodes.map((obj) => obj.id));
        ctrlId = ctrlId + 1;
      }

      // Get the current translation and scale from the zoom behavior
      var translation = graph.zoomListener.translate();
      var scale = graph.zoomListener.scale();
      var svgElement = d3.select("#graph > svg");
      var svgHeight = svgElement.attr("height");
      // Calculate the adjusted coordinates
      var adjustedX = (d3.mouse(this)[0] - translation[0]) / scale;
      var adjustedY = (d3.mouse(this)[1] - translation[1] + svgHeight / 2) / scale;

      var d = {
        id: ctrlId,
        title: consts.defaultTitle,
        x: adjustedX,
        y: adjustedY,
        type: "react",
      };
      graph.setIdCt(ctrlId);
      graph.nodes.push(d);
      graphwidth.dispatchEvent(new CustomEvent(EVENT_NODE_ADD_REACTANGLE, { detail: d }));
      graph.updateGraph();

      // Set the 'dragging' node to the newly created node
      draggingNode = d;

      // Modify the drag behavior to move the new node
      dragProcess.on("drag", function () {
        // Calculate the adjusted coordinates for dragging
        var adjustedX = (d3.mouse(this)[0] - translation[0]) / scale;
        var adjustedY = (d3.mouse(this)[1] - translation[1] + svgHeight / 2) / scale;

        draggingNode.x = adjustedX;
        draggingNode.y = adjustedY;
        graph.updateGraph();
      });
    })
    .on("dragend", function () {
      // Clean up after the drag operation is finished
      draggingNode = null;
      dragProcess.on("drag", null);
    });

  // Apply the drag behavior to the circle and rectangle buttons
  d3.select("#circle").call(dragResource);
  d3.select("#reactangle").call(dragProcess);

  d3.select("#resetzoom1").on("click", function () {
    d3.select(".graph").transition().duration(750).attr("transform", "translate(0,0) scale(1,1)");
    // graph.zoomed.call(graph);
  });

  d3.select("#resetzoom").on("click", function () {
    //graph.zoomed(graph);
    graph.resetZoom();
  });

  // Event listeners for the buttons
  function zoomAroundCenter(factor) {
    var svgElement = d3.select("#graph > svg");
    var graphElement = svgElement.select("g.graph");

    var svgWidth = +svgElement.attr("width");
    var svgHeight = +svgElement.attr("height");

    // The point to zoom about
    var centerX = svgWidth / 2;
    var centerY = svgHeight / 2;

    var currentScale = graph.zoomListener.scale();
    var newScale = currentScale * factor;

    var currentTranslate = graph.zoomListener.translate();

    // Compute the new translation that will keep the center of the SVG stationary
    var newTranslate = [
      centerX - ((centerX - currentTranslate[0]) * newScale) / currentScale,
      centerY - ((centerY - currentTranslate[1]) * newScale) / currentScale,
    ];

    graph.zoomListener.scale(newScale).translate(newTranslate);

    graphElement.attr(
      "transform",
      "translate(" + newTranslate[0] + "," + newTranslate[1] + ") scale(" + newScale + ")"
    );

    svgElement.call(graph.zoomListener);
  }

  function interpolateColor(minVal, maxVal, val, colorMin = "#00FF00", colorMax = "#FF0000") {
    // Ensure the value is within the range
    val = Math.min(Math.max(val, minVal), maxVal);

    // Calculate the ratio of the value in the range
    let ratio = (val - minVal) / (maxVal - minVal);

    // Decode the hex color to RGB
    function hexToRgb(hex) {
      var bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    }

    // Linear interpolation between the colors
    function lerp(start, end, ratio) {
      return start + (end - start) * ratio;
    }

    // Get the min and max colors in RGB
    let rgbMin = hexToRgb(colorMin);
    let rgbMax = hexToRgb(colorMax);

    // Interpolate each color component
    let r = Math.round(lerp(rgbMin[0], rgbMax[0], ratio));
    let g = Math.round(lerp(rgbMin[1], rgbMax[1], ratio));
    let b = Math.round(lerp(rgbMin[2], rgbMax[2], ratio));

    // Return the interpolated color in hex
    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
      .toString(16)
      .padStart(2, "0")}`;
  }

  // function to get the min and max node.co2
  function getMinMaxCo2(nodes) {
    let minCo2 = 0;
    let maxCo2 = 0;
    nodes.forEach(function (node) {
      if (node.co2) {
        minCo2 = Math.min(minCo2, node.co2);
        maxCo2 = Math.max(maxCo2, node.co2);
      }
    });
    return { minCo2: minCo2, maxCo2: maxCo2 };
  }

  d3.select("#zoom-in").on("click", function () {
    zoomAroundCenter(1.1);
  });

  d3.select("#zoom-out").on("click", function () {
    zoomAroundCenter(1 / 1.1);
  });

  d3.select("#reset-zoom").on("click", function () {
    graph.resetZoom();
  });

  graph.resetZoom();
  return graph;
};

export default Chart;
