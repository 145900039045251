import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

// formik components
import { Formik, Form, setFieldValue } from "formik";
import useAxios from "hooks/useAxios";
import axios from "apis/apiV1";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
import BasicInfo from "layouts/emission/new-emission/components/BasicInfo";

import validations from "layouts/emission/new-emission/schemas/validations";
import form from "layouts/emission/new-emission/schemas/form";
import initialValues from "layouts/emission/new-emission/schemas/initialValues";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";
import VisibilityControl from "layouts/common/visibilityControl";
function getSteps() {
  return ["Basic"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <BasicInfo formData={formData} />;
    default:
      return null;
  }
}

function NewEmission(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleCancel = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate("/emission-profiles");
    }
  };

  const submitForm = async (values, actions) => {
    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        //navigate("/emission-profiles");
        handleCancel();
      }
    };
    if (!values.eeioFactor && !values.ghgfactor) {
      toast.error("GHG/EEIO Factor can't be both empty!");
    } else {
      axiosFetch(
        {
          method: "post",
          url: "emissionprofileadd",
          requestConfig: {
            name: values.profileName,
            calculation_method: values.methodOfCalculation,
            category: typeof values.category != undefined ? values.category : null,
            subcategory: typeof values.subCategory != undefined ? values.subCategory : null,
            uom: typeof values.uom != undefined ? values.uom : null,
            scope1: values.scope1,
            scope2: values.scope2,
            scope3: values.scope3,
            stage1: values.stage1,
            stage2: values.stage2,
            stage3: values.stage3,
            stage4: values.stage4,
            stage5: values.stage5,
            ghgfactor: values.ghgfactor != "" ? values.ghgfactor : values.eeioFactor,
            eeiofactor: values.eeioFactor,
            material: values.material,

            allocation_factor: values.allocation_factor,
            gwp_multiplier: values.gwp_multiplier,
            notes: values.notes,
            activityUUIDs: values.activityUUIDs,

            security_isPublic: visibilityOptions.security_isPublic,
            security_organisation_visibility: visibilityOptions.security_organisation_visibility,
          },
        },
        handleResponseCallback
      );
      actions.setSubmitting(false);
      actions.resetForm();
      setActiveStep(0);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("props", props);
  }, []);

  const renderContent = () => (
    <>
      {loading && <LoaderSpinner />}
      <ToastContainer />

      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid mb={1} item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={3}>
                <VisibilityControl
                  onVisibilityChange={handleVisibilityChange}
                  initialVisibility={{
                    security_isPublic: visibilityOptions.security_isPublic,
                    security_organisation_visibility:
                      visibilityOptions.security_organisation_visibility,
                  }}
                  disabled={false}
                />
              </SoftBox>
            </Card>
          </Grid>

          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} lg={8}>
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <SoftBox p={2}>
                        <SoftBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          })}

                          <SoftBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <SoftBox />
                            ) : (
                              <SoftButton variant="gradient" color="light" onClick={handleBack}>
                                back
                              </SoftButton>
                            )}
                            <SoftButton variant="gradient" color="light" onClick={handleCancel}>
                              Cancel
                            </SoftButton>
                            <SoftBox flex={1} />{" "}
                            {/* This will take up available space, pushing the Save/next button to the far right */}
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="success"
                            >
                              {isLastStep ? "Save" : "next"}
                            </SoftButton>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );

  return (
    <>
      {props.isCalled == undefined ? (
        <DashboardLayout>
          <DashboardNavbar />
          {renderContent()}
          <Footer />
        </DashboardLayout>
      ) : (
        renderContent()
      )}
    </>
  );
}

export default NewEmission;
