import { React, useState, memo, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Formik, Form, ErrorMessage } from "formik";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";
import LoaderSpinner from "components/LoaderSpinner";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components

import "react-toastify/dist/ReactToastify.css";

import Basic from "layouts/assets/new-asset/components/Basic";
//import Emission from "layouts/assets/new-asset/components/Emission";
import Emission from "layouts/emission/select-emissions";

import EmissionComponent from "layouts/emission/new-emission";
//"layouts/pages/product-emission/";

import ConfirmDialog from "components/ConfirmDialog";
import validations from "layouts/assets/edit-asset/schemas/validations";
import form from "layouts/assets/new-asset/schemas/form";
import initialValues from "layouts/assets/new-asset/schemas/initialValues";

import VisibilityControl from "layouts/common/visibilityControl";

//const routerConstants = require("router_constants");

import {
  updateAssetInformation,
  resetNewAssetInformation,
  getAssetsListWithId,
  removeAsset,
  resetRemovedAssetStatus,
} from "reducers/assets-reducer";

const EditAsset = () => {
  const dispatch = useDispatch();
  const { newAssetInformation, assetsListWithId, removedAssetStatus } = useSelector(
    (state) => state.assetsReducer
  );
  const [posts, error, loading, axiosFetch] = useAxios();
  const [showConfirm, setShowConfirm] = useState(false);
  const [associatedData, setAssociatedData] = useState("");
  const [formValue, setFormValue] = useState(null);
  const [newProfile, setNewProfile] = useState([]);
  const [showEmissionProfile, setShowEmissionProfile] = useState(false);
  const [showProcess, setShowProcess] = useState(true);

  const [visibilityOptions, setVisibilityOptions] = useState({
    security_isPublic: false,
    security_organisation_visibility: "readwrite",
  });
  const handleVisibilityChange = (newVisibility) => {
    setVisibilityOptions(newVisibility);
  };
  const canChangeVisibility = useMemo(() => {
    if (formValue && formValue.security_user_owner_email) {
      const currentUserRole = localStorage.getItem("role");
      const currentUserEmail = localStorage.getItem("userEmail");
      if (
        currentUserRole === "superAdmin" ||
        currentUserRole === "organisationAdmin" ||
        formValue.security_user_owner_email === currentUserEmail
      ) {
        return true;
      }
    }
    return false;
  }, [formValue]);

  const { id } = useParams();
  const navigate = useNavigate();

  const { formId, formField } = form;

  useEffect(() => {
    dispatch(getAssetsListWithId(id));
  }, []);

  useEffect(() => {
    //console.log("assetsListWithId", assetsListWithId);
    if (assetsListWithId.status) {
      const formattedResponseData = assetsListWithId?.data.map((responseData) => {
        return {
          assetName: responseData.name,
          gas_volume: responseData.gas_volume,
          water_volume: responseData.water_volume,
          electricity_power: responseData.electricity_power,
          power_energy_genarated: responseData.renewal_energy_generated,
          waste_recycled: responseData.waste_recycled,
          waste_to_landfill: responseData.waste_to_landfill,
          id_facility: responseData.id_facility ? responseData.id_facility : "",
          facility: responseData.id_facility ? responseData.id_facility : "",
          //category: { label: responseData.category, value: responseData.category },
          fuel_volume: responseData.fuel_volume,
          description: responseData.describtion,
          emissionFactor: responseData.emissionFactor,
          security_user_owner_id: responseData.security_user_owner_id,
          security_user_owner_email: responseData.security_user_owner_email,
          security_isPublic: responseData.security_isPublic,
          security_organisation_visibility: responseData.security_organisation_visibility,
        };
      });
      const responseObject = formattedResponseData.reduce((obj, item) => {
        return item;
      }, {});

      setFormValue(responseObject);
      setVisibilityOptions({
        security_isPublic: responseObject.security_isPublic,
        security_organisation_visibility: responseObject.security_organisation_visibility,
      });
    }
  }, [assetsListWithId]);

  useEffect(() => {
    if (newAssetInformation.status) {
      navigate("/assets");
    }

    return () => {
      dispatch(resetNewAssetInformation());
    };
  }, [newAssetInformation]);

  const handleSubmit = (values) => {
    console.log('values', values);
    const payload = {
      name: values.assetName,
      describtion: values.description,
      // category: values.category.value,
      // type: values.category.value,
      electricity_power: values.electricity_power,
      water_volume: values.water_volume,
      gas_volume: values.gas_volume,
      renewal_energy_generated: values.power_energy_genarated,
      waste_recycled: values.waste_recycled,
      waste_to_landfill: values.waste_to_landfill,
      emissionFactor: values.emissionFactor,
      fuel_volume: values.fuel_volume,
      id_facility: values.id_facility,
      facility: values.id_facility,
      ID: id,

      security_isPublic: visibilityOptions.security_isPublic,
      security_organisation_visibility: visibilityOptions.security_organisation_visibility,
    };

    dispatch(updateAssetInformation(payload));
  };

  const handleChildData = (data) => {
    // here data is a boolea value so uisng !data means opposite to data
    setShowProcess(!data);
    setShowEmissionProfile(data);
  };

  const hanldeFormData = (data) => {
    setNewProfile(data);
  };
  return (
    <>
      {loading && <LoaderSpinner />}
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={3} style={{ display: !showEmissionProfile ? "block" : "none" }}>
          <Formik
            initialValues={formValue || initialValues}
            validationSchema={validations}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox mb={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <SoftTypography variant="h4" fontWeight="medium">
                        Edit Asset
                      </SoftTypography>
                      <SoftBox mt={1} mb={2}></SoftBox>
                    </Grid>
                    <Grid item xs={12} lg={12} display="flex" justifyContent="flex-end">
                      <SoftBox>
                        <SoftButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="success"
                        >
                          Update Asset
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={9}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <VisibilityControl
                          onVisibilityChange={handleVisibilityChange}
                          initialVisibility={{
                            security_isPublic: visibilityOptions.security_isPublic,
                            security_organisation_visibility:
                              visibilityOptions.security_organisation_visibility,
                          }}
                          disabled={!canChangeVisibility}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <Basic
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          }}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={3}>
                        <Emission
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          }}
                          onChildData={handleChildData}
                          newProfile={newProfile}
                        />
                      </SoftBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid style={{ display: showEmissionProfile ? "block" : "none" }}>
                      <EmissionComponent
                        isCalled="true"
                        onChildData={handleChildData}
                        onChildFormData={hanldeFormData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SoftBox>

        <Footer />
      </DashboardLayout>
    </>
  );
};
export default EditAsset;
