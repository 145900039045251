import * as Yup from "yup";
import checkout from 'layouts/assets/new-asset/schemas/form';

const {formField:{ 
    assetName, 
    gas_volume, 
    emissionFactor,
    water_volume,
    electricity_power, 
    power_energy_genarated,
    waste_recycled,
    waste_to_landfill,
    facility,
    fuel_volume
},} = checkout;

const validations = 
    Yup.object().shape({
        [assetName.name]:Yup.string().required(assetName.errorMsg),
    });
   


export default validations;