import { useState } from "react";

import { useNavigate } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";
import useAxios from "hooks/useAxios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import BasicInfo from "layouts/organization/new-organization/components/Basic";

import validations from "layouts/organization/new-organization/schemas/validations";
import form from "layouts/organization/new-organization/schemas/form";
import initialValues from "layouts/organization/new-organization/schemas/initialValues";

//Spinner for API call
import LoaderSpinner from "components/LoaderSpinner";

function NewOrganization() {
  const [activeStep, setActiveStep] = useState(0);
  const [posts, error, loading, axiosFetch] = useAxios();
  const { formId, formField } = form;
  const navigate = useNavigate();

  const submitForm = async (values, actions) => {
    console.log(JSON.stringify(values, null, 2));

    const handleResponseCallback = (response) => {
      if (response.status == "success") {
        actions.setSubmitting(false);
        actions.resetForm();
        setActiveStep(0);
        navigate("/organisations");
      }
    };

    axiosFetch(
      {
        method: "post",
        url: "organization/add",
        requestConfig: {
          name: values.organizationName,
          description: values.comments,
        },
      },
      handleResponseCallback
    );
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <BasicInfo
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          }}
                        />
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                          >
                            send
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewOrganization;
