import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import useAxios from "hooks/useAxios";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "layouts/facility/facility-list/components/ActionCell";
import LoaderSpinner from "components/LoaderSpinner";
function FacilityList() {
  const [posts, error, loading, axiosFetch] = useAxios();
  const [dataRow, setDataRow] = useState([]);
  const [selectedFacilityId, setSelectedFacilityId] = useState("");

  const handleResponse = (response) => {
    //refromatting data for  datatable column
    let trransferedData = response?.data.map((responseData) => {
      return {
        id: responseData.IDs,
        facility: responseData.facilityName,
        description: responseData.description.replace(/^(.{70}).{2,}/, "$1…"),
        category: responseData.category,
        action: (
          <ActionCell id={responseData.IDs} {...responseData} deleteHandler={deleteHandler} />
        ),
      };
    });
    setDataRow(trransferedData);
  };

  const fetchAsset = () => {
    axiosFetch(
      {
        method: "get",
        url: "facility/list",
      },
      handleResponse
    );
  };

  const deleteHandler = (facilityId) => {
    axiosFetch({
      method: "post",
      url: "facility/remove",
      requestConfig: {
        IDs: facilityId,
      },
    });
    setSelectedFacilityId(facilityId);
  };

  useEffect(() => {
    fetchAsset();
  }, []);

  useEffect(() => {
    if (selectedFacilityId != null) {
      let filterdTableData = dataRow.filter((row) => row.id !== selectedFacilityId);
      setDataRow(filterdTableData);
    }
  }, [selectedFacilityId]);

  return (
    <DashboardLayout>
      {loading && <LoaderSpinner />}
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Facility
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/facilities/new">
                <SoftButton variant="gradient" color="success" size="small">
                  + new facility
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          <DataTable
            table={{
              columns: [
                { Header: "facility", accessor: "facility" },
                { Header: "description", accessor: "description" },
                /* { Header: "category", accessor: "category" }, */
                { Header: "action", accessor: "action" },
              ],
              rows: dataRow,
            }}
            entriesPerPage={{
              defaultValue: 7,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            pagination={{
              variant: "gradient",
              color: "success",
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FacilityList;
